
<ciciam-frame-front>
  <ciam-sms-code-widget *ngIf="isSms" [isRight]="false" [isAllowPhoneChange]="true"
  (result)="onSmsResult($event)"></ciam-sms-code-widget>
  <div *ngIf="isSms && isEmail" class="h-8"></div>
  <ciam-email-code-widget *ngIf="isEmail" [isRight]="false" [isAllowEmailChange]="true"
  (result)="onEmailResult($event)"></ciam-email-code-widget>

  <div class="flex flex-row" *ngIf="!isSms && !isEmail" >
    <flowbite-loading class="mr-2" size="1.25rem"></flowbite-loading>{{ 'Login in progress...' | translate }}
  </div>
</ciciam-frame-front>

<div *ngIf="showExpireModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex max-h-screen hide-scroll-bar">
  <div class="relative md:w-[80vw] w-[90vw] min-w-[300px] my-6 mx-auto max-w-3xl max-h-screen">
    <!--content-->
    <div class="border-2 border-notus-primary-500 rounded-2xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 rounded-2xl">
        <h3 class="text-2xl block flex-grow text-center md:text-left lg:inline-block text-notus-primary-900">
          {{ 'Login Expired' | translate }}
        </h3>
      </div>
      <!--body-->
      <div class="relative p-4 flex-auto overflow-y-scroll overflow-x-hidden hide-scroll-bar">
        {{ 'Sorry your login session is expired, please back to the app you came from and restart the login process' | translate }}
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="goBack()">
          {{ 'Back' | translate }}
        </button>
      </div>
    </div>
    <div>&nbsp;</div>
  </div>
</div>
<div *ngIf="showExpireModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>
