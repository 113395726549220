import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { SmsEnrollResponse } from '../../app.actions';
import { SmsVerifyPopupComponent } from '../sms-verify-popup/sms-verify-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { phoneNumberWrapping } from '../../functions/utils';

@Component({
    selector: 'ciciam-choose-2fa-popup',
    templateUrl: './choose-2fa-popup.component.html',
    styleUrls: ['./choose-2fa-popup.component.scss']
})
export class Choose2faPopupComponent {
  showModal = false;

  courseId: string = '';
  courseName: string = '';
  coursePrice: number = 0;
  onSuccess?: () => Promise<any> = undefined;
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;

  sms2faData: any;
  email2faData: any;

  emailForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
  });
  phoneForm: FormGroup = this.fb.group({
    phoneNumber: ['', Validators.required],
  });

  @Output() emailVerify = new EventEmitter<any>();
  @Output() smsVerify = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  isLoading = false;

  @ViewChild('smsPopup') public smsPopup!: SmsVerifyPopupComponent;

  faEnvelope = faEnvelope;
  faPhone = faPhone;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private supportService: SupportService,
    private store: Store,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.appState$.subscribe((state) => {
      this.userId = state.userId;
    });
  }

  requestPrompt(sms2faData: any, email2faData: any) {
    this.sms2faData = sms2faData;
    this.email2faData = email2faData;

    this.showModal = true;
  }

  onSelectEmail = ($event: any) => {
    this.emailVerify.emit();
    this.toggleModal();
  }

  onSelectSms = ($event: any) => {
    this.smsVerify.emit();
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  async onEmailFormSubmit() {
    console.log(this.emailForm.value);
    if(this.emailForm.valid) {

      // let result = await this.supportService.changePassword(this.userId!, this.form.value['current_password'], this.form.value['new_password']);

      // if(result.errorCode) {
      //   this.toastr.error('Password change failed:</br>' + result.errorCauses.map((c: { errorSummary: any; }) => '- ' + c.errorSummary).join('</br>'), 'Error', {
      //     enableHtml: true,
      //   });
      // } else {
      //   this.toastr.success('Password changed successfully', 'Success');
      //   this.toggleModal();
      // }
    }
  }

  async onPhoneFormSubmit() {
    console.log(this.phoneForm.value);
    if(this.phoneForm.valid) {
      try {
        let result = await this.supportService.enable2FaSms(this.userId!, true, this.translate.currentLang, phoneNumberWrapping(this.phoneForm.value.phoneNumber));
        console.log(result);
        // TODO: check if success in return
        this.store.dispatch(new SmsEnrollResponse(result));
        this.smsPopup.requestPrompt();
      } catch(e) {
        // it would be Okta's SMS gateway error like Fraud detection or else, so we don't show error message here
      }
    }
  }

  async onRemoveEmail($event: any) {
    $event.preventDefault();
    this.isLoading = true;
    await this.supportService.removeEmail2fa(this.userId!);
    this.toastr.success(this.translate.instant('Email Authentication removed successfully'), this.translate.instant('Success'));
    this.isLoading = false;
    this.toggleModal();
  }

  async onRemoveSms($event: any) {
    $event.preventDefault();
    this.isLoading = true;
    await this.supportService.removeSms2fa(this.userId!);
    this.toastr.success(this.translate.instant('SMS Authentication removed successfully'), this.translate.instant('Success'));
    this.isLoading = false;
    this.toggleModal();
  }

  onCancel($event: any) {
    $event.preventDefault();
    this.toggleModal();
    this.cancel.emit();
  }
}
