
<flowbite-loading size="2.5rem" *ngIf="!isReady"></flowbite-loading>
<div class="rounded-2xl border-gray-300 border-2 p-6 bg-white profile" *ngIf="isReady">
  <ciciam-form-register
    (credential)="onRegister($event)"
    [pv]="false"
    [isTandC]="false"
    [mode]="'profile'"
    [disableUserInfo]="isCompany"
    #registerForm
    *ngIf="isReady"
  ></ciciam-form-register>


</div>
