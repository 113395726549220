import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridCellDropdownComponent } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, toArray } from 'rxjs';
import { FormRegisterComponent } from '../../widgets/form-register/form-register.component';
import { SetIsCompany, SetUserId } from '../../app.actions';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';

@Component({
  selector: 'ciciam-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  isReady: boolean = false;
  isCompany = false;

  faUserCircle = faUserCircle;

  @ViewChild('registerForm') registerForm?: FormRegisterComponent;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private readonly fb: FormBuilder,
    private store: Store) {

  }

  async ngOnInit() {

    const session = await this.oktaAuth.session.get();
    if(session.status !== 'ACTIVE') {
      await this.oktaAuth.signOut();
      return;
    }

    this.isReady = true;

    const user: any = await session.user!();
    console.log(user);

    this.store.dispatch(new SetUserId(user.id));

    delete user.profile.login;
    delete user.profile.secondEmail;

    if(user.type.id == environment.companyGroupId) { this.store.dispatch(new SetIsCompany(true)); this.isCompany = true; }

    this.isReady = true;

    Promise.resolve(new Promise(resolve => {
      setTimeout(resolve, 50);
    })).then(() => {
      this.registerForm?.setFormValue(user.id, user.profile);
    });
  }

  onSubmit() {
  }


  onRegister = async (payload: { email: string, password: string, first_name: string, last_name: string, mobile: string }) => {
    if(this.registerForm) this.registerForm!.isLoading = false;
  }
}
