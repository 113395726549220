import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ciciam-form-login',
  templateUrl: './form-login.component.html',
})
export class FormLoginComponent implements OnDestroy {

  @Input() websiteName = 'Flowbite';
  @Input() set message(stream: Observable<string | null>) {
    this.subscriptions.add(stream.subscribe(x => this.messageSubject.next(x)));
  }

  @Output() credential = new EventEmitter<{ email: string, password: string, usernameTypes: string[] }>();
  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() forgotPassword: EventEmitter<any> = new EventEmitter();

  credentialForm = this.fb.nonNullable.group(
    {
      email: ['', Validators.required],
      password: ['', Validators.required]
    }
  );
  isPasswordVisible: boolean = false;
  isSubmitted = false;

  public isLoading: boolean = false;

  value: string | undefined;

  private readonly messageSubject = new BehaviorSubject<string | null>(null);
  public readonly message$ = this.messageSubject.asObservable();

  private readonly subscriptions = new Subscription();
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircle = faCircle;

  private subLoginApp?: Subscription;
  public loginUsernameType: string[] = ['email', 'phone number'];
  public loginApp: string = '';
  public isAllowRegister: boolean = true;
  public isLoginAppGet: boolean = false;

  constructor(private readonly fb: FormBuilder, private readonly store: Store, private readonly translate: TranslateService, private supportService: SupportService) {
    this.subLoginApp = this.store.subscribe(async (state) => {
      this.loginApp = state.cicciam.loginApp;
      try {
        let appInfo = await this.supportService.getLoginAppConfig(this.loginApp ?? "portal");
        this.loginUsernameType = appInfo.value.loginIdentifiers ?? ['email', 'phone number'];
        this.isAllowRegister = appInfo.value.isHideRegister !== true;
      } catch(e) {}
      this.isLoginAppGet = true;
    });
  }

  usernameHint() {
    return this.loginUsernameType.map((l) => this.translate.instant(l)).join(' / ');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subLoginApp?.unsubscribe();
  }

  onSignInWithEmail() {
    this.isSubmitted = true;
    this.credentialForm.controls.email.markAsTouched();
    this.credentialForm.controls.password.markAsTouched();
    if(!this.credentialForm.valid) { return; }
    this.messageSubject.next(null);
    this.credential.emit({ email: '', password: '', usernameTypes: this.loginUsernameType, ...this.credentialForm.value });
    this.setIsLoading(true);
    // this.credentialForm.reset();
  }

  onRegister($event: any) {
    $event.preventDefault();
    this.register.emit();
  }

  onForgotPassword($event: any) {
    $event.preventDefault();
    this.forgotPassword.emit();
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  setIsLoading(isLoad: boolean) {
    this.isLoading = isLoad;
    if(isLoad) {
      this.credentialForm.disable();
    } else {
      this.credentialForm.enable();
    }
  }

  onFieldClick($event: MouseEvent) {
    ($event.target as any)?.blur();
    ($event.target as any)?.focus();
  }
}
