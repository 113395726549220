import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { firstValueFrom, Observable } from 'rxjs';
import { ClearEnrollResponses, ClearEverything, ClearLoginCredentials, ClearOAuthSigninRequest, EmailEnrollResponse, SetLoginApp, SetOffFlow, SetVerificationToken, SmsEnrollResponse } from '../../app.actions';
import { StateModel, STATE_TOKEN } from '../../app.states';
import * as uuid from 'uuid';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'ciam-login-validate',
  templateUrl: './login-validate.component.html',
  styleUrls: ['./login-validate.component.scss'],
})
export class LoginValidateComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;

  uid: string = '';
  code: string = '';
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  userId?: string;
  username?: string = '';
  password?: string = '';
  sessionToken?: string;

  isSms: boolean = false;
  isSmsSuccess: boolean = false;
  isEmail: boolean = false;
  isEmailSuccess: boolean = false;
  isForceVerify: boolean = false;

  expireCheck: any = null;
  showExpireModal: boolean = false;

  verificationToken?: string;
  loginApp?: string;

  mfaData: any;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, private translate: TranslateService) {
    console.log('hihi0');
    this.appState$.subscribe(async (state) => {
      this.isSms = state.smsEnrollResponse != null && !this.isSmsSuccess;
      this.isEmail = state.emailEnrollResponse != null && !this.isEmailSuccess;
      if(state.verificationToken) {
        this.verificationToken = state.verificationToken;
      }

      this.userId = state.userId;
      this.username = state.username;
      this.password = state.password;
      this.sessionToken = state.sessionToken;

      if(state.oauthSigninRequest) {
        this.state = state.oauthSigninRequest.state;
        this.nonce = state.oauthSigninRequest.nonce;
        this.code_challenge = state.oauthSigninRequest.code_challenge;
        this.code_challenge_method = state.oauthSigninRequest.code_challenge_method;
        this.client_id = state.oauthSigninRequest.client_id;
        this.redirect_uri = state.oauthSigninRequest.redirect_uri;
        this.response_type = state.oauthSigninRequest.response_type;
        this.display = state.oauthSigninRequest.display;
        this.scope = state.oauthSigninRequest.scope;
      }

      if(!this.isSms && !this.isEmail && !this.isForceVerify) {
        this.onVerifcationComplete();
      }
    });
    this.expireCheck = setInterval(() => {
      this.appState$.subscribe((state) => {
        if(state.oauthSigninRequest?.initiatedAt) {
          console.log(state.errorCode);
          if(!(state.oauthSigninRequest?.isOffFlow == true) && (Date.now() - (new Date(state.oauthSigninRequest?.initiatedAt)).getTime() > 1000 * 60 * 10/* || state.errorCode*/)) {
            // expired
            if(state.loginApp == "portal") {
              this.store.dispatch(new SetOffFlow(true));
            } else {
              this.store.dispatch(new ClearEverything());
              this.showExpireModal = true;
            }
          }
        }
      });
    }, 1000);

    this.store.subscribe((state) => {
      this.loginApp = state.cicciam.loginApp;
    });
  }

  async ngOnInit() {

    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
  }

  ngOnDestroy() {
    if(this.expireCheck) {
      clearInterval(this.expireCheck);
    }
  }

  onCodeChanged = ($event: any) => {
    this.code = $event;
  }

  onCodeCompleted = ($event: any) => {

  }

  onSmsResult = async ($event: any) => {
    this.isSmsSuccess = true;
    await this.onVerifcationComplete();
  }

  onEmailResult = async ($event: any) => {
    this.isEmailSuccess = true;
    await this.onVerifcationComplete();
  }

  onVerifcationComplete = async () => {
    if((!this.isSms || this.isSmsSuccess) && (!this.isEmail || this.isEmailSuccess)) {

      // double check 2fa status see any still pending activate
      this.mfaData = await this.supportService.get2faStatus(this.userId!);
      let pending2faData = this.mfaData.find((item: any) => item != null && item.status == 'PENDING_ACTIVATION');
      if(pending2faData) {
        this.isForceVerify = true;
        switch(pending2faData.factorType) {
          case 'sms':
            try {
              let smsresult = await this.supportService.enable2FaSms(this.userId!, true, this.translate.currentLang);
            } catch(e) {
              // to avoid fuzz, we will keep proceed so when user find cannot receive OTP, they click resend
            }
            this.store.dispatch(new ClearEnrollResponses());
            this.store.dispatch(new SmsEnrollResponse(pending2faData));
            this.isSms = true;
            break;
          case 'email':
            try {
              let emailresult = await this.supportService.enable2FaEmail(this.userId!, true, this.translate.currentLang);
            } catch(e) {
              // to avoid fuzz, we will keep proceed so when user find cannot receive OTP, they click resend
            }
            this.store.dispatch(new ClearEnrollResponses());
            this.store.dispatch(new EmailEnrollResponse(pending2faData));
            this.isEmail = true;
            break;
        }
        return;
      }

      if(this.verificationToken) {
        this.store.dispatch(new SetVerificationToken(undefined));
        window.open(this.supportService.getVerifyCallbackUrl(this.verificationToken), "_self");
        return;
      }

      // if(!this.sessionToken) {
        let deviceToken = localStorage.getItem('deviceToken');
        if(deviceToken == null) { deviceToken = uuid.v4().substring(0, 32); localStorage.setItem('deviceToken', deviceToken); }

        let trans: AuthnTransaction = await this.oktaAuth.signIn({
          username: this.username,
          password: this.password,
          context: {
            deviceToken: deviceToken
          }
        });

        console.log(trans);

        if(trans.status == 'SUCCESS') {
          this.sessionToken = trans.sessionToken;
        }
      // }

      this.store.dispatch(new ClearLoginCredentials());

      if(this.state && this.redirect_uri && this.store.snapshot().cicciam?.isOffFlow != true) {
        if(this.loginApp) {
          await this.supportService.assignUserGroupByApp(this.userId?.toString() ?? '', this.loginApp);
        }

        console.log("is in flow!");
        this.store.dispatch(new ClearOAuthSigninRequest());

        let result = await this.supportService.createTokenRecord(this.sessionToken ?? '', this.state, this.redirect_uri);
        console.log(result);

        this.store.dispatch(new SetLoginApp(undefined));
        this.oktaAuth.session.setCookieAndRedirect(this.sessionToken, 'https://' + environment.oktaDomain + '/oauth2/v1/authorize?response_type=token&scope=openid%20profile%20offline_access%20email&nonce=TEST&client_id=' + environment.idpReturnAppId + '&redirect_uri=https:%2F%2F' + window.location.hostname + '%2Flogin%2Fcallback&sessionToken=' + this.sessionToken + '&state=' + result.state);
      } else {
        console.log("is off flow!");
        console.log(this.state);
        console.log(this.redirect_uri);
        console.log(this.store.snapshot().cicciam?.isOffFlow);
        console.log("is off flow!");
        this.oktaAuth.session.setCookieAndRedirect(this.sessionToken, window.location.protocol + "//" + window.location.host + '/home'); // Sets a cookie on redirect
      }

      // this.router.navigate(['/home']);
    }
  }

  goBack() {
    history.go(-3);
  }

}
