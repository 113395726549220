<div class="relative inline-block text-left">
    <div (click)="toggleDropdown()" (clickOutside)="closeDropdown()">
        <button *ngIf="text !== null; else dynamicContent" type="button"
            class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            id="menu-button" aria-expanded="true" aria-haspopup="true">
            {{ text }}
            <fa-icon [icon]="faAngleDown" class="-mr-1 ml-2 h-5 w-5"></fa-icon>
        </button>
        <ng-template #dynamicContent>
            <ng-content></ng-content>
        </ng-template>
    </div>
    <div *ngIf="isDropdownShow"
        class="absolute z-40 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        [class]="[isRightAlign ? 'right-0' : 'left-0', dropdownPosition == 'bottom' ? 'origin-top-right' : 'left-0', dropdownPosition == 'bottom' ? 'origin-top-right' : 'bottom-0', dropdownPosition == 'bottom' ? 'origin-top-right' : '-translate-y-16']"
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div *ngFor="let group of items; let i = index" class="py-1" role="none">
            <a *ngFor="let item of group" [routerLink]="item.routerLink" (click)="item.onClick ? item.onClick($event) : null"
                class="text-slate-700 block px-4 py-2 text-sm hover:bg-gray-100">
                {{ item.name }}
            </a>
        </div>
    </div>
</div>
