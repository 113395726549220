import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { EmailEnrollResponse, SmsEnrollResponse } from '../../app.actions';
import { SmsVerifyPopupComponent } from '../sms-verify-popup/sms-verify-popup.component';
import { EmailVerifyPopupComponent } from '../email-verify-popup/email-verify-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { phoneNumberWrapping } from '../../functions/utils';

@Component({
    selector: 'ciciam-change-2fa-popup',
    templateUrl: './change-2fa-popup.component.html',
    styleUrls: ['./change-2fa-popup.component.scss']
})
export class Change2faPopupComponent {
  showModal = false;

  courseId: string = '';
  courseName: string = '';
  coursePrice: number = 0;
  isChanged: boolean = false;
  onDismiss?: () => Promise<any> = undefined;
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;

  sms2faData: any;
  email2faData: any;

  emailForm: FormGroup = this.fb.group({
    emailEnable: [false],
    email: ['', [Validators.required, Validators.pattern(/(?:[a-z0-9!#$%&*+/=?^_{|}-]+(?:\.[a-z0-9!#$%&*+/=?^_{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]?[a-z0-9])+|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)]],
  });
  phoneForm: FormGroup = this.fb.group({
    phoneNumberEnable: [false],
    phoneNumber: ['', [Validators.required, Validators.pattern('^(\\+[0-9]+|[0-9]{8})$')]],
  });
  emailFormInitValue: any;
  phoneFormInitValue: any;

  isLoading = false;

  @ViewChild('smsPopup') public smsPopup!: SmsVerifyPopupComponent;
  @ViewChild('emailPopup') public emailPopup!: EmailVerifyPopupComponent;

  method: string = "";
  haveOtherTwofa: boolean = false;
  otherTwofaData?: any;
  isVerifcationNeeded = false;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private supportService: SupportService,
    private store: Store,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.appState$.subscribe((state) => {
      this.userId = state.userId;
    });
  }

  requestPrompt(method: string, twofaData: any, contact: string, haveOtherTwofa: boolean, otherTwofaData?: any, onDismiss?: () => Promise<any>) {
    this.method = method;
    this.haveOtherTwofa = haveOtherTwofa;
    this.otherTwofaData = otherTwofaData;
    this.isVerifcationNeeded = twofaData && twofaData.status != 'ACTIVE';
    this.onDismiss = onDismiss;
    this.isChanged = false;

    switch(this.method) {
      case 'sms':
        this.sms2faData = twofaData;
        if(this.sms2faData && this.sms2faData.profile.phoneNumber) {
          this.phoneForm.controls['phoneNumber'].enable();
          this.phoneFormInitValue = {
            phoneNumberEnable: true,
            phoneNumber: this.sms2faData.profile.phoneNumber.replace('+852', ''),
          }
        } else {
          this.phoneForm.controls['phoneNumber'].disable();
          this.phoneFormInitValue = {
            phoneNumberEnable: false,
            phoneNumber: '',
          }
        }
        this.phoneForm.setValue(this.phoneFormInitValue);
        break;
      case 'email':
        this.email2faData = twofaData;
        if(this.email2faData && this.email2faData.profile.email) {
          this.emailForm.controls['email'].enable();
          this.emailFormInitValue = {
            emailEnable: true,
            email: this.email2faData.profile.email,
          }
        } else {
          this.emailForm.controls['email'].disable();
          this.emailFormInitValue = {
            emailEnable: false,
            email: '',
          }
        }
        this.emailForm.setValue(this.emailFormInitValue);
        break;
    }

    this.showModal = true;
  }

  onEmailEnableToggle($event: any) {
    if(!this.emailForm.value['emailEnable']) {
      this.emailForm.controls['email'].enable();
      this.emailForm.controls['email'].setValidators([Validators.required]);
    } else {
      this.emailForm.controls['email'].disable();
      this.emailForm.controls['email'].setValidators([]);
    }
    this.emailForm.controls['email'].updateValueAndValidity();
  }

  onSmsEnableToggle($event: any) {
    if(!this.phoneForm.value['phoneNumberEnable']) {
      this.phoneForm.controls['phoneNumber'].enable();
      this.phoneForm.controls['phoneNumber'].setValidators([Validators.required]);
    } else {
      this.phoneForm.controls['phoneNumber'].disable();
      this.phoneForm.controls['phoneNumber'].setValidators([]);
    }
    this.phoneForm.controls['phoneNumber'].updateValueAndValidity();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  async onEmailFormSubmit() {
    console.log(this.emailForm.value);
    if(this.emailForm.valid) {

      if(!this.emailForm.value["emailEnable"]) {
        if(!this.haveOtherTwofa) {
          this.toastr.error(this.translate.instant('At least one Two-factor Authentication method has to be enabled') , this.translate.instant('Error'));
          return;
        }
        if(confirm(this.translate.instant('change2fa.confirmDisableEmail'))) {
          this.onRemoveEmail(undefined);
        } else {

        }
      } else {
        // var myacc = this.oktaAuth.myaccount;
        // myacc.addEmail({
        //   profile:{
        //     email: this.emailForm.value.email,
        //   },
        //   sendEmail: true,
        //   role: "PRIMARY"
        // });
        // return;

        this.isLoading = true;
        try {
          let result = await this.supportService.enable2FaEmail(this.userId!, this.emailForm.value["emailEnable"], this.translate.currentLang, this.emailForm.value.email);
          console.log(result);
          this.isLoading = false;
          if(!result || result.success == false) {
            this.emailForm.controls['email'].setErrors({ 'emailused': 'emailused' });
          } else {
            this.store.dispatch(new EmailEnrollResponse(result));
            this.isChanged = true;
            this.emailPopup.requestPrompt(async () => {
              this.toggleModal();
              this.onDismiss?.();
            });
          }
        } catch(e) {
          console.log(e);
          this.isLoading = false;
          this.emailForm.controls['email'].setErrors({ 'pattern': 'pattern' });
        }

        // let result = await this.supportService.changePassword(this.userId!, this.form.value['current_password'], this.form.value['new_password']);

        // if(result.errorCode) {
        //   this.toastr.error('Password change failed:</br>' + result.errorCauses.map((c: { errorSummary: any; }) => '- ' + c.errorSummary).join('</br>'), 'Error', {
        //     enableHtml: true,
        //   });
        // } else {
        //   this.toastr.success('Password changed successfully', 'Success');
        //   this.toggleModal();
        // }
      }
    }
  }

  async onPhoneFormSubmit() {
    console.log(this.phoneForm.value);

    if(this.phoneForm.value["phoneNumberEnable"] && this.phoneForm.controls['phoneNumber'].value != '') {
      console.log('hi2a');
      var validRegex = /^(\+[0-9]+|[0-9]{8})$/;
      if(!this.phoneForm.controls['phoneNumber'].value.match(validRegex)) {
        console.log('hi3a');
        this.phoneForm.controls['phoneNumber'].setErrors({ 'phoneformat': 'regerror.phoneformat' });
      }
    }

    if(this.phoneForm.valid) {

      if(!this.phoneForm.value["phoneNumberEnable"]) {
        if(!this.haveOtherTwofa) {
          this.toastr.error(this.translate.instant('At least one Two-factor Authentication method has to be enabled') , this.translate.instant('Error'));
          return;
        }
        if(confirm(this.translate.instant('change2fa.confirmDisableSms'))) {
          this.onRemoveSms(undefined);
        } else {

        }
      } else {

        this.isLoading = true;
        try {
          let result = await this.supportService.enable2FaSms(this.userId!, false, this.translate.currentLang, phoneNumberWrapping(this.phoneForm.value.phoneNumber));
          console.log(result);
          this.isLoading = false;
          if(!result || result.success == false) {
            this.phoneForm.controls['phoneNumber'].setErrors({ 'phoneused': 'phoneused' });
          } else {
              this.isChanged = true;
            this.store.dispatch(new SmsEnrollResponse(result));
            this.isChanged = true;
            this.smsPopup.requestPrompt(async () => {
              this.toggleModal();
              this.onDismiss?.();
            });
          }
        } catch(e) {
          this.isLoading = false;
          this.phoneForm.controls['phoneNumber'].setErrors({ 'pattern': 'pattern' });
        }
      }
    }
  }

  async onRemoveEmail($event: any) {
    if($event) $event.preventDefault();
    if(!this.haveOtherTwofa) {
      this.toastr.error(this.translate.instant('At least one Two-factor Authentication method has to be enabled') , this.translate.instant('Error'));
      return;
    }
    this.isLoading = true;
    console.log(this.otherTwofaData);
    await this.supportService.removeEmail2fa(this.userId!);
    this.toastr.success(this.translate.instant('Email Authentication removed successfully'), this.translate.instant('Success'));
    this.isLoading = false;
    this.toggleModal();
    this.onDismiss?.();
  }

  async onRemoveSms($event: any) {
    if($event) $event.preventDefault();
    if(!this.haveOtherTwofa) {
      this.toastr.error(this.translate.instant('At least one Two-factor Authentication method has to be enabled') , this.translate.instant('Error'));
      return;
    }
    this.isLoading = true;
    await this.supportService.removeSms2fa(this.userId!);
    this.toastr.success(this.translate.instant('SMS Authentication removed successfully'), this.translate.instant('Success'));
    this.isLoading = false;
    this.toggleModal();
    this.onDismiss?.();
  }

  onCancel($event: any) {
    $event.preventDefault();
    if(this.isChanged) {
      this.onDismiss?.();
    }
    this.toggleModal();
  }

  get isFormModified() : boolean {
    switch(this.method) {
      case 'sms':
        return this.phoneForm.value.phoneNumberEnable != this.phoneFormInitValue.phoneNumberEnable || this.phoneForm.value.phoneNumber != this.phoneFormInitValue.phoneNumber;
      case 'email':
        return this.emailForm.value.emailEnable != this.emailFormInitValue.emailEnable || this.emailForm.value.email != this.emailFormInitValue.email;
    }

    return false;
  };
}
