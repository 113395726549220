import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ciciam-form-forgot-password',
  templateUrl: './form-forgot-password.component.html',
})
export class FormForgotPasswordComponent implements OnDestroy {

  @Input() websiteName = 'Flowbite';
  @Input() set message(stream: Observable<string | null>) {
    this.subscriptions.add(stream.subscribe(x => this.messageSubject.next(x)));
  }

  @Output() credential = new EventEmitter<{ email: string, usernameTypes: string[] }>();
  @Output() backToLogin: EventEmitter<any> = new EventEmitter();
  @Output() forgotPassword: EventEmitter<any> = new EventEmitter();

  credentialForm = this.fb.nonNullable.group(
    {
      email: ['', Validators.required]
    }
  );

  isLoading: boolean = false;


  private readonly messageSubject = new BehaviorSubject<string | null>(null);
  public readonly message$ = this.messageSubject.asObservable();

  private readonly subscriptions = new Subscription();

  private subLoginApp?: Subscription;
  public loginUsernameType: string[] = ['email', 'phone number'];
  public isAllowRegister: boolean = true;

  constructor(private readonly fb: FormBuilder, private readonly store: Store, private readonly translate: TranslateService, private supportService: SupportService) {
    this.subLoginApp = this.store.subscribe(async (state) => {
      try {
        let appInfo = await this.supportService.getLoginAppConfig(state.cicciam.loginApp ?? "portal");
        this.loginUsernameType = appInfo.value.loginIdentifiers ?? ['email', 'phone number'];
        this.isAllowRegister = appInfo.value.isHideRegister !== true;
      } catch(e) {}
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subLoginApp?.unsubscribe();
  }

  usernameHint() {
    return this.loginUsernameType.map((l) => this.translate.instant(l)).join(' / ');
  }

  onSubmitForgotPassword() {
    this.messageSubject.next(null);
    this.credential.emit({ email: '', usernameTypes: this.loginUsernameType, ...this.credentialForm.value });
    this.isLoading = true;
    // this.credentialForm.reset();
  }

  onBackToLogin($event: any) {
    $event.preventDefault();
    this.backToLogin.emit();
  }
}
