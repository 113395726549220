
    <div
      class="w-full md:max-w-md md:mt-0 xl:p-0 md:ml-auto"
    >
      <div class="py-6 md:p-6">
        <h2 class="text-xl text-green-800 font-semibold">{{ 'Forgot Password or Unlock Account' | translate }}</h2>
        <div
          class="mt-4"
          [formGroup]="credentialForm"
        >
          <div>
            <span class="p-float-label relative">
            <input
            pInputText
              type="email"
              name="email"
              id="email"
              class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
              formControlName="email"
            />
            <label
              for="email"
              class=""
              >{{ usernameHint() }}</label
            >
            </span>
          </div>
          <button
            type="submit"
            class="mt-8 flex flex-row justify-center w-60 m-auto text-white focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            (click)="onSubmitForgotPassword()"
            [class]="isLoading ? 'bg-white ring-notus-primary-700 ring-2 cursor-default' : 'bg-notus-primary-700 hover:bg-notus-primary-800'"
          >
            <ng-container *ngIf="!isLoading">
              {{ 'Reset Password' | translate }}
            </ng-container>
            <ng-container *ngIf="isLoading">
              <flowbite-loading size="1.25rem"></flowbite-loading>
            </ng-container>
          </button>
          <div class="text-center mt-4">
            <div class="">
              <p class="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                <a
                  (click)="onBackToLogin($event)"
                  class="text-notus-primary-700 hover:underline dark:text-primary-600 cursor-pointer underline underline-offset-4 text-xs"
                  >{{ 'Back to login' | translate }}</a
                >
              </p>
            </div>
            <!-- <a
              href="#"
              class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >{{ 'Forgot password?' | translate }}</a
            > -->
          </div>
        </div>
      </div>
    </div>
