<div class="mt-4 md:mt-0 xl:p-0">
  <h2 class="text-xl text-green-800 font-semibold mb-2" [class]="isRight ? 'md:text-right' : 'md:text-left'">
    {{ 'Phone number verification' | translate }}
    <fa-icon *ngIf="isSuccess" [icon]="faCircleCheck" class="text-notus-primary-600"></fa-icon>
    <fa-icon *ngIf="isError" [icon]="faCircleXmark" class="text-red-600"></fa-icon>
  </h2>
  <label
    for="email"
    class="block mb-4 text-xs text-gray-800 dark:text-white" [class]="isRight ? 'md:text-right' : 'md:text-left'"
  >
    <ng-container *ngIf="!isSuccess">
      <ng-container *ngIf="isAllowPhoneChange && smsEnrollResponse && smsEnrollResponse.status == 'PENDING_ACTIVATION'">
        {{ 'As you have not yet verified your phone number yet.' | translate }}<br />
      </ng-container>
      {{ 'An SMS has sent to the phone number you provided.' | translate }}
      <br />
      {{ 'Please enter the code below to verify your phone number.' | translate }}<br />
      {{ 'The code is valid for 5 minutes.' | translate }}
      <ng-container *ngIf="isAllowPhoneChange && smsEnrollResponse && smsEnrollResponse.status == 'PENDING_ACTIVATION'">
        <br />
        {{ 'Your registered phone number is xxx.' | translate: {'phone': phoneMask(smsEnrollResponse?.profile?.phoneNumber)} }}
        <button
          class="mt-2 justify-center text-white focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-1"
          (click)="onEditPhone($event)"
          [disabled]="isLoadingSubmit"
          [class]="isLoadingSubmit ? ['bg-white', 'ring-notus-primary-700', 'ring-2', 'md:ml-0', isRight ? 'md:ml-auto' : 'md:mr-1', isRight ? 'md:mr-0' : 'md:ml-0'] : ['bg-notus-primary-600', 'hover:bg-notus-primary-700', 'focus:ring-notus-primary-300', 'md:ml-0', isRight ? 'md:ml-auto' : 'md:mr-auto', isRight ? 'md:mr-0' : 'md:ml-0']"
        >
          <ng-container *ngIf="!isLoadingSubmit">
            <fa-icon [icon]="faPenToSquare"></fa-icon>
          </ng-container>
          <ng-container *ngIf="isLoadingSubmit">
            <flowbite-loading size="1.25rem"></flowbite-loading>
          </ng-container>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isSuccess">
      {{ 'Verification success.' | translate }}
    </ng-container>
  </label>
  <div class="flex flex-col items-start" [class]="isRight ? 'md:ml-auto' : 'md:mr-auto'" *ngIf="!isSuccess">
    <code-input [isCodeHidden]="false"
      class="max-w-xs md:w-96 md:max-w-none"
      [disabled]="isSuccess"
      [codeLength]="6"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)">
    </code-input>
    <div *ngIf="isError" class="mt-2 text-xs text-red-600 dark:text-red-300">{{ 'The code is in correct or expired' | translate }}</div>
    <div class="flex flex-row items-start">
      <button
        type="submit"
        class="mt-2 flex flex-row justify-center w-44 text-white focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-1"
        (click)="onSubmit($event)"
        [disabled]="isLoadingSubmit"
        [class]="isLoadingSubmit ? ['bg-white', 'ring-notus-primary-700', 'ring-2', 'md:ml-0', isRight ? 'md:ml-auto' : 'md:mr-1', isRight ? 'md:mr-0' : 'md:ml-0'] : ['bg-notus-primary-600', 'hover:bg-notus-primary-700', 'focus:ring-notus-primary-300', 'md:ml-0', isRight ? 'md:ml-auto' : 'md:mr-auto', isRight ? 'md:mr-0' : 'md:ml-0']"
        *ngIf="!isSuccess"
      >
        <ng-container *ngIf="!isLoadingSubmit">
          {{ 'Verify' | translate }}
        </ng-container>
        <ng-container *ngIf="isLoadingSubmit">
          <flowbite-loading size="1.25rem"></flowbite-loading>
        </ng-container>
      </button>
      <button
        type="submit"
        class="mt-2 flex flex-row justify-center focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 w-44 md:w-auto"
        (click)="onResend($event)"
        [disabled]="isLoadingResend || isLoadingSubmit || resendCooldown > 0"
        [class]="isLoadingResend || isLoadingSubmit || resendCooldown > 0 ? ['text-notus-secondary-400', 'bg-white', 'ml-0', isRight ? 'md:ml-auto' : 'md:mr-auto', isRight ? 'md:mr-0' : 'md:ml-0'] : ['text-notus-primary-600', 'ml-0', isRight ? 'md:ml-auto' : 'md:mr-auto', isRight ? 'md:mr-0' : 'md:ml-0']"
        *ngIf="!isSuccess"
      >
        <ng-container *ngIf="!isLoadingResend && resendCooldown <= 0">
          {{ 'Resend' | translate }}
        </ng-container>
        <ng-container *ngIf="!isLoadingResend && resendCooldown > 0">
          {{ 'Resend' | translate }} ({{ ceil(resendCooldown) }}{{ 'second' | translate }})
        </ng-container>
        <ng-container *ngIf="isLoadingResend">
          <flowbite-loading size="1.25rem"></flowbite-loading>
        </ng-container>
      </button>
    </div>
  </div>
</div>

<ciciam-phone-change-popup #phoneChangePopup></ciciam-phone-change-popup>
