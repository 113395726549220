import { APP_INITIALIZER, CSP_NONCE, FactoryProvider, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { MainComponent } from './main/main.component';
import { AppRoutingModule } from './app.routes';
import { CommonsModule, Logout } from '@digitsolu/commons';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './user/login/login.component';
import { AppState, STATE_TOKEN } from './app.states';
import { NotusAngularModule } from '@digitsolu/notus-angular';
import { UiTailwindModule } from '@digitsolu/ui-tailwind';
import { FlowbiteModule } from '@digitsolu/flowbite';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './user/logout/logout.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {
  OKTA_CONFIG,
  OktaAuthModule
} from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { TimelineModule } from 'primeng/timeline';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { LOCAL_STORAGE_ENGINE, NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormLoginComponent } from './widgets/form-login/form-login.component';
import { FrameFrontComponent } from './frames/front/front.component';
import { SidebarComponent } from './frames/sidebar/sidebar.component';
import { ProfileComponent } from './home/profile/profile.component';
import { ApplicationComponent } from './home/application/application.component';
import { SecurityComponent } from './home/security/security.component';
import { HistoryComponent } from './home/history/history.component';
import { TableModule } from 'primeng/table';
import { ChangePasswordPopupComponent } from './popup/change-password-popup/change-password-popup.component';
import { LoginCallbackComponent } from './user/login-callback/login-callback.component';
import { RegisterComponent } from './user/register/register.component';
import { FormRegisterComponent } from './widgets/form-register/form-register.component';
import { RegisterValidateComponent } from './user/register-validate/register-validate.component';
import { WidgetSmsCodeComponent } from './widgets/sms-code/sms-code.component';
import { WidgetEmailCodeComponent } from './widgets/email-code/email-code.component';
import { Change2faPopupComponent } from './popup/change-2fa-popup/change-2fa-popup.component';
import { SmsVerifyPopupComponent } from './popup/sms-verify-popup/sms-verify-popup.component';
import { LoginValidateComponent } from './user/login-validate/login-validate.component';
import { Choose2faPopupComponent } from './popup/choose-2fa-popup/choose-2fa-popup.component';
import { EmailVerifyPopupComponent } from './popup/email-verify-popup/email-verify-popup.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { FormForgotPasswordComponent } from './widgets/form-forgot-password/form-forgot-password.component';
import { ForgotPasswordValidateComponent } from './user/forgot-password-validate/forgot-password-validate.component';
import { FormResetPasswordComponent } from './widgets/form-reset-password/form-reset-password.component';
import { ForgotPasswordSubmitComponent } from './user/forgot-password-submit/forgot-password-submit.component';
import { CodeInputModule } from './widgets/code-input/code-input.module';
import { InputTextModule } from 'primeng/inputtext';
import { NgSwitcheryModule } from "angular-switchery-ios";
import { PortalLoginComponent } from './user/portallogin/portallogin.component';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { PortalLoginCallbackComponent } from './portallogincallback/portallogincallback.component';
import { VerificationComponent } from './verification/verification.component';
import { ConfigService } from './config-service';
import { Router } from '@angular/router';

import { config as faconfig } from '@fortawesome/fontawesome-svg-core';
import { PhoneChangePopupComponent } from './popup/phone-change-popup/phone-change-popup.component';
import { EmailChangePopupComponent } from './popup/email-change-popup/email-change-popup.component';
faconfig.autoAddCss = false;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const config: OktaAuthOptions = {
  issuer: 'https://' + environment.oktaDomain,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/login/callback',
  responseType: 'code',
  storageManager: {
    token: {
      storageType: 'localStorage'
    }
  },
  cookies: {
    secure: true,
    sameSite: 'lax',
  },
}
const oktaAuth = new OktaAuth(config);

@NgModule({
  declarations: [
    LoginComponent,
    LoginCallbackComponent,
    PortalLoginComponent,
    PortalLoginCallbackComponent,
    RegisterComponent,
    RegisterValidateComponent,
    LoginValidateComponent,
    ForgotPasswordComponent,
    ForgotPasswordValidateComponent,
    ForgotPasswordSubmitComponent,
    PhoneChangePopupComponent,
    EmailChangePopupComponent,

    HomeComponent,
    ProfileComponent,
    ApplicationComponent,
    SecurityComponent,
    HistoryComponent,
    LogoutComponent,

    FrameFrontComponent,
    SidebarComponent,

    FormLoginComponent,
    FormRegisterComponent,
    FormForgotPasswordComponent,
    FormResetPasswordComponent,
    Choose2faPopupComponent,
    ChangePasswordPopupComponent,
    Change2faPopupComponent,
    SmsVerifyPopupComponent,
    EmailVerifyPopupComponent,
    WidgetSmsCodeComponent,
    WidgetEmailCodeComponent,

    VerificationComponent,



    AppComponent,
    MainComponent,
    LogoutComponent,
  ],
  imports: [
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: [{
        key: AppState,
        engine: LOCAL_STORAGE_ENGINE
      }]
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    CommonModule,
    ReactiveFormsModule,
    CommonsModule,
    AppRoutingModule,
    TableModule,
    UiTailwindModule,
    NotusAngularModule,
    FlowbiteModule,
    TranslateModule.forRoot({
      defaultLanguage: 'zh-Hant',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NgSwitcheryModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
    OktaAuthModule,
    TimelineModule,
    AuthModule.forRoot({
      config: [
        {
          configId: 'normal',
          authority: 'https://' + environment.oktaDomain,
          postLogoutRedirectUri: environment.portalDomain,
          redirectUrl: environment.portalDomain + 'portallogin/callback',
          clientId: environment.oktaAuthAppId,
          scope: 'openid profile email phone address offline_access',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          historyCleanupOff: false,
          autoUserInfo: true,
          logLevel: LogLevel.Debug,
        },
        {
          configId: 'low',
          authority: 'https://' + environment.oktaDomain,
          postLogoutRedirectUri: environment.portalDomain,
          redirectUrl: environment.portalDomain + 'portallogin/callback',
          clientId: environment.oktaAuthAppIdLowRisk,
          scope: 'openid profile email phone address offline_access',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          historyCleanupOff: false,
          autoUserInfo: true,
          logLevel: LogLevel.Debug,
        },
        {
          configId: 'high',
          authority: 'https://' + environment.oktaDomain,
          postLogoutRedirectUri: environment.portalDomain,
          redirectUrl: environment.portalDomain + 'portallogin/callback',
          clientId: environment.oktaAuthAppIdHighRisk,
          scope: 'openid profile email phone address offline_access',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          historyCleanupOff: false,
          autoUserInfo: true,
          logLevel: LogLevel.Debug,
        },
      ],
    }),
    CodeInputModule,
    environment.plugins,
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    InputTextModule
  ],
  providers: [
    {
      provide: 'env',
      useValue: environment,
    },
    {
      provide: OKTA_CONFIG,
      // useFactory: () => ({
      //   oktaAuth: new OktaAuth(ConfigService.getOktaConfig())
      // }),
      useValue: { oktaAuth }
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
