import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, firstValueFrom } from 'rxjs';
import { OktaApp } from '../objects/oktaObj';
import { OidcToken } from '../objects/oidcToken';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    constructor(private http: HttpClient) {}

    static apiDomain = environment.apiDomain;

    async register(obj: {personalTitle: string, firstName: string, lastName: string, firstNameZh: string, lastNameZh: string, email: string, mobilePhone: string, password: string, companyName: string, companyInd: string, jobTitle: string, noCompanyInfo: boolean}): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("title", obj.personalTitle);
      postParam = postParam.append("firstName", obj.firstName);
      postParam = postParam.append("lastName", obj.lastName);
      if(obj.firstNameZh != '') postParam = postParam.append("firstNameZh", obj.firstNameZh);
      if(obj.lastNameZh != '') postParam = postParam.append("lastNameZh", obj.lastNameZh);
      if(obj.email != '') postParam = postParam.append("email", obj.email);
      if(obj.mobilePhone != '') postParam = postParam.append("mobile", obj.mobilePhone);
      postParam = postParam.append("password", obj.password);
      if(obj.companyName != '') postParam = postParam.append("companyName", obj.companyName);
      if(obj.companyInd != '') postParam = postParam.append("companyInd", obj.companyName);
      if(obj.jobTitle != '') postParam = postParam.append("jobTitle", obj.jobTitle);
      if(obj.jobTitle != '') postParam = postParam.append("jobTitle", obj.jobTitle);
      postParam = postParam.append("noCompanyInfo", obj.noCompanyInfo);

      return firstValueFrom(this.http.post(SupportService.apiDomain + 'User/CreateUser', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async enable2FaSms(userId: string, active: boolean, locale?: string, fullPhoneNumber?: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("active", active);
      if(fullPhoneNumber != undefined) {
        postParam = postParam.append("phoneNumber", fullPhoneNumber);
      }
      if(locale){
        postParam = postParam.append("locale", locale);
      }
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Factor/Enable2faSms', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async verify2FaSms(userId: string, code: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("passCode", code);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Factor/Submit2faSms', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async enable2FaEmail(userId: string, active: boolean, locale?: string, email?: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("active", active);
      if(email){
        postParam = postParam.append("email", email);
      }
      if(locale){
        postParam = postParam.append("locale", locale);
      }
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Factor/Enable2faEmail', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async verify2FaEmail(userId: string, code: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("passCode", code);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Factor/Submit2faEmail', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async updateUserProfile(userId: string, profile: any): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.appendAll(profile);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'User/UpdateUserProfile', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async getLoginAppConfig(app: string): Promise<any> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("app", app);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'User/GetLoginAppConfig', {
        params: getParam,
        headers: new HttpHeaders({

        })
      }));
    }

    async get2faStatus(userId: string): Promise<any> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("userId", userId);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'Factor/Get2faStatus', {
        params: getParam,
        headers: new HttpHeaders({

        })
      }));
    }

    async removeEmail2fa(userId: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Factor/Remove2faEmail', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async removeSms2fa(userId: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Factor/Remove2faSms', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async getAppListByUser(userId: string): Promise<OktaApp[]> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("userId", userId);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'User/GetAppsByUserId',{
        params: getParam,
        headers: new HttpHeaders({

        })
      })).then((r) => {
        let rr = r as [];
        if(rr.length > 0) {
          return rr.map((item: any) => {
            item.item2.id = item.item1;
            return item.item2;
          });
        }
        return [];
      }) as Promise<OktaApp[]>;
    }

    async getHistoryByUser(userId: string): Promise<any> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("userId", userId);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'User/GetHistoryByUserId',{
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<OktaApp[]>;
    }

    async changePassword(userId: string, oldPassword: string, newPassword: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("oldPassword", oldPassword);
      postParam = postParam.append("newPassword", newPassword);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'User/ChangePasswordByUserId', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async lookupUserByEmail(email: string): Promise<string | boolean> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("email", email);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'Lookup/SearchUserByEmail', {
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<string | boolean>;
    }

    async lookupUserByPhone(phone: string): Promise<string | boolean> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("phone", phone);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'Lookup/SearchUserByPhone', {
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<string | boolean>;
    }

    async lookupUser(username: string, usernameTypes: string[] = ['email', 'phone number']): Promise<any | boolean> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("username", username);
      getParam = getParam.append("types", usernameTypes.join(','));
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'Lookup/SearchUser', {
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<any | boolean>;
    }

    async getForgetPasswordLink(userId: string): Promise<string | boolean> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("userId", userId);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'User/GetResetPasswordLink', {
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<string | boolean>;
    }

    async resetPassword(userId: string, password: string): Promise<any> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("password", password);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'User/ResetPasswordByUserId', postParam, {
        headers: new HttpHeaders({

        })
      }));
    }

    async createTokenRecord(sessiontoken: string, state: string, redirecturi: string): Promise<OidcToken> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("sessiontoken", sessiontoken);
      postParam = postParam.append("state", state);
      postParam = postParam.append("redirecturi", redirecturi);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Oidc/CreateTokenRecord', postParam, {
        headers: new HttpHeaders({

        })
      })) as Promise<OidcToken>;
    }

    async updateTokenRecord(state: string, accesstoken: string, expiresin: number, scope: string): Promise<OidcToken> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("state", state);
      postParam = postParam.append("accesstoken", accesstoken);
      postParam = postParam.append("expiresin", expiresin);
      postParam = postParam.append("scope", scope);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'Oidc/UpdateTokenRecord', postParam, {
        headers: new HttpHeaders({

        })
      })) as Promise<OidcToken>;
    }

    async getTokenRecord(state: string): Promise<OidcToken | boolean> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("state", state);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'User/GetTokenRecord', {
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<OidcToken | boolean>;
    }

    async getTokenDataByVerificationToken(token: string): Promise<ApiResponse> {
      var getParam: HttpParams = new HttpParams();
      getParam = getParam.append("token", token);
      return firstValueFrom(this.http.get(SupportService.apiDomain + 'User/GetVerifyDataByToken', {
        params: getParam,
        headers: new HttpHeaders({

        })
      })) as Promise<ApiResponse>;
    }

    getVerifyCallbackUrl(token: string): string {
      return SupportService.apiDomain + 'User/VerifyCallback?token=' + token;
    }

    async assignUserGroupByApp(userId: string, appName: string): Promise<ApiResponse> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("appName", appName);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'User/assignUserGroupByApp', postParam, {
        headers: new HttpHeaders({

        })
      })) as Promise<ApiResponse>;
    }

    async assignUserGroup(userId: string, groupId: string): Promise<ApiResponse> {
      var postParam: HttpParams = new HttpParams();
      postParam = postParam.append("userId", userId);
      postParam = postParam.append("groupId", groupId);
      return firstValueFrom(this.http.post(SupportService.apiDomain + 'User/AssignUserGroup', postParam, {
        headers: new HttpHeaders({

        })
      })) as Promise<ApiResponse>;
    }
}


interface ApiResponse {
  success: boolean;
  errorMessages: string;
  value: any;
}
