import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./user/login/login.component";
import { LogoutComponent } from "./user/logout/logout.component";
import { MainComponent } from "./main/main.component";
import { ProfileComponent } from "./home/profile/profile.component";
import { ApplicationComponent } from "./home/application/application.component";
import { SecurityComponent } from "./home/security/security.component";
import { HistoryComponent } from "./home/history/history.component";
import { LoginCallbackComponent } from "./user/login-callback/login-callback.component";
import { RegisterComponent } from "./user/register/register.component";
import { RegisterValidateComponent } from "./user/register-validate/register-validate.component";
import { AuthGuard } from "./app.guard";
import { LoginValidateComponent } from "./user/login-validate/login-validate.component";
import { ForgotPasswordComponent } from "./user/forgot-password/forgot-password.component";
import { ForgotPasswordValidateComponent } from "./user/forgot-password-validate/forgot-password-validate.component";
import { ForgotPasswordSubmitComponent } from "./user/forgot-password-submit/forgot-password-submit.component";
import { PortalLoginComponent } from "./user/portallogin/portallogin.component";
import { PortalLoginCallbackComponent } from "./portallogincallback/portallogincallback.component";
import { VerificationComponent } from "./verification/verification.component";

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard.annoymousTo('/portallogin')],
        canActivateChild: [AuthGuard.annoymousTo('/portallogin')],
        children: [
            {
                path: '',
                component: ApplicationComponent,
            },
            {
                path: "profile",
                component: ProfileComponent,
            },
            {
                path: 'security',
                component: SecurityComponent,
            },
            {
                path: 'history',
                component: HistoryComponent,
            },
            {
                path: 'logout',
                component: LogoutComponent,
            },
        ]
    },
    {
        path: 'user',
        component: HomeComponent,
        canActivate: [AuthGuard.annoymousTo('/portallogin')],
        canActivateChild: [AuthGuard.annoymousTo('/portallogin')],
        children: [
            // {
            //     path: 'settings',
            //     component: SettingsComponent,
            // },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        // canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'portallogin',
        component: PortalLoginComponent,
        canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'portallogin/callback',
        component: PortalLoginCallbackComponent,
        canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'loginc',
        component: LoginComponent,
        canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'login-validate',
        component: LoginValidateComponent,
    },
    {
        path: 'register-validate',
        component: RegisterValidateComponent,
        // canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        // canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'forgot-password-validate',
        component: ForgotPasswordValidateComponent,
    },
    {
        path: 'forgot-password-reset',
        component: ForgotPasswordSubmitComponent,
    },
    {
        path: 'register',
        component: RegisterComponent,
        // canActivate: [AuthGuard.userTo('/home')]
    },
    {
        path: 'verification',
        component: VerificationComponent,
    },
    {
        path: 'login/callback',
        component: LoginCallbackComponent,
    },
    {
        path: 'oauth2/v1/authorize',
        component: LoginComponent,
    },
    {
        path: 'oauth2/v1/authorize/:app',
        component: LoginComponent,
    },
    // {
    //     path: 'register',
    //     component: RegisterComponent,
    //     canActivate: [AuthGuard.userTo('/home')]
    // },
    {
        path: 'logout',
        component: LogoutComponent,
        // canActivate: [AuthGuard.annoymousTo('/')]
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/portallogin'
        // component: MainComponent,
        // canActivate: [
        //     AuthGuard.annoymousTo('/portallogin'),
        //     // AuthGuard.userTo('/home')
        //     AuthGuard.userTo('/portallogin')
        // ]
    },
    { path: '**', redirectTo: '/portallogin' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
