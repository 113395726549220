<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-[80%] my-6 mx-auto max-w-2xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="p-5 border-b border-solid border-slate-200 rounded-t mx-4">
        <h3 class="text-2xl font-semibold text-notus-primary-900 text-center">
          {{ 'Change Password' | translate }}
        </h3>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
          <div
          [formGroup]="form"
        >
        <div
        class="space-4 md:space-6 flex-wrap flex flex-col lg:flex-row gap-8">
          <div class="w-full">
            <div class="relative">
              <span class="p-float-label">
                <input
                  pInputText
                  [type]="isCurrentPasswordVisible ? 'text' : 'password'"
                  name="current_password"
                  id="current_password"
                  class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  formControlName="current_password"
                  autocomplete="off"
                />
                <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isCurrentPasswordVisible" [icon]="faEyeSlash" (click)="toggleCurrentPassword()"></fa-icon>
                <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isCurrentPasswordVisible" [icon]="faEye" (click)="toggleCurrentPassword()"></fa-icon>
                <label
                  for="current_password"
                  class=""
                  >{{ 'Current Password' | translate }}</label
                >
              </span>
            </div>
            <div class="text-red-600 col-span-10 text-xs mt-1">
              <div *ngIf="form.get('current_password')?.invalid && (form.get('current_password')?.dirty || form.get('current_password')?.touched)" class="alert alert-danger">
                {{ 'Please enter your current password' | translate }}
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="relative">
              <span class="p-float-label">
                <input
                  pInputText
                  [type]="isNewPasswordVisible ? 'text' : 'password'"
                  name="new_password"
                  id="new_password"
                  class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  formControlName="new_password"
                  (input)="checkPassword($event)"
                  autocomplete="off"
                />
                <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isNewPasswordVisible" [icon]="faEyeSlash" (click)="toggleNewPassword()"></fa-icon>
                <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isNewPasswordVisible" [icon]="faEye" (click)="toggleNewPassword()"></fa-icon>
                <label
                  for="new_password"
                  class=""
                  >{{ 'New Password' | translate }}</label
                >
              </span>
            </div>
            <div class="text-xs mt-2">
              <div class="text-gray-600">{{ 'Password requirements:' | translate }}</div>
                <ul>
                  <li [class]="!isPasswordHasLowercase ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block" >
                      <fa-icon [icon]="faCheck" *ngIf="isPasswordHasLowercase"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasLowercase" size="xs"></fa-icon>
                    </div>
                    {{ 'One lowercase character' | translate }}
                  </li>
                  <li [class]="!isPasswordHasUppercase ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block" >
                      <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasUppercase"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasUppercase" size="xs"></fa-icon>
                    </div>
                    {{ 'One uppercase character' | translate }}
                  </li>
                  <li [class]="!isPasswordHasNumber ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block" >
                      <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasNumber"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasNumber" size="xs"></fa-icon>
                    </div>
                    {{ 'One number' | translate }}
                  </li>
                  <li [class]="!isPasswordLength8 ? 'text-gray-600' : 'text-green-700'">
                    <div class="w-4 inline-block" >
                      <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordLength8"></fa-icon>
                      <fa-icon [icon]="faCircle" *ngIf="!isPasswordLength8" size="xs"></fa-icon>
                    </div>
                    {{ '8 characters minimum' | translate }}
                  </li>
                </ul>
              </div>
            </div>
          <div class="w-full">
            <div class="relative">
                <span class="p-float-label">
            <input
              pInputText
              [type]="isConfirmPasswordVisible ? 'text' : 'password'"
              name="confirm_password"
              id="confirm_password"
              class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
              formControlName="confirm_password"
              autocomplete="off"
            />
            <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isConfirmPasswordVisible" [icon]="faEyeSlash" (click)="toggleConfirmPassword()"></fa-icon>
            <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isConfirmPasswordVisible" [icon]="faEye" (click)="toggleConfirmPassword()"></fa-icon>
            <label
                  for="confirm_password"
                  class=""
                  >{{ 'Repeat New Password' | translate }}</label
                >
          </span>
          </div>
          </div>

        </div>
          <hr class="block my-4" />
          <div class="flex flex-row-reverse">
          <button
            type="submit"
            class="flex flex-row justify-center w-32 text-white bg-notus-primary-600 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:focus:ring-primary-800"
            [class]="isLoading ? 'bg-white ring-notus-primary-700 ring-2 cursor-default' : 'bg-notus-primary-700 hover:bg-notus-primary-800'"
            (click)="onSubmit()"
          >
            <ng-container *ngIf="!isLoading">
              {{ 'Save' | translate }}
            </ng-container>
            <ng-container *ngIf="isLoading">
              <flowbite-loading size="1.25rem"></flowbite-loading>
            </ng-container>
          </button>
          <button
            type="submit"
            class="flex flex-row justify-center w-32 text-notus-primary-600 bg-transparent hover:text-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            (click)="onCancel($event)"
          >
            {{ 'Cancel' | translate }}
          </button>
        </div>
      </div>

      </div>
      <!--footer-->
      <!-- <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        <button class="p-2 bg-notus-secondary-700 rounded-md text-white px-6" type="button">
          Save
        </button>
        <button class="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          Cancel
        </button>
      </div> -->
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>
