import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { DropdownItem } from '@digitsolu/ui-tailwind';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { SetLanguage } from '../../app.actions';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ciciam-frame-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss'],
})
export class FrameFrontComponent {

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  @Input() hideLanguageButton: boolean = false;


  public readonly items: DropdownItem[][] = [
    [
      {
        name: '繁體中文',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('zh-Hant');
          this.store.dispatch(new SetLanguage('zh-Hant'));
        },
      },
    ],
    [
      {
        name: '简体中文',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('zh-Hans');
          this.store.dispatch(new SetLanguage('zh-Hans'));
        },
      },
    ],
    [
      {
        name: 'English',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('en');
          this.store.dispatch(new SetLanguage('en'));
        }
      },
    ]
  ];

  constructor (@Inject(DOCUMENT) private document: Document, public translate: TranslateService, public store: Store, private renderer: Renderer2) {

  }

  async ngOnInit() {
    this.translate.addLangs(['en', 'zh-Hant', 'zh-Hans']);

    this.appState$.subscribe(async (state) => {
      if(state.language) {
        this.translate.use(state.language);
      }
    });
  }

  setFontSize(className: string) {
    this.renderer.removeClass(this.document.body, 'font-small');
    this.renderer.removeClass(this.document.body, 'font-medium');
    this.renderer.removeClass(this.document.body, 'font-large');
    this.renderer.addClass(this.document.body, className);
  }
}
