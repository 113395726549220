import { Inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Select, Store } from "@ngxs/store";
import { firstValueFrom, lastValueFrom, map, Observable, switchMap } from "rxjs";
import { RetainSession } from "./app.actions";
import { STATE_TOKEN, StateModel } from "./app.states";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { OidcSecurityService } from "angular-auth-oidc-client";

export class AuthGuard {

    public static annoymousTo(url: string) {
        @Injectable({ providedIn: 'root' })
        class AuthGuardWithRoute implements CanActivate, CanActivateChild {

            @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

            constructor(readonly store: Store, readonly router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public oidcSecurityService: OidcSecurityService) { }

            async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
              const isAuthenticated = this.store.selectSnapshot(STATE_TOKEN).isLoggedIn && this.store.selectSnapshot(STATE_TOKEN).userId != null;
              if(isAuthenticated) {
                try {
                  const session = await this.oktaAuth.session.get();
                  if(session.status === 'ACTIVE') {
                    return true;
                  } else {
                    await this.oktaAuth.signOut();
                  }
                }
                catch(err) {
                  return false;
                }
              } else if (this.oidcSecurityService.isAuthenticated()) {
                return true;
              }
              return this.router.parseUrl(url);
            }

            async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
              const isAuthenticated = this.store.selectSnapshot(STATE_TOKEN).isLoggedIn && this.store.selectSnapshot(STATE_TOKEN).userId != null;
              if(isAuthenticated) {
                try {
                  const session = await this.oktaAuth.session.get();
                  if(session.status === 'ACTIVE') {
                    return true;
                  } else {
                    await this.oktaAuth.signOut();
                  }
                }
                catch(err) {
                  return false;
                }
              } else if (this.oidcSecurityService.isAuthenticated()) {
                return true;
              }
              return this.router.parseUrl(url);
            }
        }
        return AuthGuardWithRoute;
    }

    public static userTo(url: string) {
        @Injectable({ providedIn: 'root' })
        class NoAuthGuardWithRoute implements CanActivate, CanActivateChild {

          @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

            constructor(readonly store: Store, readonly router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

            async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
              const isAuthenticated = this.store.selectSnapshot(STATE_TOKEN).isLoggedIn && this.store.selectSnapshot(STATE_TOKEN).userId != null;
              if(isAuthenticated) {
                try {
                  const session = await this.oktaAuth.session.get();
                  if(session.status === 'ACTIVE') {
                    return this.router.parseUrl(url);
                  }
                }
                catch(err) {
                }
              }
              return true;
            }

            async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
              const isAuthenticated = this.store.selectSnapshot(STATE_TOKEN).isLoggedIn && this.store.selectSnapshot(STATE_TOKEN).userId != null;
              if(isAuthenticated) {
                try {
                  const session = await this.oktaAuth.session.get();
                  if(session.status === 'ACTIVE') {
                    return this.router.parseUrl(url);
                  }
                }
                catch(err) {
                }
              }
              return true;
            }
        }
        return NoAuthGuardWithRoute;
    }
}
