<!-- <div class=" table w-full">
  <div class=" table-header-group font-bold">
    <div class=" table-row">
      <div class=" table-cell  border-b-2 border-gray-200 pb-2">Application</div>
      <div class=" table-cell  border-b-2 border-gray-200">Location</div>
      <div class=" table-cell  border-b-2 border-gray-200">Device</div>
      <div class=" table-cell  border-b-2 border-gray-200">Login time</div>
    </div>
  </div>
  <div class=" table-row-group">
    <div class="table-row">
      <div class="table-cell pt-2">Safety App</div>
      <div class="table-cell">North Point</div>
      <div class="table-cell">Chrome, Mac OS</div>
      <div class="table-cell">25/06/2022 23:12:04</div>
    </div>
  </div>
</div> -->

<p-table #dt2 [globalFilterFields]="['vin','year']" dataKey="id" (loading)="loading" [value]="data"
    [paginator]="true" [rows]="10" [tableStyleClass]="'m-w-[50rem]'" [showFirstLastIcon]="true" [showJumpToPageDropdown]="false" [showPageLinks]="false"
    currentPageReportTemplate="{{ 'Showing {first} to {last} of {totalRecords} entries' | translate }}" [rowsPerPageOptions]="[10,25,50]"
    [globalFilterFields]="['siteReference']">
    <!--Table Header-->
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left border-b-2 border-gray-200 pb-2 border-spacing-2 border-separate"><div class="p-2">{{ 'Application' | translate }}</div></th>
        <th class="text-left border-b-2 border-gray-200"><div class="p-2">{{ 'Location' | translate }}</div></th>
        <th class="text-left border-b-2 border-gray-200"><div class="p-2">{{ 'Device' | translate }}</div></th>
        <th class="text-left border-b-2 border-gray-200"><div class="p-2">{{ 'Login time' | translate }}</div></th>
      </tr>
    </ng-template>
    <!--Table Body-->
    <ng-template pTemplate="body" let-product>
      <tr>
        <td class="w-[25%]"><div class="p-2">{{product.application}}</div></td>
        <td class="w-[25%]"><div class="p-2">{{product.locaiton}}</div></td>
        <td class="w-[25%]"><div class="p-2">{{product.device}}</div></td>
        <td class="w-[25%]"><div class="p-2">{{dateDisplay(product.loginTime)}}</div></td>

      </tr>
    </ng-template>
    <!--Not found message-->
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4"><div class="p-2">
          <flowbite-loading size="2.5rem" *ngIf="isLoading"></flowbite-loading>
          <ng-container *ngIf="!isLoading">{{ 'No result found.' | translate }}</ng-container>
        </div></td>
      </tr>
    </ng-template>
  </p-table>
