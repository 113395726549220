<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-6xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--body-->
      <div class="relative p-6 flex-auto">
        <div class="space-4 md:space-6 flex-wrap flex flex-col lg:flex-row gap-8 mb-2 mt-2">
          <div class="w-full"
          action="#"
          [formGroup]="phoneForm">
            <h2 class="text-xl text-green-800 font-semibold mb-2" [class]="'md:text-left'">
              {{ 'Phone number change' | translate }}
            </h2>
            <span class="p-float-label relative">
            <input
              pInputText
              name="phoneNumber"
              id="phoneNumber"
              class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              formControlName="phoneNumber"
            />
            <label
              for="phoneNumber"
              class=""
              >{{ 'Phone Number' | translate }}</label
            >
          </span>
            <div *ngIf="phoneForm.get('phoneNumber')?.errors?.['required'] && phoneForm.get('phoneNumber')?.touched" class="alert alert-danger text-red-600">
              {{ 'Please enter your phone number' | translate }}
            </div>
            <div *ngIf="phoneForm.get('phoneNumber')?.errors?.['pattern'] && phoneForm.get('phoneNumber')?.touched" class="alert alert-danger text-red-600">
              {{ 'Please enter a valid phone number.' | translate }}
            </div>
            <div *ngIf="phoneForm.get('phoneNumber')?.errors?.['phoneused'] && phoneForm.get('phoneNumber')?.touched" class="alert alert-danger text-red-600">
              {{ 'Phone number had been used.' | translate }}
            </div>
          </div>
        </div>
         <div class="flex flex-row-reverse">
          <button
            type="submit"
            class="flex flex-row justify-center w-32 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            *ngIf="!isLoading"
            (click)="onPhoneFormSubmit()"
          >
          {{ 'Change' | translate }}
          </button>
          <button
            type="submit"
            disabled
            class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
            *ngIf="isLoading"
          >
          <flowbite-loading size="1.25rem"></flowbite-loading>
          </button>
           <button
             type="submit"
             class="flex flex-row justify-center w-32 text-notus-primary-900 bg-transparent hover:text-notus-primary-900 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
             (click)="onCancel($event)"
           >
             {{ 'Cancel' | translate }}
           </button>
         </div>
        </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>
