<div class="box m-auto max-w-2xl">
  <div class="  box-top p-6">
    <p class=" font-bold">{{ 'Login' | translate }}</p>
  </div>
  <div class=" bg-white p-6 flex flex-row box-bottom">
    <div class=" flex-grow">
      <p class=" font-bold">{{ 'Change Password' | translate }}</p>
      <p>{{ "It's a good idea to use strong password that you don't use elsewhere" | translate }}</p>
    </div>
    <div class="flex items-center">
      <button class="min-w-[6rem] bg-notus-primary-700 hover:bg-notus-primary-900 text-white font-bold py-2 px-6 rounded-3xl inline-flex items-center justify-center" (click)="onClick()">
        <span>{{ 'Edit' | translate }}</span>
      </button>
    </div>
  </div>
  <!-- <div class=" bg-white p-6 flex flex-row rounded-b-lg border-t-2 border-gray-200">
    <div class=" flex-grow">
      <p class=" font-bold">Forgotten Password Question</p>
      <p>Select recover password question so you can reset your password if you have problems logging into your account.</p>
    </div>
    <div class="flex items-center">
      <button class="min-w-[6rem] bg-notus-primary-700 hover:bg-notus-primary-900 text-white font-bold py-2 px-6 rounded-3xl inline-flex items-center">
        <span>Edit</span>
      </button>
    </div>
  </div> -->
</div>

<div class="h-8"></div>

<div class="box m-auto max-w-2xl">
  <div class="p-6 box-top">
    <p class=" font-bold">{{ 'Two-factor Authentication' | translate }}</p>
  </div>
  <div class=" bg-white p-6 flex flex-row box-bottom">
    <div class=" flex-grow">
      <p class=" font-bold">{{ 'Use two-factor authentication' | translate }}</p>
      <flowbite-loading size="2.5rem" *ngIf="isLoading"></flowbite-loading>
      <ng-container *ngIf="!isLoading">
        <div>
          <div>
            <div class="flex flex-grow justify-center items-center border-b-2 py-4">
              <div class="mr-2"><img src="assets/images/phone.svg" class="w-6 m-2" /></div>
              <div class="flex-grow text-left">{{ 'SMS' | translate }} -
                <span *ngIf="sms2faData && sms2faData.status == 'ACTIVE'" class="text-notus-primary-900 font-semibold">{{ 'On' | translate }}</span>
                <span *ngIf="sms2faData && sms2faData.status != 'ACTIVE'" class="text-black font-semibold">{{ 'Pending Activation' | translate }}</span>
                <span *ngIf="!sms2faData" class="text-black font-semibold">{{ 'Off' | translate }}</span>
              </div>
              <button *ngIf="!isLoading" [disabled]="isCompany" class="min-w-[6rem] bg-notus-primary-700 hover:bg-notus-primary-900 text-white font-bold py-2 px-6 rounded-3xl inline-flex items-center justify-center disabled:bg-gray-400" (click)="on2faClick('sms')">
                <span>{{ 'Edit' | translate }}</span>
              </button>
            </div>
          </div>
          <div>
            <div class="flex flex-grow justify-center items-center py-4">
              <div class="mr-2"><img src="assets/images/mail.svg" class="w-6 m-2" /></div>
              <div class="flex-grow text-left">{{ 'Email' | translate }} -
                <span *ngIf="email2faData && email2faData.status == 'ACTIVE'" class="text-notus-primary-900 font-semibold">{{ 'On' | translate }}</span>
                <span *ngIf="email2faData && email2faData.status != 'ACTIVE'" class="text-black font-semibold">{{ 'Pending Activation' | translate }}</span>
                <span *ngIf="!email2faData" class="text-black font-semibold">{{ 'Off' | translate }}</span>
              </div>
              <button *ngIf="!isLoading" [disabled]="isCompany" class="min-w-[6rem] bg-notus-primary-700 hover:bg-notus-primary-900 text-white font-bold py-2 px-6 rounded-3xl inline-flex items-center justify-center disabled:bg-gray-400" (click)="on2faClick('email')">
                <span>{{ 'Edit' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- <p><span>On</span> - Google Authenticator</p> -->
      </ng-container>
    </div>
    <div class="flex items-center">
    </div>
  </div>
</div>


<ciciam-change-password-popup #passwordPopup></ciciam-change-password-popup>
<ciciam-change-2fa-popup #mfaPopup></ciciam-change-2fa-popup>
