import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridCellDropdownComponent } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, Subscription, toArray } from 'rxjs';
import { ChangePasswordPopupComponent } from '../../popup/change-password-popup/change-password-popup.component';
import { Change2faPopupComponent } from '../../popup/change-2fa-popup/change-2fa-popup.component';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { STATE_TOKEN, StateModel } from '../../app.states';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { SetUserId } from '../../app.actions';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ciciam-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent {

  faUserCircle = faUserCircle;

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;

  isLoading: boolean = true;
  data: any;

  phone: string = '';
  email: string = '';
  sms2faData: any;
  email2faData: any;
  isCompany: boolean = false;

  @ViewChild('passwordPopup') public passwordPopup!: ChangePasswordPopupComponent;
  @ViewChild('mfaPopup') public mfaPopup!: Change2faPopupComponent;

  _sub?: Subscription;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private readonly fb: FormBuilder, private readonly supportService: SupportService, private store: Store, private router: Router) {
  }

  async ngOnInit() {
    const session = await this.oktaAuth.session.get();
    if(session.status !== 'ACTIVE') {
      localStorage.setItem('redirectUrl', this.router.url);
      await this.oktaAuth.signOut();
      return;
    }

    const user: any = await session.user!();

    this.store.dispatch(new SetUserId(user.id));
    this.userId = user.id;
    this.get2faStatus();
  }

  async ngOnDestory() {
    this._sub?.unsubscribe();
  }

  async get2faStatus() {
    if(this.userId) {
      this.isLoading = true;

      this.sms2faData = undefined;
      this.email2faData = undefined;

      const session = await this.oktaAuth.session.get();
      if(session.status !== 'ACTIVE') {
        localStorage.setItem('redirectUrl', this.router.url);
        await this.oktaAuth.signOut();
        return;
      }

      const user: any = await session.user!();
      if(user.type.id == environment.companyGroupId) { this.isCompany = true; }
      this.phone = user.profile.mobilePhone;
      this.email = user.profile.email;
      if(this.email.endsWith('@cicciam.hk')) this.email = "";

      this.data = await this.supportService.get2faStatus(this.userId!);
      this.sms2faData = this.data.find((item: any) => item && item.factorType === 'sms');
      this.email2faData = this.data.find((item: any) => item && item.factorType === 'email' && !item.profile.email.endsWith('@cicciam.hk'));

      this.isLoading = false;
    }
  }

  onClick = () => {
    this.passwordPopup.requestPrompt();
  }

  on2faClick = (method: string) => {
    switch(method){
      case 'sms':
        this.mfaPopup.requestPrompt(method, this.sms2faData, this.phone, this.email2faData != null && this.email2faData.status == 'ACTIVE', this.email2faData, async () => {
          this.isLoading = true;
          await this.get2faStatus();
        });
        break;
      case 'email':
        this.mfaPopup.requestPrompt(method, this.email2faData, this.email, this.sms2faData != null && this.sms2faData.status == 'ACTIVE', this.sms2faData, async () => {
          this.isLoading = true;
          await this.get2faStatus();
        });;
        break;
    }
  }
}
