import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonsModule } from '@digitsolu/commons';
import { DropdownComponent } from './widgets/dropdown/dropdown.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridCellDropdownComponent } from './widgets/ag-grid-cell-dropdown/ag-grid-cell-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    CommonsModule,
  ],
  declarations: [
    DropdownComponent,
    AgGridCellDropdownComponent,
  ],
  exports: [
    DropdownComponent,
    AgGridCellDropdownComponent,
  ],
})
export class UiTailwindModule { }
