import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'flowbite-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class ComponentLoadingComponent implements OnDestroy {

  @Input() size: string = '100%';

  constructor() { }

  ngOnDestroy(): void {
  }
}
