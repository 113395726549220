import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ciciam-sms-verify-popup',
    templateUrl: './sms-verify-popup.component.html',
    styleUrls: ['./sms-verify-popup.component.scss']
})
export class SmsVerifyPopupComponent {
  showModal = false;

  courseId: string = '';
  courseName: string = '';
  coursePrice: number = 0;
  onSuccess?: () => Promise<any> = undefined;
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;

  form: FormGroup = this.fb.group({
    current_password: ['', Validators.required],
    new_password: ['', Validators.required],
    confirm_password: ['', Validators.required],
  });

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private supportService: SupportService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.appState$.subscribe((state) => {
      this.userId = state.userId;
    });
  }

  requestPrompt(onSuccess?: () => Promise<any>) {
    this.onSuccess = onSuccess;
    this.showModal = true;
  }

  onResult = async ($event: any) => {
    this.toastr.success(this.translate.instant('SMS Authentication updated successfully'), this.translate.instant('Success'));
    this.toggleModal();
    this.onSuccess?.();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  useStripe() {
    this.toggleModal();
  }

  onCancel($event: any) {
    $event.preventDefault();
    this.toggleModal();
  }
}
