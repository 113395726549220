import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store"
import { Authenticate, Logout } from "./auth-guard.actions";

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

export interface AuthStateModel {
    userId: string | null
}

export const initialAuthStateModel: AuthStateModel = {
    userId: null
}

@State(
    {
        name: AUTH_STATE_TOKEN,
        defaults: initialAuthStateModel
    }
)
@Injectable({ providedIn: 'root' })
export class AuthState {

    @Action(Authenticate)
    authenticate(ctx: StateContext<AuthStateModel>, action: Authenticate) {
        const state = ctx.getState();
        if (state.userId != action.userId)
            ctx.setState({ userId: action.userId });
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        ctx.setState({ userId: null });
    }
}
