<span *ngFor="let holder of placeholders; index as i"
      (window:resize)="onResize($event)"
      [class.code-hidden]="isCodeHidden">
  <input #input
         (click)="onClick($event)"
         (paste)="onPaste($event, i)"
         (input)="onInput($event, i)"
         (keydown)="onKeydown($event, i)"
         [type]="inputType"
         [disabled]="disabled"
         [attr.inputmode]="inputMode"
         [attr.autocapitalize]="autocapitalize"
         autocomplete="one-time-code"/>
</span>

