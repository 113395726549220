
<ciciam-frame-front>
  <ciciam-form-forgot-password #forgotPasswordForm
    (credential)="onForgotPassword($event)"
    (backToLogin)="onBackToLoginClick()"
    (forgotPassword)="onForgotPasswordClick()"
  ></ciciam-form-forgot-password>
</ciciam-frame-front>

<ciciam-choose-2fa-popup #choose2fa
  (smsVerify)="proceedWithSms2fa()"
  (emailVerify)="proceedWithEmail2fa()"
  (cancel)="onCancel2fa()"
></ciciam-choose-2fa-popup>
