import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridCellDropdownComponent } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { OktaApp } from 'apps/app-cic-ciam/src/objects/oktaObj';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, toArray } from 'rxjs';
import { SetUserId } from '../../app.actions';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';
import { STATE_TOKEN, StateModel } from '../../app.states';

@Component({
  selector: 'ciciam-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent {

  userId?: string;
  allApps?: OktaApp[];
  apps?: OktaApp[];
  blacklistApps: string[] = [];

  enAppIds: string[] = ['0oa9c7ko1vf7QsxL9697' /* OPAS DEV */, '0oa9be6bneXicrJeb697' /* OPAS UAT */, '0oa9veguomUyfd5UA697' /* OPAS PRD */];
  zhHantAppIds: string[] = ['0oa8mqkfz1DAwQ4un697' /* OPAS DEV */, '0oa8z4q7fsMBamxLV697' /* OPAS UAT */, '0oa9vefbs7eu4jsSy697' /* OPAS PRD */];
  zhHansAppIds: string[] = ['0oa9c7o78w4RDjjAT697' /* OPAS UAT */, '0oa9ve6g02EWyPI5o697' /* OPAS PRD */];

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  isLoading: boolean = true;

  faUserCircle = faUserCircle;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private readonly fb: FormBuilder, private store: Store, private supportService: SupportService) {
    this.appState$.subscribe(async (state) => {
      if(state.language) {
        switch(state.language) {
          case 'en':
            this.blacklistApps = [];
            this.blacklistApps = this.blacklistApps.concat(this.zhHantAppIds);
            this.blacklistApps = this.blacklistApps.concat(this.zhHansAppIds);
            break;
          case 'zh-Hans':
            this.blacklistApps = [];
            this.blacklistApps = this.blacklistApps.concat(this.enAppIds);
            this.blacklistApps = this.blacklistApps.concat(this.zhHantAppIds);
            break;
          case 'zh-Hant':
          default:
            this.blacklistApps = [];
            this.blacklistApps = this.blacklistApps.concat(this.enAppIds);
            this.blacklistApps = this.blacklistApps.concat(this.zhHansAppIds);
            break;
        }
        this.apps = this.allApps?.filter(app => this.blacklistApps.indexOf(app.id) < 0) ?? [];
      }
    });
  }

  async ngOnInit() {
    // if(await this.oktaAuth.isAuthenticated()) {
      const session = await this.oktaAuth.session.get();
      if(session.status !== 'ACTIVE') {
        await this.oktaAuth.signOut();
        return;
      }

      const user: any = await session.user!();
      this.userId = user.id;
      this.store.dispatch(new SetUserId(user.id));
      this.getAppList();
    // }
  }

  async getAppList() {
    if(this.userId) {
      this.isLoading = true;
      this.allApps = (await this.supportService.getAppListByUser(this.userId)).filter(app => app.status === 'ACTIVE' && !app.label.includes('OnePass Login Portal'));
      this.allApps = this.allApps.filter(app => this.onAppVisitClick(app, false));
      this.apps = this.allApps.filter(app => !this.blacklistApps.includes(app.id));
      this.isLoading = false;
    }
  }

  onAppVisitClick(app: OktaApp, isVisit: boolean = true) {
    try {
      if(app.visibility.hide.web) {
        if(app.settings.oauthClient && app.settings.oauthClient.application_type === 'browser' && app.settings.oauthClient.initiate_login_uri) {
          if(isVisit) { window.open(app.settings.oauthClient.initiate_login_uri, '_blank'); }
          return true;
        }
        if(app.settings.signOn && app.settings.signOn.audience) {
          if(isVisit) { window.open(app.settings.signOn.audience, '_blank'); }
          return true;
        }
        return false;
      }
      if(isVisit) { window.open(app._links.appLinks[0].href.replace(environment.oktaDomain, environment.oktaDomain), '_blank'); }
      return true;
    } catch(e) {return false;}
  }
}
