import { GridOptions } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridCellDropdownComponent } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, toArray } from 'rxjs';
import { Authenticate, ClearEnrollResponses, ClearEverything, EmailEnrollResponse, SetOidcError, SetVerificationToken, SmsEnrollResponse } from '../app.actions';
import { SupportService } from '../../services/support.service';
import { Choose2faPopupComponent } from '../popup/choose-2fa-popup/choose-2fa-popup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ciciam-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent {
  faUserCircle = faUserCircle;

  userData: any;
  userId: string = '';
  callback: string = '';

  error: string = '';
  error_description: string = '';
  @ViewChild('choose2fa') choose2fa!: Choose2faPopupComponent;

  constructor(private readonly fb: FormBuilder, public oidcSecurityService: OidcSecurityService, private route: ActivatedRoute, public http: HttpClient, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  private authStateService: OktaAuthStateService, private readonly supportService: SupportService, public router: Router, private store: Store, private translate: TranslateService) {
    this.route.queryParams.subscribe(params => {
      store.dispatch(new ClearEverything());

      let token = params['token'];
      let callback = params['callback'];

      if(!callback || callback == "") {

      }

      this.callback = callback;

      if(!token || token == "") {
        window.open(callback, "_self");
      }

      this.supportService.getTokenDataByVerificationToken(token).then((data) => {
        if(!data.success) {
          window.open(callback, "_self");
        } else {
          this.supportService.get2faStatus(data.value.userId).then(async (mfaData) => {
            let sms2faData = mfaData.find((item: any) => item != null && (item.status == 'ACTIVE' || item.status == 'PENDING_ACTIVATION') && item.factorType === 'sms');
            let email2faData = mfaData.find((item: any) => item != null && (item.status == 'ACTIVE' || item.status == 'PENDING_ACTIVATION') && item.factorType === 'email' && !item.profile.email.endsWith('@cicciam.hk'));

            this.store.dispatch(new Authenticate(data.value.userId));
            this.store.dispatch(new SetVerificationToken(token));

            switch(data.value.mode) {
              case 1:
                // verification only
                  this.userId = data.value.userId;
                if(sms2faData && email2faData) {
                  // both factors available, need popup and ask
                  this.choose2fa.requestPrompt(sms2faData, email2faData);
                } else if(sms2faData) {
                  await this.proceedWithSms2fa();
                } else if(email2faData) {
                  await this.proceedWithEmail2fa();
                }
                break;
              case 0:
              default:
                // registration
                if(email2faData) {
                  this.store.dispatch(new EmailEnrollResponse(email2faData));
                  // this.supportService.enable2FaEmail(data.value.userId, true, this.translate.currentLang);
                }

                if(sms2faData) {
                  this.store.dispatch(new SmsEnrollResponse(sms2faData));
                  // this.supportService.enable2FaSms(data.value.userId, true);
                }

                this.router.navigate(['register-validate']);
            }
          });
        }
      });

      let code = params['code'];
      let state = params['state'];
      this.error = params['error'];
      this.error_description = params['error_description'];

      if (code && state) {
        this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
          if (isAuthenticated) {
            console.log(userData);
            this.userData = userData;
            this.router.navigate(['/home']);
            // this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
            //   this.router.navigate(['/home']);
            // });
          }
        });
      } else {
        if(this.oidcSecurityService.isAuthenticated()) {
          this.oidcSecurityService.getUserData().subscribe((userData) => {
            this.userData = userData;
          });

          // this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
          //   console.log(data);
          // });
        } else if(this.error && this.error_description) {
          this.store.dispatch(new SetOidcError(this.error));
          this.router.navigate(['/']);
        }
      }
    });
  }

  async ngOnInit() {

  }

  async proceedWithSms2fa() {
    let result = await this.supportService.enable2FaSms(this.userId, true, this.translate.currentLang);
    this.store.dispatch(new ClearEnrollResponses());
    this.store.dispatch(new SmsEnrollResponse(result));
    this.router.navigate(['/login-validate']);
    // this.oktaAuth.session.setCookieAndRedirect((await firstValueFrom(this.appState$)).sessionToken, window.location.protocol + "//" + window.location.host + '/login-validate'); // Sets a cookie on redirect
  }

  async proceedWithEmail2fa() {
    let result = await this.supportService.enable2FaEmail(this.userId, true, this.translate.currentLang);
    this.store.dispatch(new ClearEnrollResponses());
    this.store.dispatch(new EmailEnrollResponse(result));
    this.router.navigate(['/login-validate']);
    // this.oktaAuth.session.setCookieAndRedirect((await firstValueFrom(this.appState$)).sessionToken, window.location.protocol + "//" + window.location.host + '/login-validate'); // Sets a cookie on redirect
  }

  onCancel2fa() {
    window.open(this.callback, "_self");
  }
}
