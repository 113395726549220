
    <div
      class="w-full md:max-w-md md:mt-0 xl:p-0 md:ml-auto"
    >
      <div class="py-6 md:p-6">
        <h2 class="text-xl text-green-800 mb-4">{{ 'Reset Password or Unlock Account' | translate }}</h2>
        <div
          [formGroup]="credentialForm"
        >
          <div>
            <span class="p-float-label relative">
              <input
              pInputText
                [type]="isPasswordVisible ? 'text' : 'password'"
                name="password"
                id="password"
                placeholder=""
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0"
                required=""
                formControlName="password"
                (input)="checkPassword($event)"
                autocomplete="off"
              />
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isPasswordVisible" [icon]="faEyeSlash" (click)="togglePassword()"></fa-icon>
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isPasswordVisible" [icon]="faEye" (click)="togglePassword()"></fa-icon>
              <label
                for="password"
                class=""
                >{{ 'Password' | translate }}</label
              >
            </span>
          </div>
          <div
            *ngIf="credentialForm.controls['password'].invalid && (credentialForm.controls['password'].dirty || credentialForm.controls['password'].touched)"
            class="text-red-600 col-span-10 text-xs mt-2 "
          >
            <div *ngIf="credentialForm.controls['password'].errors?.['required']">
              {{ 'Password is required.' | translate }}
            </div>
            <div *ngIf="credentialForm.controls['password'].errors?.['requirement']">
              {{ 'Password does not fulfill requirement.' | translate }}
            </div>
          </div>
          <div class="mt-2">
            <div>{{ 'Password requirements:' | translate }}</div>
            <ul>
              <li [class]="!isPasswordHasLowercase ? 'text-black' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon [icon]="faCheck" *ngIf="isPasswordHasLowercase"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasLowercase" size="xs"></fa-icon>
                </div>
                {{ 'One lowercase character' | translate }}
              </li>
              <li [class]="!isPasswordHasUppercase ? 'text-black' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasUppercase"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasUppercase" size="xs"></fa-icon>
                </div>
                {{ 'One uppercase character' | translate }}
              </li>
              <li [class]="!isPasswordHasNumber ? 'text-black' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasNumber"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasNumber" size="xs"></fa-icon>
                </div>
                {{ 'One number' | translate }}
              </li>
              <li [class]="!isPasswordLength8 ? 'text-black' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordLength8"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordLength8" size="xs"></fa-icon>
                </div>
                {{ '8 characters minimum' | translate }}
              </li>
            </ul>
          </div>
          <div class="mt-6">
            <span class="p-float-label relative">
              <input
              pInputText
              [type]="isRepeatPasswordVisible ? 'text' : 'password'"
                name="repeatPassword"
                id="repeatPassword"
                placeholder=""
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0"
                required=""
                formControlName="repeatPassword"
                autocomplete="off"
              />
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isRepeatPasswordVisible" [icon]="faEyeSlash" (click)="toggleRepeatPassword()"></fa-icon>
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isRepeatPasswordVisible" [icon]="faEye" (click)="toggleRepeatPassword()"></fa-icon>
              <label
                for="password"
                class=""
                >{{ 'Repeat Password' | translate }}</label
              >
            </span>
            <div
              *ngIf="credentialForm.controls['repeatPassword'].invalid && (credentialForm.controls['repeatPassword'].touched)"
              class="text-red-600 col-span-10 text-xs mt-2"
              >
              <div *ngIf="credentialForm.controls['repeatPassword'].errors?.['required']">
                {{ 'Repeat password is required.' | translate }}
              </div>
              <div *ngIf="credentialForm.controls['repeatPassword'].errors?.['match']">
                {{ 'The repeat password does not match.' | translate }}
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="flex flex-row justify-center w-60 m-auto text-white mt-6 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            (click)="onResetPassword()"
            [class]="isLoading ? 'bg-white ring-notus-primary-700 ring-2 cursor-default' : 'bg-notus-primary-700 hover:bg-notus-primary-800'"
          >
            <ng-container *ngIf="!isLoading">
              {{ 'Reset Password' | translate }}
            </ng-container>
            <ng-container *ngIf="isLoading">
              <flowbite-loading size="1.25rem"></flowbite-loading>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
