import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { faArrowLeft, faBars, faBuilding, faCity, faPeopleGroup, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { MenuItem } from 'libs/notus-angular/src/lib/notus-angular.types';
import { SetIsCompany, SetLanguage } from '../../app.actions';
import { DropdownItem } from '@digitsolu/ui-tailwind';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';

@Component({
  selector: 'ciciam-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

  @Input() siteName = "Notus Angular";
  @Input() menuName = "Menu";
  @Input() menu: MenuItem[] = [];

  isCompany: boolean = false;

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  pathMatchOptions: IsActiveMatchOptions = {
    matrixParams: 'subset',
    queryParams: 'ignored',
    paths: 'exact',
    fragment: 'ignored',
  }

  faBars = faBars;
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  faIcon = faCity;

  collapseShow = 'hidden';
  public readonly items: DropdownItem[][] = [
    [
      {
        name: '繁體中文',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('zh-Hant');
          this.store.dispatch(new SetLanguage('zh-Hant'));
        },
      },
    ],
    [
      {
        name: '简体中文',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('zh-Hans');
          this.store.dispatch(new SetLanguage('zh-Hans'));
        },
      },
    ],
    [
      {
        name: 'English',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('en');
          this.store.dispatch(new SetLanguage('en'));
        }
      },
    ]
  ];

  constructor (@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2, public translate: TranslateService, public store: Store, private supportService: SupportService) {

  }

  toggleCollapseShow(classes: string) {
    this.collapseShow = classes;
  }

  onMenuItemClicked(item: MenuItem) {
    this.toggleCollapseShow('hidden');
  }

  async ngOnInit() {
    this.translate.addLangs(['en', 'zh-Hant', 'zh-Hans']);

    this.appState$.subscribe(async (state) => {
      if(state.language) {
        this.translate.use(state.language);
      }
    });

    const session = await this.oktaAuth.session.get();
    if(session.status !== 'ACTIVE') {
      await this.oktaAuth.signOut();
      return;
    }

    const user: any = await session.user!();
    if(user.type.id == environment.companyGroupId) { this.store.dispatch(new SetIsCompany(true)); this.isCompany = true; }
    if(user.profile['customRedirectURL']) {
      let result = await this.supportService.updateUserProfile(user.id, {
        customRedirectURL: 'null'
      });
      window.location.href = user.profile['customRedirectURL'];
    }
  }

  setFontSize(className: string) {
    this.renderer.removeClass(this.document.body, 'font-small');
    this.renderer.removeClass(this.document.body, 'font-normal');
    this.renderer.removeClass(this.document.body, 'font-large');
    this.renderer.addClass(this.document.body, className);
  }
}
