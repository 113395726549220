
<ciciam-frame-front>
  <div class="flex flex-row"><flowbite-loading class="mr-2" size="1.25rem"></flowbite-loading>{{ 'Login in progress...' | translate }}</div>
</ciciam-frame-front>

<ciciam-choose-2fa-popup #choose2fa
  (smsVerify)="proceedWithSms2fa()"
  (emailVerify)="proceedWithEmail2fa()"
  (cancel)="onCancel2fa()"
></ciciam-choose-2fa-popup>
