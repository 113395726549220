export function phoneNumberWrapping(phoneNumber: string): string {
  phoneNumber = phoneNumber.replace(/[\s-]/g, '');
  if (/^\d{8}$/.test(phoneNumber)) {
    return '+852' + phoneNumber;
  }
  if (/^\d$/.test(phoneNumber)) {
    return '+' + phoneNumber;
  }
  return phoneNumber;
}
