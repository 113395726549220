import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens, AuthApiError } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { Authenticate, ClearEnrollResponses, ClearEverything, EmailEnrollResponse, SetLanguage, SetLoginApp, SetOAuthSigninRequest, SetOffFlow, SetOidcError, SmsEnrollResponse, StoreLoginCredentials, StoreSessionToken } from '../../app.actions';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { Observable, firstValueFrom } from 'rxjs';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Choose2faPopupComponent } from '../../popup/choose-2fa-popup/choose-2fa-popup.component';
import { FormLoginComponent } from '../../widgets/form-login/form-login.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'cicciam-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string, usernameTypes: string[] }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;
  expireCheck: any = null;

  errorCode: string = '';
  showExpireModal: boolean = false;
  loginApp?: string;

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  @ViewChild('loginForm') loginForm?: FormLoginComponent;
  @ViewChild('choose2fa') choose2fa!: Choose2faPopupComponent;
  sms2faData: any;
  email2faData: any;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private translateService: TranslateService, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, private toastr: ToastrService, private translate: TranslateService) {
    this.route.queryParams.subscribe(params => {
      this.errorCode = params['errorCode'];
      if(this.errorCode) {
        this.store.dispatch(new SetOidcError(this.errorCode));
      }

      if(params['state']) {
        this.state = params['state'];
        this.nonce = params['nonce'];
        this.code_challenge = params['code_challenge'];
        this.code_challenge_method = params['code_challenge_method'];
        this.client_id = params['client_id'];
        this.redirect_uri = params['redirect_uri'];
        this.response_type = params['response_type'];
        this.display = params['display'];
        this.scope = params['scope'];

        if(this.state && this.redirect_uri) {
          this.store.dispatch(new SetOAuthSigninRequest({
            state: this.state,
            nonce: this.nonce,
            code_challenge: this.code_challenge,
            code_challenge_method: this.code_challenge_method,
            client_id: this.client_id,
            redirect_uri: this.redirect_uri,
            response_type: this.response_type,
            display: this.display,
            scope: this.scope,
            initiatedAt: new Date(),
            isOffFlow: false
          }));

          this.onOidcTimer();
        }
      } else {
        if(this.route.snapshot.url.length == 0 || this.route.snapshot.url[0].path == 'login') {
          this.store.dispatch(new ClearEverything());
        } else {
          this.store.subscribe((state) => {
            this.loginApp = state.cicciam.loginApp;

            this.state = state.cicciam.oauthSigninRequest?.state ?? '';
            this.nonce = state.cicciam.oauthSigninRequest?.nonce ?? '';
            this.code_challenge = state.cicciam.oauthSigninRequest?.code_challenge ?? '';
            this.code_challenge_method = state.cicciam.oauthSigninRequest?.code_challenge_method ?? '';
            this.client_id = state.cicciam.oauthSigninRequest?.client_id ?? '';
            this.redirect_uri = state.cicciam.oauthSigninRequest?.redirect_uri ?? '';
            this.response_type = state.cicciam.oauthSigninRequest?.response_type ?? '';
            this.display = state.cicciam.oauthSigninRequest?.display ?? '';
            this.scope = state.cicciam.oauthSigninRequest?.scope ?? '';

            if(this.state && this.redirect_uri) {
              this.onOidcTimer();
            }
          });
        }
      }

      if(params['lang']) {
        switch(params['lang']) {
          case 'zh-Hant':
          case 'zh-Hans':
          case 'en':
            this.store.dispatch(new SetLanguage(params['lang']));
            break;
        }
      }
      if(params['display']) {
        switch(params['display']) {
          case 'zh-Hant':
          case 'zh-Hans':
          case 'en':
            this.store.dispatch(new SetLanguage(params['display']));
            break;
        }
      }
    });

    this.route.paramMap.subscribe((params) => {
      let loginApp = params.get('app');
      if(loginApp) {
        this.store.dispatch(new SetLoginApp(loginApp));
      }
    });

    // this.appState$.subscribe((state) => {
    //   if(state.errorCode) {
    //     this.toastr.error("Login was expired, please login again");
    //     this.store.dispatch(new SetOidcError(undefined));
    //   }
    // });

    this.store.subscribe((state) => {
      this.loginApp = state.cicciam.loginApp;
    });
  }

  async ngOnInit() {
    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
    // this.translateService.setDefaultLang('zh-Hant');
    this.translateService.addLangs(['en', 'zh-Hant']);

    this.appState$.subscribe(async (state) => {
      if(state.language) {
        this.translateService.use(state.language);
      }
    });
  }

  onOidcTimer() {
    // https://onepass-uat.cic.hk/oauth2/v1/authorize/etendering?state=RFRhcGpyK1c1b3hQTGtjR2plb2UxQi9XakFlOFJtZ2ZidGg2WU53d2dUSkZmRVBZWnpUaDI5QjY4aVk2MDNYcA&nonce=euM2ZjdL4L5op2Z6JtFGN8iKT_ggDNnQ&client_id=0oa4cd4j2gVdXd0Ac697&redirect_uri=https:%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&response_type=code&scope=email%20openid%20profile
    this.expireCheck = setInterval(() => {
      this.appState$.subscribe((state) => {
        if(state.oauthSigninRequest?.initiatedAt) {
          console.log(state.errorCode);
          if(!(state.oauthSigninRequest?.isOffFlow == true) && (Date.now() - (new Date(state.oauthSigninRequest?.initiatedAt)).getTime() > 1000 * 60 * 10/* || state.errorCode*/)) {
            // expired
            if(state.loginApp == "portal") {
              this.store.dispatch(new SetOffFlow(true));
            } else {
              this.store.dispatch(new ClearEverything());
              this.showExpireModal = true;
            }
          }
        }
      });
    }, 1000);
  }

  ngOnDestroy() {
    if(this.expireCheck) {
      clearInterval(this.expireCheck);
    }
  }

  onSignIn = async (payload: { email: string, password: string, usernameTypes: string[] }) => {
    // this.credential.emit(payload);

    try {
      await this.onSigninCall(payload.email, payload.password);
    } catch(e: any) {
      // fail, try lookup
      let username = payload.email;

      let result = await this.supportService.lookupUser(username, payload.usernameTypes);
      if(result !== false) {
        try {
          if(result.length > 0) { result = result[0]; }
          var loginName = result.profile.login;
          await this.onSigninCall(loginName.toString(), payload.password);
        } catch(e: any) {
          this.onSigninException(e);
        }
      } else {
        this.onSigninException(e);
      }
    }
  }

  onSigninCall = async (username: string, password: string) => {
    let deviceToken = localStorage.getItem('deviceToken');
    if(deviceToken == null) { deviceToken = uuid.v4().substring(0, 32); localStorage.setItem('deviceToken', deviceToken); }

    let trans: AuthnTransaction = await this.oktaAuth.signIn({
      username: username,
      password: password,
      context: {
        deviceToken: deviceToken
      }
    });

    if(trans.status == 'SUCCESS') {

      this.store.dispatch(new StoreLoginCredentials(username, password));

      console.log(trans);
      console.log({
        state: this.state,
        nonce: this.nonce,
        code_challenge: this.code_challenge,
        code_challenge_method: this.code_challenge_method,
        client_id: this.client_id,
        redirect_uri: this.redirect_uri,
        response_type: this.response_type,
        display: this.display,
        scope: this.scope + " offline_access",
      });
      this.store.dispatch(new StoreSessionToken(trans.sessionToken!));
      this.store.dispatch(new Authenticate(trans.user!['id']));

      let appInfo = await this.supportService.getLoginAppConfig(this.loginApp ?? "portal");
      // if(appInfo && appInfo.value.isOtp == false) {
      //   if(this.state && this.redirect_uri) {
      //     let result = await this.supportService.createTokenRecord(trans.sessionToken ?? '', this.state, this.redirect_uri);

      //     if(this.loginApp) {
      //       await this.supportService.assignUserGroupByApp(trans.user!['id'], this.loginApp);
      //     }

      //     this.oktaAuth.session.setCookieAndRedirect(trans.sessionToken, 'https://' + environment.oktaDomain + '/oauth2/v1/authorize?response_type=token&scope=openid%20profile%20offline_access%20email&nonce=TEST&client_id=' + environment.idpReturnAppId + '&redirect_uri=https:%2F%2F' + window.location.hostname + '%2Flogin%2Fcallback&sessionToken=' + trans.sessionToken + '&state=' + result.state);
      //   } else {
      //     this.oktaAuth.session.setCookieAndRedirect(trans.sessionToken, window.location.protocol + "//" + window.location.host + '/home'); // Sets a cookie on redirect
      //   }
      // } else {
        if(!(appInfo && appInfo.value.isOtp == false)) {
          let mfaData = await this.supportService.get2faStatus(trans.user!['id']);
          this.sms2faData = mfaData.find((item: any) => item != null && (item.status == 'ACTIVE' || item.status == 'PENDING_ACTIVATION') && item.factorType === 'sms');
          this.email2faData = mfaData.find((item: any) => item != null && (item.status == 'ACTIVE' || item.status == 'PENDING_ACTIVATION') && item.factorType === 'email' && !item.profile.email.endsWith('@cicciam.hk'));
        }

        if(this.sms2faData && this.email2faData) {
          // both factors available, need popup and ask
          this.choose2fa.requestPrompt(this.sms2faData, this.email2faData);
        } else if(this.sms2faData) {
          await this.proceedWithSms2fa();
        } else if(this.email2faData) {
          await this.proceedWithEmail2fa();
        } else {
          this.store.dispatch(new ClearEnrollResponses());
          this.router.navigate(['/login-validate']);

          // if(this.state && this.redirect_uri) {
          //   let result = await this.supportService.createTokenRecord(trans.sessionToken ?? '', this.state, this.redirect_uri);
          //   console.log(result);

          //   this.oktaAuth.session.setCookieAndRedirect(trans.sessionToken, 'https://' + environment.oktaDomain + '/oauth2/v1/authorize?response_type=token&scope=openid%20profile%20offline_access%20email&nonce=TEST&client_id=' + environment.idpReturnAppId + '&redirect_uri=https:%2F%2F' + window.location.hostname + '%2Flogin%2Fcallback&sessionToken=' + trans.sessionToken + '&state=' + result.state);
          // } else {
          //   this.oktaAuth.session.setCookieAndRedirect(trans.sessionToken, window.location.protocol + "//" + window.location.host + '/home'); // Sets a cookie on redirect
          // }
        }
      // }
    } else {
      switch(trans.status) {
        case "LOCKED_OUT":
          this.toastr.error(this.translateService.instant("LOGIN.ACCOUNT_LOCKED"));
          break;
      }
      if(this.loginForm) this.loginForm.setIsLoading(false);
    }
  }

  onSigninException = async (e: any) => {
    this.loginForm!.setIsLoading(false);
    if(e as AuthApiError) {
      let errorStr = '';
      let errorCode = '';
      if(e.errorCauses.length > 0) {
        errorStr = e.errorCauses[0].errorSummary;
        errorCode = e.errorCauses[0].errorCode;
      } else {
        errorStr = e.errorSummary;
        errorCode = e.errorCode;
      }

      if(errorCode == 'E0000004') {
        errorStr = this.translateService.instant("Login incorrect");
      }

      this.toastr.error(errorStr);
    }
  }

  async proceedWithSms2fa() {
    try {
      let result = await this.supportService.enable2FaSms((await firstValueFrom(this.appState$)).userId!, true, this.translate.currentLang);
    } catch(e) {
      // to avoid fuzz, we will keep proceed so when user find cannot receive OTP, they click resend
    }
    this.store.dispatch(new ClearEnrollResponses());
    this.store.dispatch(new SmsEnrollResponse(this.sms2faData));
    this.router.navigate(['/login-validate']);
    // this.oktaAuth.session.setCookieAndRedirect((await firstValueFrom(this.appState$)).sessionToken, window.location.protocol + "//" + window.location.host + '/login-validate'); // Sets a cookie on redirect
  }

  async proceedWithEmail2fa() {
    try {
      let result = await this.supportService.enable2FaEmail((await firstValueFrom(this.appState$)).userId!, true, this.translate.currentLang);
    } catch(e) {
      // to avoid fuzz, we will keep proceed so when user find cannot receive OTP, they click resend
    }
    this.store.dispatch(new ClearEnrollResponses());
    this.store.dispatch(new EmailEnrollResponse(this.email2faData));
    this.router.navigate(['/login-validate']);
    // this.oktaAuth.session.setCookieAndRedirect((await firstValueFrom(this.appState$)).sessionToken, window.location.protocol + "//" + window.location.host + '/login-validate'); // Sets a cookie on redirect
  }

  onRegisterClick() {

    // this.choose2fa.requestPrompt(undefined, undefined);
    // this.router.navigate(['register']);
    this.showModal = true;
  }

  onForgotPasswordClick() {
    this.router.navigate(['/forgot-password']);
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  goBack() {
    history.go(-2);
  }

  onRegister = async (payload: any) => {
    if(payload.isSuccess) {
      console.log(payload.data);

      this.store.dispatch(new Authenticate(payload.data.value));
      this.store.dispatch(new StoreLoginCredentials(payload.email, payload.password));

      let data = await this.supportService.get2faStatus(payload.data.value);
      let sms2faData = data.find((item: any) => item && item.factorType === 'sms');
      let email2faData = data.find((item: any) => item && item.factorType === 'email' && !item.profile.email.endsWith('@cicciam.hk'));
      this.store.dispatch(new SmsEnrollResponse(sms2faData));
      this.store.dispatch(new EmailEnrollResponse(email2faData));

      this.router.navigate(['register-validate']);
    }
  }

  onCancel2fa = async () => {
    if(this.loginForm) this.loginForm.setIsLoading(false);
  }
}
