import { GridOptions } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridCellDropdownComponent } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, toArray } from 'rxjs';
import { SetOidcError } from '../app.actions';
import { STATE_TOKEN, StateModel } from '../app.states';

@Component({
  selector: 'ciciam-portallogincallback',
  templateUrl: './portallogincallback.component.html',
  styleUrls: ['./portallogincallback.component.scss'],
})
export class PortalLoginCallbackComponent {
  faUserCircle = faUserCircle;

  form: FormGroup = this.fb.group({
    company_name: [''],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    mobile: ['', Validators.required],
    first_name_zh: [''],
    last_name_zh: [''],
    addr_line_1: [''],
    addr_line_2: [''],
    addr_sub_district: [''],
    addr_district: [''],
    addr_line_1_zh: [''],
    addr_line_2_zh: [''],
    addr_sub_district_zh: [''],
    addr_district_zh: [''],
    company_addr_line_1: [''],
    company_addr_line_2: [''],
    company_addr_sub_district: [''],
    company_addr_district: [''],
    company_phone: [''],
    company_website: [''],
    company_ind: [''],
    personal_title: ['', Validators.required],
    job_title: ['', Validators.required],
    fax: [''],
  });

  userData: any;

  error: string = '';
  error_description: string = '';

  redirectAfterLogin?: string;

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  constructor(private readonly fb: FormBuilder, public oidcSecurityService: OidcSecurityService, private route: ActivatedRoute, public http: HttpClient, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  private authStateService: OktaAuthStateService, public router: Router, private store: Store) {
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      let state = params['state'];
      this.error = params['error'];
      this.error_description = params['error_description'];

      if (code && state) {
        this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
          if (isAuthenticated) {
            console.log(userData);
            this.userData = userData;
            this.router.navigateByUrl('/home');
            // this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
            //   this.router.navigate(['/home']);
            // });
          }
        });
      } else {
        if(this.oidcSecurityService.isAuthenticated()) {
          this.oidcSecurityService.getUserData().subscribe((userData) => {
            this.userData = userData;
          });

          // this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
          //   console.log(data);
          // });
        } else if(this.error && this.error_description) {
          this.store.dispatch(new SetOidcError(this.error));
          this.router.navigate(['/']);
        }
      }
    });
  }

  async ngOnInit() {

  }

  onSubmit() {
    console.log(this.form.value);
  }
}
