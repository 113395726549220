import { Component, FactoryProvider, Inject, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuGroup, MenuItem } from '@digitsolu/notus-angular';
import { DropdownItem } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faTv, faAddressBook, faChalkboard, faBookOpen, faArrowRight, faSignOutAlt, faTableCells, faTabletAlt, faShield, faShieldHalved, faClockRotateLeft, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Select, Store } from '@ngxs/store';
import { first, firstValueFrom, from, map, mergeMap, Observable, of } from 'rxjs';
import { Location } from '@angular/common';
import OktaAuth, { AccessToken, IDToken, TokenManagerInterface, UserClaims } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { StateModel, STATE_TOKEN } from '../app.states';
import { SupportService } from '../../services/support.service';
import { SetLanguage } from '../app.actions';

@Component({
  selector: 'ciciam-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

  faUserCircle = faUserCircle;

  public readonly menu: MenuItem[] = [
    {
      icon: faTableCells,
      routerLink: '/home',
      itemName: 'Application'
    },
    {
      icon: faUserCircle,
      routerLink: '/home/profile',
      itemName: 'User Profile'
    },
    {
      icon: faShieldHalved,
      routerLink: '/home/security',
      itemName: 'Security'
    },
    {
      icon: faClockRotateLeft,
      routerLink: '/home/history',
      itemName: 'Login History'
    },
    {
      icon: faArrowRightFromBracket,
      routerLink: '/home/logout',
      itemName: 'Sign Out'
    },
  ];

  public readonly items: DropdownItem[][] = [
    [
      {
        name: '設定',
        routerLink: '/user/settings',
      },
    ],
    [
      {
        name: '登出',
        routerLink: '/logout'
      },
    ]
  ];

  pageIcon = faTv;
  pageName = 'Home';

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private store: Store, private route: ActivatedRoute, private router: Router, private location: Location, private supportService: SupportService) {
    router.events.subscribe((val) => {
      let u = location.path().split('/');
      switch(u[u.length - 1].split('?')[0]) {
        case 'home':
          this.pageIcon = faTableCells;
          this.pageName = 'Application';
          break;
        case 'profile':
          this.pageIcon = faUserCircle;
          this.pageName = 'User Profile';
          break;
        case 'security':
          this.pageIcon = faShieldHalved;
          this.pageName = 'Security';
          break;
        case 'history':
          this.pageIcon = faClockRotateLeft;
          this.pageName = 'Login History';
          break;
      }
    });

    route.queryParams.subscribe(async params => {
      if(params['lang']) {
        switch(params['lang']) {
          case 'zh-Hant':
          case 'zh-Hans':
          case 'en':
            this.store.dispatch(new SetLanguage(params['lang']));
            break;
        }
      }
      if(params['display']) {
        switch(params['display']) {
          case 'zh-Hant':
          case 'zh-Hans':
          case 'en':
            this.store.dispatch(new SetLanguage(params['display']));
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.checkAuth();

    if(localStorage.getItem('redirectUrl')) {
      console.log('nav to ' + localStorage.getItem('redirectUrl')!);
      this.router.navigateByUrl(localStorage.getItem('redirectUrl')!);
      localStorage.removeItem('redirectUrl');
    }
  }

  async checkAuth() {
    // if(!me) {
    //   // TOOD: redirect to login page, but actaully should handle by authguard
    // } else {
    //   let redirectUrl = me['customRedirectURL'];
    //   if(redirectUrl) {
    //     // clear customRedirectURL
    //     me['customRedirectURL'] = '';

    //     // redirect to customRedirectURL
    //   }

    // }

    // const tokenManager: TokenManagerInterface = this.oktaAuth.tokenManager;
    // const accessToken: AccessToken = await tokenManager.get('accessToken') as AccessToken;
    // console.log(accessToken);
    // const idToken: IDToken = await tokenManager.get('idToken') as IDToken;
    // console.log(idToken);
    // const userInfo: UserClaims = await this.oktaAuth.token.getUserInfo(session., idToken);

    // // var user = await this.oktaAuth.token.getUserInfo();
    // console.log(user);
  }
}
