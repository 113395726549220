import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { CommonsModule } from '@digitsolu/commons';
import { CardComponent } from './widgets/card/card.component';
import { RoundedIconComponent } from './widgets/rounded-icon/rounded-icon.component';
import { SearchBoxComponent } from './widgets/search-box/search-box.component';
import { CardDashboardComponent } from './widgets/card-dashboard/card-dashboard.component';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { UiTailwindModule } from '@digitsolu/ui-tailwind';

@NgModule({
  imports: [CommonModule, RouterModule, FontAwesomeModule, CommonsModule, UiTailwindModule],
  declarations: [
    SidebarComponent,
    TopbarComponent,
    CardComponent,
    RoundedIconComponent,
    SearchBoxComponent,
    CardDashboardComponent,
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    CardComponent,
    RoundedIconComponent,
    SearchBoxComponent,
    CardDashboardComponent,
  ],
})
export class NotusAngularModule { }
