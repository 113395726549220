import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { firstValueFrom, Observable } from 'rxjs';
import { ClearLoginCredentials, ClearOAuthSigninRequest, SmsEnrollResponse } from '../../app.actions';
import { StateModel, STATE_TOKEN } from '../../app.states';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'ciam-forgot-password-validate',
  templateUrl: './forgot-password-validate.component.html',
  styleUrls: ['./forgot-password-validate.component.scss'],
})
export class ForgotPasswordValidateComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;

  uid: string = '';
  code: string = '';
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  username?: string = '';
  password?: string = '';

  isSms: boolean = false;
  isSmsSuccess: boolean = false;
  isEmail: boolean = false;
  isEmailSuccess: boolean = false;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService) {
    console.log('hihi0');
    this.appState$.subscribe((state) => {
      if(state.smsEnrollResponse) {
        this.isSms = true;
      }
      if(state.emailEnrollResponse) {
        this.isEmail = true;
      }

      this.username = state.username;
      this.password = state.password;

      console.log(this.username);
      console.log(this.password);

      console.log('hihi1');
      if(state.oauthSigninRequest) {
        console.log('hihi2');
        this.state = state.oauthSigninRequest.state;
        this.nonce = state.oauthSigninRequest.nonce;
        this.code_challenge = state.oauthSigninRequest.code_challenge;
        this.code_challenge_method = state.oauthSigninRequest.code_challenge_method;
        this.client_id = state.oauthSigninRequest.client_id;
        this.redirect_uri = state.oauthSigninRequest.redirect_uri;
        this.response_type = state.oauthSigninRequest.response_type;
        this.display = state.oauthSigninRequest.display;
        this.scope = state.oauthSigninRequest.scope;
      }
    });
  }

  async ngOnInit() {

    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
  }

  onCodeChanged = ($event: any) => {
    this.code = $event;
  }

  onCodeCompleted = ($event: any) => {

  }

  onSmsResult = async ($event: any) => {
    this.isSmsSuccess = true;
    await this.onVerifcationComplete();
  }

  onEmailResult = async ($event: any) => {
    this.isEmailSuccess = true;
    await this.onVerifcationComplete();
  }

  onVerifcationComplete = async () => {
    if((!this.isSms || this.isSmsSuccess) && (!this.isEmail || this.isEmailSuccess)) {
      this.router.navigate(['/forgot-password-reset']);
    }
  }

}
