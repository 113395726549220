import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subscription, firstValueFrom } from 'rxjs';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { faCheck, faTimes, faEyeSlash, faEye, faCircle, faArrowLeft, faShare, faShareFromSquare, faEdit } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'ciciam-form-register',
  styleUrls: ['./form-register.component.scss'],
  templateUrl: './form-register.component.html',
})
export class FormRegisterComponent implements OnDestroy {

  @Input() websiteName = 'Flowbite';
  @Input() set message(stream: Observable<string | null>) {
    this.subscriptions.add(stream.subscribe(x => this.messageSubject.next(x)));
  }
  @Input() pv: boolean = true;
  @Input() isTandC: boolean = true;
  @Input() mode: string = 'register';
  @Input() disableUserInfo: boolean = false;

  @Output() credential = new EventEmitter<any>();
  @Output() login: EventEmitter<any> = new EventEmitter();

  userId?: string;
  isLoading: boolean = false;

  isPasswordHasLowercase: boolean = false;
  isPasswordHasUppercase: boolean = false;
  isPasswordHasNumber: boolean = false;
  isPasswordLength8: boolean = false;
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;

  isTandc1Read = false;
  isTandc1 = false;
  isTandc1Error = false;
  isTandc2Read = false;
  isTandc2 = false;
  isTandc2Error = false;
  noCompanyInfo = false;
  isSubmitted = false;

  faCheck = faCheck;
  faTimes = faTimes;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircle = faCircle;
  faBack = faArrowLeft;
  faGoto = faShareFromSquare;
  faEdit = faEdit;

  public steps = [
    {label: 'step1', icon: 'fas fa-user', active: false, completed: false},
    {label: 'step2', icon: 'fas fa-user', active: false, completed: false},
    {label: 'step3', icon: 'fas fa-user', active: false, completed: false},
  ];
  currentStep: number = 1;

  credentialForm = this.fb.nonNullable.group(
    {
      personalTitle: ['', Validators.required],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],

      firstNameZh: [''],
      lastNameZh: [''],

      email: [''],
      mobilePhone: [''],

      password: ['', Validators.required],
      repeatPassword: ['', Validators.required],

      companyName: ['', Validators.required],
      companyInd: ['', Validators.required],
      jobTitle: ['', Validators.required],

      noCompanyInfo: [false]

      // addrLine1: [''],
      // addrLine2: [''],
      // addrSubDistrict: [''],
      // addrDistrict: [''],
      // addrLine1Zh: [''],
      // addrLine2Zh: [''],
      // addrSubDistrictZh: [''],
      // addrDistrictZh: [''],
      // companyAddrLine1: [''],
      // companyAddrLine2: [''],
      // companyAddrSubDistrict: [''],
      // companyAddrDistrict: [''],
      // companyPhone: [''],
      // companyWebsite: [''],
      // fax: [''],

    }
  );


  private readonly messageSubject = new BehaviorSubject<string | null>(null);
  public readonly message$ = this.messageSubject.asObservable();

  private readonly subscriptions = new Subscription();

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  constructor(public translate: TranslateService, private readonly fb: FormBuilder, private http: HttpClient, private store: Store, private supportService: SupportService, private toastr: ToastrService, private router: Router) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setFormValue(userId: string, obj: any) {
    this.userId = userId;

    let values = {
      personalTitle: obj.personalTitle ?? '',
      firstName: obj.firstName ?? '',
      lastName: obj.lastName ?? '',
      firstNameZh: obj.firstNameZh ?? '',
      lastNameZh: obj.lastNameZh ?? '',
      email: obj.email && !obj.email.endsWith('@cicciam.hk') ? obj.email : '',
      mobilePhone: obj.mobilePhone ? obj.mobilePhone.replace('+852', '') : '',
      // userIdentity: obj.userIdentity ?? '',
      companyName: obj.companyName ?? '',
      companyInd: obj.companyInd ?? '',
      jobTitle: obj.jobTitle ?? '',
      password: '',
      repeatPassword: '',
      noCompanyInfo: obj.noCompanyInfo == true ?? false
    }

    if(this.mode != 'register') {
      this.credentialForm.controls['email'].disable();
      this.credentialForm.controls['mobilePhone'].disable();
      this.credentialForm.controls['email'].clearValidators();
      this.credentialForm.controls['mobilePhone'].clearValidators();
    }

    this.credentialForm.setValue({
      ...this.credentialForm.value,
      ...values
    })

    this.checkNoCompanyInfo();
  }

  checkPassword($event: any) {
    let password = this.credentialForm.controls['password'].value;

    this.isPasswordHasLowercase = /[a-z]/.test(password);
    this.isPasswordHasUppercase = /[A-Z]/.test(password);
    this.isPasswordHasNumber = /[0-9]/.test(password);
    this.isPasswordLength8 = password.length >= 8;
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPassword() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  onTandc1Scroll($event: any) {
    if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight) {
      this.isTandc1Read = true;
    }
  }

  onTandc1Change($event: any) {
    if(!this.isTandc1Read) {
      $event.srcElement.checked = false;
      this.isTandc1Error = true;
      return;
    }
    this.isTandc1 = $event.srcElement.checked;
    this.isTandc1Error = false;
  }

  onTandc2Scroll($event: any) {
    if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight) {
      this.isTandc2Read = true;
    }
  }

  onTandc2Change($event: any) {
    if(!this.isTandc2Read) {
      $event.srcElement.checked = false;
      this.isTandc2Error = true;
      return;
    }
    this.isTandc2 = $event.srcElement.checked;
    this.isTandc2Error = false;
  }

  onNoCompanyInfoChange($event?: any) {
    // this.noCompanyInfo = $event.srcElement.checked;
    if(!$event) { this.credentialForm.controls['noCompanyInfo'].setValue(!this.credentialForm.value['noCompanyInfo']); }
    this.checkNoCompanyInfo();
  }

  checkNoCompanyInfo() {
    if(this.credentialForm.controls['noCompanyInfo'].value) {
      this.credentialForm.controls['companyName'].disable();
      this.credentialForm.controls['companyInd'].disable();
      this.credentialForm.controls['jobTitle'].disable();
      this.credentialForm.controls['companyName'].clearValidators();
      this.credentialForm.controls['companyInd'].clearValidators();
      this.credentialForm.controls['jobTitle'].clearValidators();
      this.credentialForm.controls['companyName'].updateValueAndValidity();
      this.credentialForm.controls['companyInd'].updateValueAndValidity();
      this.credentialForm.controls['jobTitle'].updateValueAndValidity();
    } else if(!this.disableUserInfo) {
      this.credentialForm.controls['companyName'].enable();
      this.credentialForm.controls['companyInd'].enable();
      this.credentialForm.controls['jobTitle'].enable();
      this.credentialForm.controls['companyName'].setValidators([Validators.required]);
      this.credentialForm.controls['companyInd'].setValidators([Validators.required]);
      this.credentialForm.controls['jobTitle'].setValidators([Validators.required]);
      this.credentialForm.controls['companyName'].updateValueAndValidity();
      this.credentialForm.controls['companyInd'].updateValueAndValidity();
      this.credentialForm.controls['jobTitle'].updateValueAndValidity();
    }
  }

  async onRegisterWithEmail() {
    console.log('hi1');
    this.messageSubject.next(null);

    this.credentialForm.setErrors(null);

    this.isSubmitted = true;

    this.credentialForm.controls['email'].setErrors(null);
    this.credentialForm.controls['mobilePhone'].setErrors(null);
    this.credentialForm.controls['repeatPassword'].setErrors(null);

    this.credentialForm.controls['personalTitle'].markAsTouched();
    this.credentialForm.controls['firstName'].markAsTouched();
    this.credentialForm.controls['lastName'].markAsTouched();
    this.credentialForm.controls['email'].markAsTouched();
    this.credentialForm.controls['mobilePhone'].markAsTouched();
    this.credentialForm.controls['password'].markAsTouched();
    this.credentialForm.controls['repeatPassword'].markAsTouched();
    this.credentialForm.controls['companyName'].markAsTouched();
    this.credentialForm.controls['companyInd'].markAsTouched();
    this.credentialForm.controls['jobTitle'].markAsTouched();

    if(!(this.isPasswordHasLowercase && this.isPasswordHasUppercase && this.isPasswordHasNumber && this.isPasswordLength8)) {
      this.credentialForm.controls['password'].setErrors({ 'requirement': 'regerror.password' });
    }

    if(this.credentialForm.controls['email'].value == '' && this.credentialForm.controls['mobilePhone'].value == '') {
      this.credentialForm.setErrors({ 'emailandphonemissing': 'error' });
      this.credentialForm.controls['email'].setErrors({ 'emailandphonemissing': 'error' });
      this.credentialForm.controls['mobilePhone'].setErrors({ 'emailandphonemissing': 'error' });
    }

    if(this.credentialForm.controls['email'].value != '') {
      console.log('hi2');
      var validRegex = /(?:[a-z0-9!#$%&*+/=?^_{|}-]+(?:\.[a-z0-9!#$%&*+/=?^_{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]?[a-z0-9])+|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      if(!this.credentialForm.controls['email'].value.match(validRegex)) {
        console.log('hi3');
        this.credentialForm.controls['email'].setErrors({ 'emailformat': 'regerror.emailformat' });
      }
    }

    if(this.credentialForm.controls['mobilePhone'].value != '') {
      console.log('hi2a');
      var validRegex = /^(\+[0-9]+|[0-9]{8})$/;
      if(!this.credentialForm.controls['mobilePhone'].value.match(validRegex)) {
        console.log('hi3a');
        this.credentialForm.controls['mobilePhone'].setErrors({ 'phoneformat': 'regerror.phoneformat' });
      }
    }

    if(!this.credentialForm.valid) {
      return;
    }

    // this.credential.emit(payload);

    let formValue = this.credentialForm.value;

    let password = formValue.password;
    let repeatPassword = formValue.repeatPassword;

    if(password !== repeatPassword) {
      // TODO: show error message
      this.credentialForm.controls['repeatPassword'].setErrors({ 'match': 'error' });
      return;
    }

    this.setIsLoading(true);

    // check if email already used
    if(formValue.email) {
      var emailSearch = await this.supportService.lookupUserByEmail(formValue.email);
      if(emailSearch !== false) {
        this.setIsLoading(false);
        this.credentialForm.controls['email'].setErrors({ 'emailused': 'regerror.emailused' });
        return;
      }
    }

    // check if phone number already used
    if(formValue.mobilePhone) {
      var phoneSearch = await this.supportService.lookupUserByPhone(formValue.mobilePhone);
      if(phoneSearch !== false) {
        this.setIsLoading(false);
        this.credentialForm.controls['mobilePhone'].setErrors({ 'phoneused': 'regerror.phoneused' });
        return;
      }
    }

    this.setIsLoading(false);

    this.currentStep = 2;
  }

  onRegisterStep2Confirm() {
    this.currentStep = 3;
  }

  onRegisterStep2Back() {
    this.currentStep = 1;
  }

  onRegisterStep3Back() {
    this.currentStep = 2;
  }

  async onRegisterStep3Confirm() {

    this.setIsLoading(true);

    let formValue = this.credentialForm.value;
    let password = formValue.password;

    if(formValue.mobilePhone && formValue.mobilePhone.length == 8 && !formValue.mobilePhone.startsWith('+')) {
      formValue.mobilePhone = '+852' + formValue.mobilePhone;
    }

    if(!formValue.email) {
      formValue.email = formValue.mobilePhone + '@cicciam.hk';
    }

    let payload = {
      "profile": {
        ...formValue,
        "login": formValue.email ? formValue.email : formValue.mobilePhone,
        "mobilePhone": formValue.mobilePhone ? formValue.mobilePhone : ''
      },
      "credentials": {
        "password" : { "value": password }
      }
    };

    delete payload.profile.password;
    delete payload.profile.repeatPassword;

    try {
      var result = await this.supportService.register({
        personalTitle: formValue.personalTitle ?? '',
        firstName: formValue.firstName ?? '',
        lastName: formValue.lastName ?? '',
        firstNameZh: formValue.firstNameZh ?? '',
        lastNameZh: formValue.lastNameZh ?? '',
        email: formValue.email,
        mobilePhone: formValue.mobilePhone ?? '',
        password: password ?? '',
        companyName: formValue.companyName ?? '',
        companyInd: formValue.companyInd ?? '',
        jobTitle: formValue.jobTitle ?? '',
        noCompanyInfo: formValue.noCompanyInfo == true ?? false
      });

        console.log(result);

        // if(formValue.email?.endsWith('@cicciam.hk')) {
        //   await this.supportService.removeEmail2fa(data.id);
        // }

        // this.isLoading = false;

        if(result == undefined || result.success != true) {
          if(result != undefined && result.errorCode != undefined) {
            if(result.errorCode == 'E0000001') {
              this.toastr.error(this.translate.instant('Your password seems contains part of your email / phone number, please modify and try again'), this.translate.instant('Error'));
              this.currentStep = 1;
            } else {
              this.toastr.error(this.translate.instant('Action failed, please try again ({{errorcode}})', { errorcode: result.errorCode }), this.translate.instant('Error'));
            }
          }
        }

        if(result != undefined && result.success == true) {
          this.credential.emit({ isSuccess: true, data: result, email: '', firstName: '', lastName: '', mobilePhone: '', ...this.credentialForm.value, password: password });
        } else {
          this.setIsLoading(false);
        }
    } catch(err: any) {
      console.log(err);

      this.setIsLoading(false);

      let errorMessages: string[] = [];

      if(err.error.errorCauses && err.error.errorCauses.length > 0) {
        for(let errorCause of err.error.errorCauses) {
          if(errorCause.errorSummary) {
            if (errorCause.errorSummary.includes('password')) {
              errorMessages = [...errorMessages, this.translate.instant('regerror.password')];
              this.credentialForm.controls['password'].setErrors({ 'requirement': 'regerror.password' });
            } else if(errorCause.errorSummary.includes('An object with this field already exists in the current organization') || errorCause.errorSummary.includes('目前的組織已存在使用此欄位的物件')) {
              errorMessages = [...errorMessages, this.translate.instant('regerror.emailorphoneused')];
              this.credentialForm.setErrors({ 'emailorphoneused': 'regerror.emailorphoneused' });
            } else {
              errorMessages = [...errorMessages, errorCause.errorSummary];
              this.credentialForm.setErrors({ 'error': errorCause.errorSummary });
            }
          }
        }
      }

      this.toastr.error(errorMessages.join('<br />'), this.translate.instant('Error'), {
        enableHtml: true,
      });
    }
  }


  async onUpdateProfile() {

    this.messageSubject.next(null);

    this.credentialForm.controls['password'].clearValidators();
    this.credentialForm.controls['password'].updateValueAndValidity();
    this.credentialForm.controls['repeatPassword'].clearValidators();
    this.credentialForm.controls['repeatPassword'].updateValueAndValidity();

    this.credentialForm.controls['personalTitle'].markAsTouched();
    this.credentialForm.controls['firstName'].markAsTouched();
    this.credentialForm.controls['lastName'].markAsTouched();
    this.credentialForm.controls['email'].markAsTouched();
    this.credentialForm.controls['mobilePhone'].markAsTouched();
    this.credentialForm.controls['companyName'].markAsTouched();
    this.credentialForm.controls['companyInd'].markAsTouched();
    this.credentialForm.controls['jobTitle'].markAsTouched();

    this.credentialForm.setErrors(null);
    this.credentialForm.updateValueAndValidity();

    if(!this.credentialForm.valid) {
      return;
    }

    // this.credential.emit(payload);

    let formValue = this.credentialForm.value;

    delete formValue.email;
    delete formValue.mobilePhone;
    delete formValue.password;
    delete formValue.repeatPassword;

    this.setIsLoading(true);

    let result = await this.supportService.updateUserProfile(this.userId!, formValue);

    // TODO: check update success?

    this.setIsLoading(false);

    this.toastr.success(this.translate.instant('Update profile success'));

    this.credential.emit({ isSuccess: true, data: result, email: '', firstName: '', lastName: '', mobilePhone: '', password: '', ...this.credentialForm.value });
  }

  onLogin($event: any) {
    $event.preventDefault();
    this.login.emit();
  }

  setIsLoading(isLoad: boolean) {
    this.isLoading = isLoad;
    if(isLoad) {
      this.credentialForm.disable();
    } else {
      this.credentialForm.enable();
      this.checkNoCompanyInfo();
    }
  }

  gotoSecurityPage() {
    this.router.navigate(['/home/security']);
  }
}
