import { Component, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { SetOidcError } from '../../app.actions';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'cicciam-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss'],
})
export class LoginCallbackComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  access_token: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  token_type: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  expires_in: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  scope: string = ''; //=S256&
  state: string = ''; //=0oa4cd4j2gVdXd0Ac697&

  error: string = '';
  error_description: string = '';

  signIn: any;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private supportService: SupportService, private store: Store) {
    this.route.fragment.subscribe(async (fragment) => {
      const params = new URLSearchParams(fragment ?? '');
      this.access_token = params.get('access_token') ?? '';
      this.token_type = params.get('token_type') ?? '';
      this.expires_in = params.get('expires_in') ?? '';
      this.scope = params.get('scope') ?? '';
      this.state = params.get('state') ?? '';
      this.error = params.get('error') ?? '';
      this.error_description = params.get('error_description') ?? '';

      if(this.state && this.access_token && this.expires_in && this.scope) {
        let result = await this.supportService.updateTokenRecord(this.state, this.access_token, parseInt(this.expires_in), this.scope);
        if(result) {
          window.location.href = decodeURIComponent(result.redirectUri) + '?code=' + this.state + '&state=' + result.idpState;
        }
      } else {
        this.store.dispatch(new SetOidcError(this.error));
        this.router.navigate(['/']);
      }

    });
  }

  async ngOnInit() {
  }

  onRegister() {
    this.router.navigate(['register']);
  }
}

// 00GGZrTk4Vs7US51iYVoYNPW4Vb6_pZeVmInp43yCz


// Client ID: 0oa4cd4j2gVdXd0Ac697
// Client Secret: YgjzKW8eseZ2STsbmGue9B4NYbYrxEe1gzK6S4Pe


// Auth URL: https://onepass-sso-uat.cic.hk/oauth2/v1/authorize?idp=0oa4cd47jspgoWMIE697&client_id={clientId}&response_type={responseType}&response_mode={responseMode}&scope={scopes}&redirect_uri={redirectUri}&state={state}&nonce={nonce}
// Redirect URL: https://onepass-sso-uat.cic.hk/oauth2/v1/authorize/callback

//https://onepass-sso-uat.cic.hk/
//https://onepass-sso-uat.cic.hk/app/UserHome?iss=https%3A%2F%2Fonepass-sso-uat.cic.hk&session_hint=AUTHENTICATED
//https://onepass-sso-uat.cic.hk/oauth2/v1/authorize?client_id=okta.2b1959c8-bcc0-56eb-a589-cfcfb7422f26&code_challenge=fjkdJ-Nd_z2KvsLT0H5diykYrKkRBwdCZSH8XWNKpHA&code_challenge_method=S256&nonce=WTFLIk5lbJ8bZL26lZ4QBqiy9Xrk8sVlddP12CWdBQTtqOJ2bkM2cTZ57ZREpz3A&redirect_uri=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Fenduser%2Fcallback&response_type=code&state=0jbQwl4aV0R3jD5J8PeB4AlrM3pw8UAEKVJUjAAkZgk37AfABlLq9hjfJZ08nCiS&scope=openid%20profile%20email%20okta.users.read.self%20okta.users.manage.self%20okta.internal.enduser.read%20okta.internal.enduser.manage
// --> https://onepass-sso-uat.cic.hk/idp/idx/identify
// --> https://onepass-sso-uat.cic.hk/idp/idx/challenge
// --> https://onepass-sso-uat.cic.hk/idp/idx/challenge/answer


//http://onepass-sso-uat.cic.hk/oauth2/v1/authorize?
// response_type=token&
// scope=openid&
// state=TEST&
// nonce=TEST&
// client_id=0oa4cd4j2gVdXd0Ac697&
// redirect_uri=https:%2F%2Fcic-ciam.yet-another-testing-domain.com%3A4200%2Flogin%2Fcallback&
// sessionToken=20111pje9OV2TYqDRi8WkIUHVxBvIBx4xlAilmNmuqP5vSUV-DdvzMB

//http://onepass-sso-uat.cic.hk/oauth2/v1/authorize?client_id=0oa4cd4j2gVdXd0Ac697&nonce=DYXLUc8B55WxXcDJYnvOYWr4uzmEshwo&redirect_uri=https:%2F%2Fcic-ciam.yet-another-testing-domain.com%3A4200%2Flogin%2Fcallback&response_type=token&scope=email%20openid%20profile&sessionToken=20111pje9OV2TYqDRi8WkIUHVxBvIBx4xlAilmNmuqP5vSUV-DdvzMB&state=T


// http://onepass-sso-uat.cic.hk/oauth2/v1/authorize?response_type=token&scope=openid%20profile%20offline_access&state=TEST&nonce=TEST&client_id=0oa4ds3u5n1WTtDgv697&redirect_uri=https:%2F%2Fcic-ciam.yet-another-testing-domain.com%3A4200%2Flogin%2Fcallback&sessionToken=20111Ho1PKsFl0K215pB3S0aXI6ELARlUrTa1oKmOADadI_Z3SxI-mM
// https://cic-ciam.yet-another-testing-domain.com:4200/login/callback#
// access_token=eyJraWQiOiJ3dXhiU0tOcGY0WXV3UE9UcXJwMG5jYV9YeHNwdm53SFJ3VkZlUVJjWDRFIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULldURjZDMHVhY2VCNkRzdWdjRUd0NFhFUG95WURvb1M2dE1iUWFKV19VbnMiLCJpc3MiOiJodHRwczovL2NpYy1vbmVwYXNzLXVhdC5va3RhLmNvbSIsImF1ZCI6Imh0dHBzOi8vY2ljLW9uZXBhc3MtdWF0Lm9rdGEuY29tIiwic3ViIjoibGVvLmxpQGRldi5oa21jaS5jb20iLCJpYXQiOjE2Nzc1OTczNTMsImV4cCI6MTY3NzYwMDk1MywiY2lkIjoiMG9hNGRzM3U1bjFXVHREZ3Y2OTciLCJ1aWQiOiIwMHU0MGIzeGEwd2p4UG5CSzY5NyIsInNjcCI6WyJvcGVuaWQiXSwiYXV0aF90aW1lIjoxNjc3NTk3MzUyfQ.l9DnKCy9y2qOVxvqKr-qQvHkE-IDa1_v1Ur27q7ihVkNH7JqcPLjvxQTRmowmvROdGJXqJIQLpBRDR3iYSGBIdRUtFG-N4l3I--naFSnD8sEQSbr-ifpPR7MlHlEMRriP3y-hlT7ej0-Ve8bKDFnMF-eweJqamqBfD5f3dETcy6EOm1ZoYoBi4ELFEhaerZdU3pTW85_UcLGwVbLtgoTUMuEgYNTlSr1yddsaqLhl-qcGp_Db4UpSy_8RZ0ki-4q9rE016ckObp883HqJY9coVlpDznBiLxsk7-A2DtJJ2dQO4xpGhaxFMFx-7dSjZPsRLp2fe7i68avBnZLKL3oPw&
// token_type=Bearer&expires_in=3600&scope=openid&state=TEST


// https://cic-ciam.yet-another-testing-domain.com:4200/login/callback#
// access_token=eyJraWQiOiJ3dXhiU0tOcGY0WXV3UE9UcXJwMG5jYV9YeHNwdm53SFJ3VkZlUVJjWDRFIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlZsWGR6aWZoZnppSkRSalV1YVZ0YlRncWd3WVpEY3RkcXpGMEo5bFpyYlkiLCJpc3MiOiJodHRwczovL2NpYy1vbmVwYXNzLXVhdC5va3RhLmNvbSIsImF1ZCI6Imh0dHBzOi8vY2ljLW9uZXBhc3MtdWF0Lm9rdGEuY29tIiwic3ViIjoibGVvLmxpQGRldi5oa21jaS5jb20iLCJpYXQiOjE2Nzc1OTgyODAsImV4cCI6MTY3NzYwMTg4MCwiY2lkIjoiMG9hNGRzM3U1bjFXVHREZ3Y2OTciLCJ1aWQiOiIwMHU0MGIzeGEwd2p4UG5CSzY5NyIsInNjcCI6WyJvcGVuaWQiLCJwcm9maWxlIl0sImF1dGhfdGltZSI6MTY3NzU5NzM1Mn0.EqQb7ThooIDILkVAPxnsKUGyGg6F5QtERas5ZLvq4iK5BhMPBN_RDnivJTzvd5g382tFYn2IP9SJUG8yepr0RfutLJCZUcAHrfweW7S-HXkaND6Qkz6XWFT3uwvpv9m50BsEEjTdTMbycKwFPkrZsPPC6hH6EHgt6x9fJFqn_FdVgrf_N855YFluvZJEomdGEjiR-3ovfyS-89_w-05eOJHowxbhnQCxLABiXnZNefPgwdKN3nR4Sr7ZWXfYOzak-9tkhiSx4pdwZRZIfO8kXTngaDcYuqJ38H-xwjdjf3KsOPWNymtyEf0FckYFOcYGBKj7YTEv5eKENFUScAOw9w&
// token_type=Bearer&expires_in=3600&scope=openid+profile&state=TEST
