
<ciciam-frame-front>
  <div class="mr-0 ml-auto">
  <ciam-sms-code-widget *ngIf="isSms" [isRight]="false" [isAllowPhoneChange]="true"
  (result)="onSmsResult($event)"></ciam-sms-code-widget>
  <div *ngIf="isSms && isEmail" class="h-8"></div>
  <ciam-email-code-widget *ngIf="isEmail" [isRight]="false" [isAllowEmailChange]="true"
  (result)="onEmailResult($event)"></ciam-email-code-widget>
  </div>
</ciciam-frame-front>
