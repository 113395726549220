
<div class="bg-fallback">
  <div class="bg bg-top bg-cover md:bg-right max-w-[2200px] max-h-[1200px] mx-auto">
    <div class="md:hidden text-center mb-4"><img class="mx-auto pt-4 w-[80vw]" src="/assets/images/mobileThemeImg.png" /></div>
    <div class="bg-white min-h-[50%] md:min-h-screen flex flex-col items-start px-8 pt-8 rounded-t-3xl md:rounded-t-[4rem] md:px-0 md:pt-0 md:items-end md:bg-transparent">
      <tw-dropdown menu [items]="items" class="hidden md:block" *ngIf="!hideLanguageButton">
        <img src="/assets/images/language.png" class="m-4 cursor-pointer" />
      </tw-dropdown>
      <div class="flex flex-row justify-between w-full md:block md:w-auto">
        <img src="/assets/images/cic-logo.png" class="md:mt-40 md:mr-20 w-48 md:w-64 md:mb-12" />

        <tw-dropdown menu [items]="items" class="-mt-8 -mr-8 block md:hidden">
          <img src="/assets/images/language.png" class="m-4 cursor-pointer w-6" />
        </tw-dropdown>
      </div>
      <div class="md:mr-10 w-full md:max-w-[32rem]">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
