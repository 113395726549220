import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { firstValueFrom, Observable } from 'rxjs';
import { ClearOAuthSigninRequest, FactorInfoChange, FactorInfoChangeConfirm, SmsEnrollResponse } from '../../app.actions';
import { StateModel, STATE_TOKEN } from '../../app.states';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { faCheckCircle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { EmailChangePopupComponent } from '../../popup/email-change-popup/email-change-popup.component';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'ciam-email-code-widget',
  templateUrl: './email-code.component.html',
  styleUrls: ['./email-code.component.scss'],
})
export class WidgetEmailCodeComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;

  @Input() isRight: boolean = true;
  @Input() isFirstSent: boolean = true;

  uid: string = '';
  code: string = '';
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  emailEnrollResponse: any = null;

  isSms: boolean = false;
  isEmail: boolean = false;
  @Output() result = new EventEmitter<{ success: boolean }>();

  isLoadingSubmit: boolean = false;
  isLoadingResend: boolean = false;
  isSuccess: boolean = false;
  isError: boolean = false;

  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;
  faPenToSquare = faPenToSquare;
  @ViewChild('emailChangePopup') public emailChangePopup!: EmailChangePopupComponent;
  @Input() isAllowEmailChange: boolean = false;

  resendCooldown: number = 30;        // current cooldown in seconds
  resendCooldownInit: number = 30;    // cooldown in seconds
  resendCooldownTimer: any = null;    // timer for cooldown

  constructor(private router: Router, private translate: TranslateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, private toastr: ToastrService) {

  }

  ceil = Math.ceil;

  async ngOnInit() {
    if(!this.isFirstSent) {
      this.onResend(null);
    }
    this.appState$.subscribe(async (state) => {
      this.emailEnrollResponse = state.emailEnrollResponse;
    });
    this.resendCooldownTimer = setInterval(() => {
      if(this.resendCooldown > 0) {
        this.resendCooldown--;
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.resendCooldownTimer);
  }

  onCodeChanged = ($event: any) => {
    this.code = $event;
  }

  onCodeCompleted = ($event: any) => {
    this.onSubmit($event);
  }

  onSubmit = async ($event: any) => {
    if(this.isLoadingSubmit) { return; }

    this.isLoadingSubmit = true;
    this.isError = false;

    let emailEnrollResponse = (await firstValueFrom(this.appState$)).emailEnrollResponse;
    let userId = (await firstValueFrom(this.appState$)).userId;

    try {
      let result = await this.supportService.verify2FaEmail(userId!, this.code);

      if(result && result.result == true) {
        if(this.emailEnrollResponse != null && this.emailEnrollResponse?.profile?.email != null) {
          this.store.dispatch(new FactorInfoChange(this.emailEnrollResponse?.profile?.email));
        }
        if(result.login) {
          this.store.dispatch(new FactorInfoChange(result.login));
        }

        this.result.emit({success: true});
        this.isSuccess = true;

        this.store.dispatch(new FactorInfoChangeConfirm());
      } else {
        this.isError = true;
      }
    } catch(e) {
      this.isError = true;
    }

    this.isLoadingSubmit = false;
  }

  onResend = async ($event: any) => {
    if(this.isLoadingResend) { return; }

    this.isLoadingResend = true;

    let emailEnrollResponse = (await firstValueFrom(this.appState$)).emailEnrollResponse;
    let userId = (await firstValueFrom(this.appState$)).userId;

    try {
      let result = await this.supportService.enable2FaEmail(userId!, true, this.translate.currentLang);

      // TODO: how to determine success?
      if($event != null) {
        this.toastr.success(this.translate.instant('Email sent!'));
        this.resendCooldown = this.resendCooldownInit;
      }

      this.isLoadingResend = false;
    } catch(e) {
      this.toastr.error(this.translate.instant('Failed to send email, please try again later.'));
    }
  }

  emailMask(email: string) {
    return email.replace(/(.{2})(.*)(?=@)/, (a, b, c) => b + '*'.repeat(c.length));
  }

  onEditEmail = async ($event: any) => {
    this.emailChangePopup.requestPrompt();
  }

}
