<ciciam-sidebar [menu]="menu" menuName="" siteName="" menuHeader="" class="min-h-screen block">
  <div navm class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-8 px-4">
    <a class="text-xl inline-block text-notus-primary-900 w-full text-center md:w-auto md:text-3xl">
      <fa-icon class="mr-2" [icon]="pageIcon"></fa-icon>
      {{ pageName | translate }}
    </a>
  </div>
  <div nav class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-8 px-4">
    <a class="text-xl inline-block text-notus-primary-900 w-full text-center md:w-auto md:text-3xl">
      <fa-icon class="mr-2" [icon]="pageIcon"></fa-icon>
      {{ pageName | translate }}
    </a>
  </div>
</ciciam-sidebar>
