<!-- Sidebar -->
<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-80 z-30 p-4 md:px-12">
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-row flex-wrap items-center w-full mx-auto">
    <button
      class="absolute cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button" (click)="toggleCollapseShow('bg-white py-3 px-6 pl-12')">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <div class="flex-grow md:hidden">
      <ng-content select="[navm]"></ng-content>
    </div>
    <div class="md:hidden">
      <ng-content select="[menu]"></ng-content>
    </div>
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 w-[80%] max-w-xs z-40 overflow-y-auto h-screen items-center flex-1 rounded"
      [ngClass]="collapseShow">
      <div class="md:min-w-full md:hidden flex flex-wrap mb-6 my-1 ml-[-1.85rem]">
        <div class="flex justify-start">
          <button type="button"
            class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            (click)="toggleCollapseShow('hidden')">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
      </div>

      <div>
        <img src="/assets/images/cic-logo.png" class="w-40 mb-6 md:w-full md:mb-24" />
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li *ngFor="let item of menu" class="items-center">
            <a *ngIf="item.url; else internalLink" [href]="item.url" target="_blank"
              class="text-gray-600 hover:text-gray-800 text-sm block mb-4 no-underline font-semibold"
              (click)="onMenuItemClicked(item)">
              <fa-icon [icon]="item.icon" class="mr-2 text-gray-600 text-base" [fixedWidth]="true">
              </fa-icon>
              {{ item.itemName | translate }}
            </a>
            <ng-template #internalLink>
              <a [routerLink]="[item.routerLink]" class="text-lg py-6 block hover:text-notus-primary-800" routerLinkActive
                [routerLinkActiveOptions]="pathMatchOptions" #adminDashboard="routerLinkActive" [ngClass]="
                  adminDashboard.isActive
                    ? 'font-bold text-notus-primary-900'
                    : 'text-gray-600'
                " (click)="onMenuItemClicked(item)">
                <fa-icon [icon]="item.icon" class="mr-2 text-lg" [fixedWidth]="true"></fa-icon>
                {{ item.itemName | translate }}
              </a>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="mt-20 md:hidden">
        <div class="text-gray-600 hidden md:inline-block p-4 px-8 rounded-xl border border-gray-600 shadow-inner shadow-gray-400" *ngIf="isCompany">
          <fa-icon [icon]="faIcon" class="pr-2"></fa-icon>
          {{ 'Company User' | translate }}
        </div>
      </div>
      <div class="flex flex-row items-center -ml-4  md:hidden">
        <tw-dropdown menu [items]="items" class="block" dropdownPosition="top">
          <img src="/assets/images/language.png" class="m-4 cursor-pointer border-r border-r-gray-500 pr-6" />
        </tw-dropdown>
        <div class="flex flex-row items-baseline mb-2">
          <div class="p-2 px-3 text-base font-static cursor-pointer" (click)="setFontSize('font-small')">A</div>
          <div class="p-2 px-3 text-xl font-static cursor-pointer" (click)="setFontSize('font-normal')">A</div>
          <div class="p-2 px-3 text-2xl font-static cursor-pointer" (click)="setFontSize('font-large')">A</div>
        </div>
      </div>
    </div>
    <div>
      <div class="text-gray-600 hidden md:inline-block p-4 px-8 rounded-xl border border-gray-600 shadow-inner shadow-gray-400" *ngIf="isCompany">
        <fa-icon [icon]="faIcon" class="pr-2"></fa-icon>
        {{ 'Company User' | translate }}
      </div>
    </div>
    <div class="md:flex flex-row items-center hidden ">
      <tw-dropdown menu [items]="items" class="hidden md:block" dropdownPosition="top">
        <img src="/assets/images/language.png" class="m-4 ml-0 cursor-pointer border-r border-r-gray-500 pr-6" />
      </tw-dropdown>
      <div class="flex flex-row items-baseline mb-2">
        <div class="p-2 px-3 text-base font-static cursor-pointer" (click)="setFontSize('font-small')">A</div>
        <div class="p-2 px-3 text-xl font-static cursor-pointer" (click)="setFontSize('font-normal')">A</div>
        <div class="p-2 px-3 text-2xl font-static cursor-pointer" (click)="setFontSize('font-large')">A</div>
      </div>
    </div>
  </div>
</nav>

<!-- Contents -->
<div class="relative min-h-screen h-full md:ml-80 flex-col bg-gray-100">
  <!-- Navigation -->
  <nav
    class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center hidden md:flex grow-0 md:pt-16">
    <ng-content select="[nav]"></ng-content>
  </nav>

  <!-- Body -->
  <div class="px-8 mx-auto z-1 grow relative min-h-screen md:pt-32">
    <router-outlet></router-outlet>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
