<h2 class="text-xl">{{ 'My Applications' | translate }}</h2>
<hr class="my-4" />
<div class="flex flex-row flex-wrap gap-4 md:gap-8 ">
  <div *ngIf="isLoading">
    <flowbite-loading size="2.5rem"></flowbite-loading>
  </div>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="(apps?.length ?? 0) > 0">
      <ng-container *ngFor="let app of apps">
        <div class="flex flex-col rounded-3xl border-gray-300 border-2 w-[45%] md:w-48 py-4 bg-white cursor-pointer" *ngIf="blacklistApps.indexOf(app.id) < 0" (click)="onAppVisitClick(app)">
          <img [src]="app._links.logo[0].href" class="w-20 h-20 mx-auto">
          <div class="text-center mt-4 mx-2" data-id="{{app.id}}">{{ app.label }}</div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(apps?.length ?? 0) <= 0">
      <div class="text-center text-gray-500 dark:text-gray-400">
        {{ 'No application found' | translate }}
      </div>
    </ng-container>
  </ng-container>
</div>
