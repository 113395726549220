
<div class="flex flex-col">
<div class="w-[30rem] m-auto max-w-full">
  <p-timeline [value]="steps" layout="horizontal" align="top" *ngIf="mode == 'register'">
    <ng-template pTemplate="content" let-item>
      <div class="inline-block w-max text-center -translate-x-1/2 ml-2 mt-2" [class]="currentStep > (steps.indexOf(item) + 1) ? 'past' : currentStep == (steps.indexOf(item) + 1) ? 'current' : 'future'">{{ item.label | translate }}</div>
    </ng-template>
    <ng-template pTemplate="marker" let-item>
      <div class="p-timeline-event-marker" [class]="currentStep > (steps.indexOf(item) + 1) ? 'past' : currentStep == (steps.indexOf(item) + 1) ? 'current' : 'future'">
        <div class="text-green-900 translate-y-[-0.4rem] text-xl leading-[1rem]" *ngIf="currentStep > (steps.indexOf(item) + 1)">✓</div>
      </div>
  </ng-template>
  </p-timeline>
</div>
<div class="overflow-x-clip" [ngClass]="mode == 'register' ? 'overflow-y-auto max-h-[calc(100vh-18rem)]' : ''">
  <div class="relative overflow-x-hidden" [ngClass]="mode == 'register' ? 'h-[calc(100vh-18rem)] overflow-y-auto' : ''">
    <div
      class="switch-page w-full max-w-3xl md:mt-0 xl:p-0 mr-auto"
      [class]="(currentStep == 1 ? 'active' : currentStep > 1 ? 'past' : 'future') + ' ' + (mode == 'register' ? 'h-[calc(100vh-18rem)] overflow-y-auto' : '')"
    >
      <div class="md:space-y-6 px-1 sm:px-4" [class]="pv ? '' : 'p-0 sm:p-0 sm:px-0'" action="#" [formGroup]="credentialForm">
        <!-- Personal Title -->
        <div class="grid grid-cols-12">
          <label
            for="email"
            class="col-span-12 md:col-span-2 block mb-2 text-base font-medium text-green-900 dark:text-white pr-1"
            >{{ 'Personal Title' | translate }} <span class="text-red-500">*</span></label
          >
          <div class="col-span-12 md:col-span-10 mb-4">
            <div class=" flex flex-row">
              <div class="mr-4 cursor-pointer" (click)="disableUserInfo ? null : credentialForm.controls['personalTitle'].setValue('Mr.')">
                <input type="radio" name="personalTitle" formControlName="personalTitle" class="rounded-full checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" value="Mr."
                [attr.disabled]="disableUserInfo ? '' : null" />
                <span class="text-sm text-gray-600 ml-2">{{ 'Mr.' | translate }}</span>
              </div>
              <div class="mr-4 cursor-pointer" (click)="disableUserInfo ? null : credentialForm.controls['personalTitle'].setValue('Ms.')">
                <input type="radio" name="personalTitle" formControlName="personalTitle" class="rounded-full checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" value="Ms."
                [attr.disabled]="disableUserInfo ? '' : null" />
                <span class="text-sm text-gray-600 ml-2">{{ 'Ms.' | translate }}</span>
              </div>
              <div class="mr-4 cursor-pointer" (click)="disableUserInfo ? null : credentialForm.controls['personalTitle'].setValue('Mrs.')">
                <input type="radio" name="personalTitle" formControlName="personalTitle" class="rounded-full checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" value="Mrs."
                [attr.disabled]="disableUserInfo ? '' : null" />
                <span class="text-sm text-gray-600 ml-2">{{ 'Mrs.' | translate }}</span>
              </div>
            </div>
            <div
            *ngIf="credentialForm.controls['personalTitle'].invalid && (credentialForm.controls['personalTitle'].dirty || credentialForm.controls['personalTitle'].touched)"
            class="text-red-600 col-span-10 text-xs mt-1"
            >
              <div *ngIf="credentialForm.controls['personalTitle'].errors?.['required']">
                {{ 'Personal title is required.' | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- Name in English -->
        <div class="grid grid-cols-12 ">
          <div class="col-span-12 md:col-span-2">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white pr-1 my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Name in English' | translate }}</span> <span class="text-red-500">*</span></label
            >
          </div>
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
              <span class="p-float-label relative">
                <input
                  pInputText
                  name="lastName"
                  id="lastName"
                  class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  formControlName="lastName"
                  [attr.disabled]="disableUserInfo ? '' : null"
                />

                <label
                  for="lastName"
                  class=""
                >{{ 'Surname in English' | translate }}</label>
              </span>

            <div
            *ngIf="credentialForm.controls['lastName'].invalid && (credentialForm.controls['lastName'].dirty || credentialForm.controls['lastName'].touched)"
            class="text-red-600 col-span-10 text-xs mt-1"
            >
              <div *ngIf="credentialForm.controls['lastName'].errors?.['required']">
                {{ 'Surname is required.' | translate }}
              </div>
            </div>
            </div>
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-4">
              <span class="p-float-label relative">
              <input
              pInputText
                name="firstName"
                id="firstName"
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                formControlName="firstName"
                [attr.disabled]="disableUserInfo ? '' : null"
              />
              <label
                for="lastName"
                class=""
                >{{ 'Other names in English' | translate }}</label
              >
              </span>

            <div
            *ngIf="credentialForm.controls['firstName'].invalid && (credentialForm.controls['firstName'].dirty || credentialForm.controls['firstName'].touched)"
            class="text-red-600 col-span-10 text-xs mt-1"
            >
              <div *ngIf="credentialForm.controls['firstName'].errors?.['required']">
                {{ 'Other names is required.' | translate }}
              </div>
            </div>
            </div>
          </div>
        </div>
        <!-- Name in Chinese -->
        <div class="grid grid-cols-12 ">
          <div class="col-span-12 md:col-span-2">
            <label
              for="lastName"
              class="mb-2 text-sm font-medium text-gray-500 dark:text-white hidden md:invisible"
              >{{ 'Surname in Chinese' | translate }}</label
            >
            <label
              for="email"
              class="col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Name in Chinese' | translate }}</span></label
            >
          </div>
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
              <span class="p-float-label relative">
                <input
                  pInputText
                  name="lastNameZh"
                  id="lastNameZh"
                  class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  formControlName="lastNameZh"
                  [attr.disabled]="disableUserInfo ? '' : null"
                />
                <label
                  for="lastName"
                  class=""
                  >{{ 'Surname in Chinese' | translate }}</label
                >
              </span>
            </div>
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-4">
              <span class="p-float-label relative">
              <input
              pInputText
                name="firstNameZh"
                id="firstNameZh"
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                formControlName="firstNameZh"
                [attr.disabled]="disableUserInfo ? '' : null"
              />
              <label
                for="lastName"
                class=""
                >{{ 'Other names in Chinese' | translate }}</label
              >
              </span>
            </div>
          </div>
        </div>
        <!-- Contact -->
        <div class="grid grid-cols-12">
          <div class="col-span-12 md:col-span-2">
            <label
              for="email"
              class="col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white pr-1 my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Contact' | translate }}</span> <span class="text-red-500">*</span></label
            >
          </div>
          <div class="col-span-12 md:col-span-10 grid grid-cols-12 mb-4 md:mb-2">
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2 md:mb-0">
              <div class="flex flex-row">
                <span class="p-float-label relative flex-grow">
                  <input
                  pInputText
                    name="mobilePhone"
                    id="mobilePhone"
                    class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    formControlName="mobilePhone"
                    autocomplete="off"
                  />
                  <label
                    for="lastName"
                    class=""
                    >{{ 'Phone Number' | translate }}</label
                  >
                </span>
                <div *ngIf="mode == 'profile'" class="!mt-0 !mb-0 !ml-1">
                  <button
                    class="justify-center text-white focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-1"
                    (click)="gotoSecurityPage()"
                    [class]="['bg-notus-primary-600', 'hover:bg-notus-primary-700', 'focus:ring-notus-primary-300', 'md:ml-0']"
                  >
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                </div>
              </div>
              <div
                *ngIf="credentialForm.controls['mobilePhone'].invalid"
                class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
                >
                <div *ngIf="credentialForm.controls['mobilePhone'].errors?.['phoneused']">
                  {{ 'Phone number had been used.' | translate }}
                </div>
                <div *ngIf="credentialForm.controls['mobilePhone'].errors?.['phoneformat']">
                  {{ 'Please enter a valid phone number.' | translate }}
                </div>
              </div>
            </div>
            <div class="col-span-12 pb-0 md:col-span-6 md:pr-2 md:pb-0 md:mb-0">
              <div class="flex flex-row">
                <span class="p-float-label relative flex-grow">
                  <input
                  pInputText
                    type="email"
                    name="email"
                    id="email"
                    class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0 "
                    placeholder=""
                    formControlName="email"
                    autocomplete="off"
                  />
                  <label
                    for="lastName"
                    class=""
                    >{{ 'Email Address' | translate }}</label
                  >
                </span>
                <div *ngIf="mode == 'profile'" class="!mt-0 !mb-0 !ml-1">
                  <button
                    class="justify-center text-white focus:outline-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-1"
                    (click)="gotoSecurityPage()"
                    [class]="['bg-notus-primary-600', 'hover:bg-notus-primary-700', 'focus:ring-notus-primary-300', 'md:ml-0']"
                  >
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                </div>
              </div>
              <div
                *ngIf="credentialForm.controls['email'].invalid"
                class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
                >
                <div *ngIf="credentialForm.controls['email'].errors?.['emailused']">
                  {{ 'Email address had been used.' | translate }}
                </div>
                <div *ngIf="credentialForm.controls['email'].errors?.['emailformat']">
                  {{ 'Email address format is incorrect.' | translate }}
                </div>
              </div>
            </div>
            <div
              *ngIf="credentialForm.invalid"
              class="text-red-600 col-span-12 text-xs mt-1"
              >
              <div *ngIf="credentialForm.controls['email'].errors?.['emailandphonemissing'] && credentialForm.controls['mobilePhone'].errors?.['emailandphonemissing']">
                {{ 'Please provider either phone or email.' | translate }}
              </div>
              <div *ngIf="credentialForm.errors?.['emailorphoneused']">
                {{ 'Phone number or email address had been used.' | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- Password -->
        <div class="grid grid-cols-12 mt-4 mb-6" *ngIf="mode == 'register'">
          <label
            for="password"
            class="col-span-12 md:col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white pr-1 my-2"
            ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Password' | translate }}</span> <span class="text-red-500">*</span></label
          >
          <div class="col-span-12 md:col-span-10 pr-2 relative">
            <input
              [type]="isPasswordVisible ? 'text' : 'password'"
              name="password"
              id="password"
              placeholder=""
              class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0"
              required=""
              formControlName="password"
              (input)="checkPassword($event)"
              autocomplete="new-password"
            />
            <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isPasswordVisible" [icon]="faEyeSlash" (click)="togglePassword()"></fa-icon>
            <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isPasswordVisible" [icon]="faEye" (click)="togglePassword()"></fa-icon>
          </div>
          <div class="col-span-12 md:col-span-2"></div>
          <div class="col-span-12 md:col-span-10 pr-2">
          <div
            *ngIf="credentialForm.controls['password'].invalid && (credentialForm.controls['password'].dirty || credentialForm.controls['password'].touched)"
            class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
            >
            <div *ngIf="credentialForm.controls['password'].errors?.['required']">
              {{ 'Password is required.' | translate }}
            </div>
            <div *ngIf="credentialForm.controls['password'].errors?.['requirement']">
              {{ 'Password does not fulfill requirement.' | translate }}
            </div>
          </div>
          <div class="text-xs mt-2">
            <div class="text-gray-600">{{ 'Password requirements:' | translate }}</div>
            <ul>
              <li [class]="!isPasswordHasLowercase ? 'text-gray-600' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon [icon]="faCheck" *ngIf="isPasswordHasLowercase"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasLowercase" size="xs"></fa-icon>
                </div>
                {{ 'One lowercase character' | translate }}
              </li>
              <li [class]="!isPasswordHasUppercase ? 'text-gray-600' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasUppercase"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasUppercase" size="xs"></fa-icon>
                </div>
                {{ 'One uppercase character' | translate }}
              </li>
              <li [class]="!isPasswordHasNumber ? 'text-gray-600' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordHasNumber"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordHasNumber" size="xs"></fa-icon>
                </div>
                {{ 'One number' | translate }}
              </li>
              <li [class]="!isPasswordLength8 ? 'text-gray-600' : 'text-green-700'">
                <div class="w-4 inline-block" >
                  <fa-icon class="" [icon]="faCheck" *ngIf="isPasswordLength8"></fa-icon>
                  <fa-icon [icon]="faCircle" *ngIf="!isPasswordLength8" size="xs"></fa-icon>
                </div>
                {{ '8 characters minimum' | translate }}
              </li>
            </ul>
          </div>
          </div>
        </div>
        <!-- Repeat Password -->
        <div class="grid grid-cols-12 mt-4 mb-4" *ngIf="mode == 'register'">
          <label
            for="repeatPassword"
            class="col-span-12 md:col-span-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white my-2"
            ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Repeat Password' | translate }}</span> <span class="text-red-500">*</span></label
          >
          <div class="col-span-12 md:col-span-10 pr-2 relative">
            <input
            [type]="isRepeatPasswordVisible ? 'text' : 'password'"
              name="repeatPassword"
              id="repeatPassword"
              placeholder=""
              class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-0"
              required=""
              formControlName="repeatPassword"
              autocomplete="new-password"
            />
            <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="!isRepeatPasswordVisible" [icon]="faEyeSlash" (click)="toggleRepeatPassword()"></fa-icon>
            <fa-icon class="absolute p-2.5 leading-5 top-0 right-4 text-gray-400" *ngIf="isRepeatPasswordVisible" [icon]="faEye" (click)="toggleRepeatPassword()"></fa-icon>
          </div>
          <div class="col-span-12 md:col-span-2"></div>
          <div
            *ngIf="credentialForm.controls['repeatPassword'].invalid && (credentialForm.controls['repeatPassword'].touched)"
            class="text-red-600 col-span-12 md:col-span-10 text-xs mt-1"
            >
            <div *ngIf="credentialForm.controls['repeatPassword'].errors?.['required']">
              {{ 'Repeat password is required.' | translate }}
            </div>
            <div *ngIf="credentialForm.controls['repeatPassword'].errors?.['match']">
              {{ 'The repeat password does not match.' | translate }}
            </div>
          </div>
        </div>

        <hr class="mb-4" />

        <!-- Company -->
        <div class="grid grid-cols-12">
          <div class="col-span-12 md:col-span-2">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white my-2"
              ><span class="text-base font-medium text-green-900 dark:text-white">{{ 'Company' | translate }}</span> <span class="text-red-500" *ngIf="!credentialForm.controls['noCompanyInfo'].value">*</span></label
            >
          </div>
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
              <span class="p-float-label relative">
              <input
              pInputText
                name="companyName"
                id="companyName"
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-100"
                placeholder=""
                formControlName="companyName"
                [disabled]="noCompanyInfo"
                [attr.disabled]="disableUserInfo ? '' : null"

              />
              <label
                for="companyName"
                class=""
                >{{ 'Company Name' | translate }}</label
              >
              </span>
              <div
              *ngIf="credentialForm.controls['companyName'].invalid && (credentialForm.controls['companyName'].dirty || credentialForm.controls['companyName'].touched)"
              class="text-red-600 col-span-10 text-xs mt-1"
              >
                <div *ngIf="credentialForm.controls['companyName'].errors?.['required']">
                  {{ 'Company name is required.' | translate }}
                </div>
              </div>
            </div>
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 mb-2">
              <select name="companyInd" formControlName="companyInd"
              class="leading-5 border border-gray-300 rounded-3xl focus:outline-none focus:ring-green-600 focus:border-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-100"
              [class]="credentialForm.value['companyInd'] == '' ? 'text-gray-500' : 'text-gray-900'"
              [disabled]="noCompanyInfo"
              [attr.disabled]="disableUserInfo ? '' : null">
                <option value="" disabled selected>{{ 'Select Industry...' | translate }}</option>
                <option value="Academic Institution">{{ 'Academic Institution' | translate }}</option>
                <option value="Consultant">{{ 'Consultant' | translate }}</option>
                <option value="Contractor">{{ 'Contractor' | translate }}</option>
                <option value="Freelance">{{ 'Freelance' | translate }}</option>
                <option value="Government">{{ 'Government' | translate }}</option>
                <option value="Manufacturer/Supplier/Startup">{{ 'Manufacturer/Supplier/Startup' | translate }}</option>
                <option value="Professional Body">{{ 'Professional Body' | translate }}</option>
                <option value="Project Client">{{ 'Project Client (Developer/Estate Officer/NGO)' | translate }}</option>
                <option value="Property Management Company">{{ 'Property Management Company' | translate }}</option>
                <option value="Research Body">{{ 'Research Body' | translate }}</option>
                <option value="Statutory Body">{{ 'Statutory Body' | translate }}</option>
                <option value="Trade Association">{{ 'Trade Association' | translate }}</option>
                <option value="Utility Organisation">{{ 'Utility Organisation' | translate }}</option>
                <option value="Others">{{ 'Others' | translate }}</option>
              </select>
              <div
              *ngIf="credentialForm.controls['companyInd'].invalid && (credentialForm.controls['companyInd'].dirty || credentialForm.controls['companyInd'].touched)"
              class="text-red-600 col-span-10 text-xs mt-1"
              >
                <div *ngIf="credentialForm.controls['companyInd'].errors?.['required']">
                  {{ 'Company industry is required.' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12">
          <div class="col-span-12 md:col-span-2">
          </div>
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0">
              <span class="p-float-label relative">
              <input
                pInputText
                name="jobTitle"
                id="jobTitle"
                class=" border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-green-600 focus:border-green-600 focus:outline-green-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-100"
                placeholder=""
                formControlName="jobTitle"
                [disabled]="noCompanyInfo"
                [attr.disabled]="disableUserInfo ? '' : null"
              />
              <label
                for="lastName"
                class=""
                >{{ 'Job Title' | translate }}</label
              >
              </span>
              <div
              *ngIf="credentialForm.controls['jobTitle'].invalid && (credentialForm.controls['jobTitle'].dirty || credentialForm.controls['jobTitle'].touched)"
              class="text-red-600 col-span-10 text-xs mt-1"
              >
                <div *ngIf="credentialForm.controls['jobTitle'].errors?.['required']">
                  {{ 'Job title is required.' | translate }}
                </div>
              </div>
            </div>
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0">
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12">
          <div class="col-span-12 md:col-span-2">
          </div>
          <div class="col-span-12 md:col-span-10 grid grid-cols-12 mt-2">
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0 cursor-pointer" (click)="onNoCompanyInfoChange()">
              <input type="checkbox" formControlName="noCompanyInfo" name="noCompanyInfo" (change)="onNoCompanyInfoChange($event)" class="rounded checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600"
              [attr.disabled]="disableUserInfo ? '' : null" />
              <span class="text-sm text-gray-600 ml-2">{{ 'Prefer not to provide now' | translate }}</span>
            </div>
            <div class="col-span-12 pb-2 md:col-span-6 md:pr-2 md:pb-0">
            </div>
          </div>
        </div>

        <hr class="my-4" />
        <div class="mt-6 flex flex-row justify-center md:justify-start">
          <div class="flex-grow"></div>
          <button
            type="submit"
            class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
            *ngIf="!isLoading && !disableUserInfo"
            (click)="mode =='register' ? onRegisterWithEmail() : onUpdateProfile()"
          >
            <ng-container *ngIf="mode == 'register'">
              {{ 'Next' | translate }}
            </ng-container>
            <ng-container *ngIf="mode == 'profile'">
              {{ 'Save' | translate }}
            </ng-container>
          </button>
          <button
            type="submit"
            disabled
            class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
            *ngIf="isLoading"
          >
          <flowbite-loading size="1.25rem"></flowbite-loading>
          </button>
        </div>
      </div>
        </div>
  <div
    *ngIf="isTandC"
    class="switch-page w-full max-w-3xl md:mt-0 xl:p-0 mr-auto"
    [class]="currentStep == 2 ? 'active' : currentStep > 2 ? 'past' : 'future'"
  >
  <div class="sm:px-4" [class]="pv ? '' : 'p-0 sm:p-0 sm:px-0'">
        <div class="col-span-12 md:col-span-10 grid grid-cols-12">
        <div class="col-span-12 md:col-span-6 md:pr-2 md:pb-0 flex flex-row space-x-2">
            <!-- <fa-icon class="text-green-600 cursor-pointer" [icon]="faBack" (click)="onRegisterStep2Back()"></fa-icon> -->
            <label
              for="email"
              class="block mb-2 font-medium text-gray-900 dark:text-white"
              ><span class="text-xl font-medium text-green-900 dark:text-white">{{ 'Terms and Conditions' | translate }}</span></label
            >
            </div>
            </div>

        <div class="text-gray-600">
          <div class="h-[calc(100vh-25rem)] overflow-y-scroll border p-2" (scroll)="onTandc1Scroll($event)">
            收集個人資料聲明<br />
            <br />
            1. 你向建造業議會或香港建造學院〔「議會或學院」〕所提供的資料，包括《個人資料〔私隱〕條例》所指的個人資料，只會用於相關議會或學院之活動。<br />
            2. 為讓你得知最新的議會或學院活動和行業內發展情況，議會或學院將使用你的個人資料，包括你的姓名、電話號碼、郵寄和電郵地址，將有關訓練課程、測試、註冊、活動項目、議會或學院工作和建造業其他方面的最新資訊提供給你。<br />
            3. 在未得你的事先同意前，議會或學院不會將你的個人資料轉移給任何第三方。<br />
            4. 你必須向議會或學院提供完整的資料。如你未能提供完整的資料，議會或學院或無法處理及/或考慮你的申請。你可選擇是否同意接收上述資訊。<br />
            5. 你有權要求查閱及修正你的個人資料。有關申請須以書面向議會或學院提出，地址為九龍觀塘駿業街56號中海日升中心38樓。<br />
          </div>
          <div
            *ngIf="isTandc1Error && !isTandc1Read"
            class="text-red-600 col-span-10 mt-2"
            >
            {{ 'Please read the Personal Information Agreement to the end.' | translate }}
          </div>
          <!-- <div class="mt-2">
            <input type="checkbox" name="tandc1" (change)="onTandc1Change($event)" class="rounded checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" /><span class="text-red-500 ml-1">*</span>
            <span class="text-xs text-gray-600 ml-2">{{ 'I have read and understand the Personal Information Agreement' | translate }}</span>
          </div>
          <div
            *ngIf="!isTandc1 && isSubmitted"
            class="text-red-600 col-span-10 text-xs mt-1"
            >
            {{ 'Please agree with the Personal Information Agreement.' | translate }}
          </div> -->
          <div class="mt-6 flex flex-row justify-center md:justify-start">
            <button
              class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
              *ngIf="!isLoading"
              (click)="onRegisterStep2Back()"
            >
                {{ 'Back' | translate }}
            </button>
            <div class="flex-grow"></div>
            <button
              class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
              *ngIf="!isLoading && !disableUserInfo"
              (click)="onRegisterStep2Confirm()"
            >
                {{ 'Agree' | translate }}
            </button>
            <button
              type="submit"
              disabled
              class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
              *ngIf="isLoading"
            >
            <flowbite-loading size="1.25rem"></flowbite-loading>
            </button>
          </div>
        </div>
      </div>
      </div>
      <div
        *ngIf="isTandC"
        class="switch-page w-full max-w-3xl md:mt-0 xl:p-0 mr-auto"
        [class]="currentStep == 3 ? 'active' : currentStep > 3 ? 'past' : 'future'"
      >
        <div class="sm:px-4" [class]="pv ? '' : 'p-0 sm:p-0 sm:px-0'">
          <div class="col-span-12 md:col-span-10 grid grid-cols-12">
            <div class="col-span-12 md:col-span-6 md:pr-2 md:pb-0 flex flex-row space-x-2">
                <!-- <fa-icon class="text-green-600 cursor-pointer" [icon]="faBack" (click)="onRegisterStep3Back()"></fa-icon> -->
                <label
                  for="email"
                  class="block mb-2 font-medium text-gray-900 dark:text-white"
                  ><span class="text-xl font-medium text-green-900 dark:text-white">{{ 'Terms and Conditions' | translate }}</span></label
                >
                </div>
                </div>
        <div class="text-gray-600">

          <div class="h-[calc(100vh-27rem)] overflow-y-scroll border p-2" (scroll)="onTandc2Scroll($event)">
            <ng-container *ngIf="translate.currentLang == 'en'">
              Full-time Short Course – Notice to Applicants<br />
              <br />
              1.	Application<br />
                Applicants must read the course information and admission requirements very carefully. <br />
                <br />
              2.	General Entry Requirements<br />
              2.1		Basic Entry Requirements:<br />
              	Aged 18 or above<br />
              	Applicants should be Hong Kong legal residents and permitted to work in 		Hong Kong.<br />
              	Ability to read and write in English<br />
                2.2	Applicants may required to receive a physical test / colour blind test before admission to assess whether they have the appropriate abilities to receive training, they must accept and pass the test.<br />
                <br />
              3.	Interview Process<br />
                3.1	During the admission interview, applicants will be asked about working experience, expectation from the course, personal physical condition and academic background. Applicants must answer correctly.<br />
                3.2	Applicants are required to present his/her Hong Kong Identity Card during the interview; record of work experience and academic qualification may also be required. If applicant cannot submit the required documents during the interview, he/she must submit it before the course commencement. Anyone fails to comply will be rejected from admission.<br />
              3.3	Applicants will be assessed according to the information provided in the application form.<br />
                Here-below is the criteria:<br />
              	Basic and preferred admission requirement<br />
              	Comparison between the applicants of the same course<br />
              	Interview performance and the test results, if applicable<br />
              	The desire for joining the construction industry<br />
              3.4  If the applicant already possessed Trade Test Certificates (either for skilled or semi-skilled workers), he/she should try his/her best to join the industry. We will not accept applicants who already acquired Trade Test Certificates for the relevant trade he/she applies. <br />
              3.5  If the applicant had joined or completed other training programmes in HKIC and received any allowance, his/her re-entry status will be restricted by the conditions listed in item 9.6. <br />
              <br />
              4.	Interview Result<br />
                4.1	HKIC will notify the applicants the interview result immediately at the completion of interview. When extra assessment is needed, applicants will be notified in written or by phone within 3 weeks after interview.<br />
                4.2	If the course is full and the applicants have to wait for a long time before admitting to a new course, applicants can consider other available courses and make his/her request known to the interviewer.<br />
                <br />
              5.		From Enrollment to Admission<br />
                5.1	Admitted applicants who wish to make any amendment or abandon his/her training place after receipt of the “Notice of Admission” shall notify HKIC at least one week prior to the course commencement date. For admitted applicants who absent on the first day of class without prior notification and explanation, HKIC reserves the right not to process his/her other application for a period of 3 months from the commencement date of the courses he/she absents from. <br />
                5.2	If the applicant continuously refused the admission arrangement for 2 times, his/her application will be cancelled. If he/she wants to apply other courses, he/she has to re-submit the application. On the other hand, for those who is not unemployed, his/her application also will be cancelled.<br />
                5.3	HKIC reserves the right to postpone/cancel a class if there is insufficient enrolment or even postpone/cancel a class if there is insufficient number of students shown-up on the first day training. On such occasion, if the admitted applicants wish to take another full-time short course, HKIC will give priority to his/her application.  <br />
                5.4	Admitted applicants who need to quit his/her job for attending the course may have to consider the consequences resulted thereafter in case the course is cancelled or postponed. HKIC will not bear any responsibility or handle any complaint arising from such occasion. <br />
                5.5	Applicants cannot be existing trainees/ students of any courses offered by the Construction Industry Council or the Hong Kong Institute of Construction.<br />
                <br />
              6.	Class Schedule:	Mondays to Fridays: From 8:20am to 5:00pm<br />
              <br />
              7.	Discipline at Campus	<br />
                7.1	On the first day of training, each student will be given a “Handbook for Students” which includes the regulations set out in the campus, say no smoking, no wearing of shorts and abide the safety regulations as prescribed by the campus, etc. In the course of training, a student will be provided training uniforms and the necessary tools and materials. <br />
                7.2	A student will be dismissed from training if his/her overall attendance rate falls below 95%, unable to follow the training curriculum, violate serious discipline or disobey the campus’ instructions which for example, refuse to receive training at other campus/training grounds, etc.<br />
                <br />
              8.	Release of Training Allowance<br />
                8.1	Students of Short Courses are eligible to training allowance on daily basis with the maximum of HK$4,800 per month. <br />
                  For the courses in the Enhanced Construction Manpower Training Scheme, students will receive an allowance of maximum HK$12,910 per month according to training duration and their attendance. (The allowance includes the basic allowance of maximum HK$10,000 per month and the retention allowance. The retention allowance will be provided when the student has successfully completed the programme and passed the practice test and written test).<br />
                8.2	Allowance will be deducted for any absence. <br />
                8.3	No allowance is to be granted for all designated holidays of HKIC, casual leave and sick leave; unless a special approval from the Principal of campus is obtained.<br />
                8.4	Late arrival for more than 15 minutes or early leave to training shall result in reduction of allowance.<br />
                8.5	If the course contains the retention allowance, students have to complete and pass all assigned tests in order to grant the allowance. Additional test opportunity could be offered during the training period. If students are not able to complete or pass the tests, retention allowance will not be distributed. (With effect from 1 May 2023, retention allowance will be distributed after 3 months when the graduates are confirmed to stay in the construction industry.)<br />
                8.6	All cases of dropping-out before completion of training, for whatever reasons, will be requested to reimburse all allowance received.<br />
                8.7	The monthly allowances will be distributed by auto-pay through local bank accounts in the middle of the subsequent month. Students must provide their local bank account information on or before the course commencement. HSBC account is recommended while transferring of allowance to other bank account involves a few days of delay. Accounts form virtual banks are not acceptable at the moment. If the applications do not own a Hong Kong bank account, please open one prior to the commencement of class and provide HKIC with the relevant information.<br />
                8.8	Full time Adult Short Course is specially designed for the unemployed, if applicant is fully employed, and his/her aim to take the course is to strengthen his/her related skill level, he/she will not entitle to receive training allowance, unless he/she can prove that his/her salary was temporary suspended during the training period.<br />
                <br />
              9.	The Arrangement of Placement / Re-application<br />
                9.1	Apart from obtaining a certificate after the completion of training, graduates will be given placement assistance if their overall attendance rate attains 95% AND pass either the trade test or examination as required by the course. <br />
                <br />
                9.2	Eligible graduates are required to respect HKIC’s placement offer and treasure every job opportunity. Nevertheless, graduates may also seek placement in the construction industry by themselves.<br />
                9.3	HKIC will assist students on employment, but whether or not the graduates could be hired also depending on the needs in job markets, graduates’ skill level, attitude, performance and expected remuneration. <br />
              9.4	HKIC will assist students for the application of construction workers registration before the completion of training. Nobody is allowed to work in the construction site without registration.<br />
              9.5	Student who fails in the Intermediate Trade Test should retake the test and obtain passing result in order to seek for employment support from Career Support Services. <br />
                9.6	Graduates who have completed the course and would like to apply other full times course, should be bound by the following:         <br />
              <table>
                <tr><td>Type of re-application</td><td>Re-application restriction</td></tr>
                <tr><td>Ordinary re-application</td><td>- Starting from the last graduation date, re-application will not be accepted within 1 year. <br />
              - Re-application after 1 year must provide at least 1 month proven employment record.<br />
              - Re-application to the same course graduated will not be accepted.</td></tr>
              <tr><td>Training terminated during the training period</td><td>	- Re-application will not be accepted for 2 years   starting from the termination date. Applicants must return all received allowance from last course prior to admit to class.</td></tr>
                <tr><td>Withdrawal from the course during the training period</td><td>- Re-application will not be accepted for 1 year starting from the withdrawal date. Applicants must return all received allowance from last course prior to admit to class.</td></tr>
              <tr><td>Failed in the Intermediate Trade Test</td><td>	- Starting from the last graduation date, re-application will not be accepted within 1 year if applicants cannot provide at least 1 month proven employment record.<br />
              - Re-application to the same course will not be accepted.</td></tr>
              <tr><td>Failed to follow our placement instruction, e.g. refused to attend the job interview or employment</td><td>	-	If absent, refused to attend job interview /  employment twice after last graduation, or cannot provide at least 1 month proven employment record, re-application will not be accepted within 15 months.<br />
              - Re-application to the same course will not be accepted.</td></tr>
              <tr><td>Participated in any Construction Tradesman Collaborative Training Scheme (CTS)</td><td>	- Starting from the last graduation / completion / withdrawal date, re-application will not be accepted within 1 year.<br />
              - Re-application to the same course will not be accepted.</td></tr>
              <tr><td>Participated in any Advanced Construction Manpower Training Scheme</td><td> - Pilot Scheme (Skills Enhancement Courses) 	- Re-application to the same course will not be accepted.</td></tr>
            </table><br />
              9.6.1	Proven employment record should include the employer’s particulars and				employment period. 	<br />
              9.6.2	Written application for exemption for the above mentioned rules is						required.<br />
              <br />
              10.	 Application Given Priority<br />
              Priority for admission will be granted to the applicants who can provide valid support to their declared work experience in the construction industry, or recommendation made by 	employers of the construction industry under which job opportunities is guaranteed. The placement assistance of HKIC is therefore not required by them. <br />
              <br />
              11.	 Others<br />
                11.1 The HKIC has taken necessary measures to ensure the validity of this document during production. Students Handbook will be distributed on the first day of class. Should there be any difference between this notice and the handbook, the Principal of campus would make all necessary determination. <br />
                11.2 The HKIC may at its absolute discretion without notice at any time and under any circumstances withdraw or modify the provisions of any goods or services. <br />
                11.3 With effect from 1 January 2021, no monthly training allowance will be provided to students of all full-time short courses during class suspension caused by Novel Coronavirus, other epidemic outbreaks or any incident which causes a serious impact on the livelihood of the community.<br />
                11.4 For new classes commencing from September 2021 in the academic year 2021/22, 1-day sports training will be included in the full-time short programmes with training days of 75 days or above to fulfil the graduation requirement.<br />
                11.5 For inquiries before course commencement, please call HKIC Hotline 2100 9000.<br />
                <br />
              Issued by Student Recruitment - Hong Kong Institute of Construction<br />
              1 April 2023              <br />
            </ng-container>
            <ng-container *ngIf="translate.currentLang != 'en'">
              申請資格<br /><br />
              1.1	申請人必須於開學日或以前年滿18歲、可合法居留及受僱的香港居民。除個別以英語授課的課程外，申請人需具有閱讀及書寫中文能力，並能以廣東話溝通。<br />
              1.2	部份課程設有色覺／色弱／體能測試，旨在評估申請人是否具備合適能⼒以接受該課程的培訓。申請人須接受並通過該課程的測試。<br />
              ２.	課程選擇<br />
                申請人必須先閱讀香港建造學院（下稱本學院）的課程章程，認識課程的入學條件及學習內容，然後揀選課程的第一及第二志願。<br />
              ３.	面試及測試<br />
              ３.1		本學院職員將於面試時了解申請人的工作經驗、報讀課程的目的、健康狀況及學歷等，申請人必須忠實地回答。<br />
              ３.2	申請人必須出示身份證明文件以證明可於香港合法居留及受僱。因應個別課程的入學要求，申請人會被要求出示學歷、工作紀錄或相關牌照。申請人如未能於面試時提供相關文件，必須於開課前補交，否則會被拒絕入學。<br />
              ３.3		本學院會根據申請人在申請表內所提		供的資料及會面時的表現予以評核。		以下為評核過程中的考慮因素：<br />
              	基本及優先入學條件<br />
              	同一課程的申請人的互相比較<br />
              	面試表現或個別課程的測試結果<br />
              	加入建造業的意欲<br />
              報名及面試時間並非絕對取錄準則。<br />
              ３.4	如申請人已經擁有某工種的技能測試證書（無論是技能測試（大工）或中級工藝測試（中工）），應該盡力加入該行業工作。本學院不會接受申請人報讀與所持有的技能測試證書相同工種的課程（例如：一名持有鋼筋屈紮技能測試證書的申請人，不能再報讀鋼筋屈紮證書）。<br />
              ３.5	申請人若曾就讀本學院課程或本學院資助予其他機構舉辦的全日制課程並領取津貼，將被視為「完成」或「曾入讀」本學院課程，其申請須按9.6項所列之限制處理。<br />
              3.6	凡報讀全日制短期課程的人士須於面試時穿著或帶備運動服及運動鞋以便部份課程進行體能測試。<br />
              ４.	面試結果<br />
              ４.1	一般情況下，本學院職員會即時通知申請人面試及測試結果。如申請人需要補交文件或進行額外評估，則約三星期才獲得通知。<br />
              ４.2	如報讀之課程已額滿或需輪候一段較長時間，申請人可向本學院職員要求轉報其他課程。<br />
              ５.	取錄及入學<br />
              ５.1	如申請人獲本學院取錄，將會收到通知。如申請人欲對通知書上的資料作出更改或放棄入學，必須於開課前一星期按通知書上的指示通知有關職員。被取錄者如在沒有預先通知及解釋的情況下沒有入學，本學院會由其不入學之課程開課日起計算，三個月內拒絕處理其申請。<br />
              ５.2	如被取錄者持續拒絕入讀本學院所編排的課程兩次，其申請將被取消。如欲再次報讀本課院任何課程，須重新辦理報名手續並接受面試及/或測試。<br />
              ５.3	如課程滿額或人數不足，本學院有權安排被取錄者延期入學或安排該班延期開課，被取錄者不得異議。惟被取錄者可以申請轉讀另一全日制短期課程。<br />
              ５.4	如被取錄者需辭去工作才可入讀本學院課程，須考慮課程一旦延期開班或取消的影響；任何有關的投訴，本學院一概不受理。<br />
              ５.5	申請人不能在同一時間修讀建造業議會／香港建造學院任何提供培訓津貼／獎金／資助發放的課程。<br />
              ６.  	上課時間<br />
              星期一至五 上午8:20至下午5:00<br />
                (以下課程除外)<br />
                起重機操作班<br />
                星期一至五 上午8:00至下午5:48<br />
                負荷物移動機操作班<br />
                星期一至六 上午8:20至下午4:20<br />
              ７.	學院秩序<br />
              ７.1	學生將於開課時收到「學生手冊」、制服及相關工具。學生需仔細閱讀學院規條並嚴格遵守（例如不可吸煙、不可穿著短褲和各項安全規例等）。<br />
              ７.2	學生如出現下列情況可被飭令退學：<br />
              	全學期出席率低於95% (部分指定科目出席率必須為100%)；<br />
              	不能跟上學習進度；<br />
              	違反嚴重紀律 ;<br />
              	經證實嚴重違反本學院學則 ;<br />
              	拒絕聽從本學院指示（例如不接受到其他訓練場地實習）。<br />
              <br />
              ８.	訓練津貼<br />
              ８.1	訓練津貼詳細見附件A。<br />
              ８.2	如學生缺席課堂將會被扣除相應的訓練津貼。<br />
              ８.3	除獲得校長特別批准外，學生缺課及學院假期均不會獲發津貼。<br />
              ８.4	如學生遲到15分鐘以上或缺席半天	訓練，當扣除半天津貼。<br />
              ８.5	如課程訓練津貼包含留職鼓勵津貼，學生必須於院校安排的中級工藝測試或考試取得及格成績並確認在畢業後3個月仍留在建造業方發放留職鼓勵津貼。學生於訓練期內可獲一次免費額外參加中級工藝測試或考試的機會。<br />
              ８.6	在任何情況下，學生自動退學或被飭令退學，已領取之津貼、制服及工具需要全部退還給本學院。<br />
              ８.7 	每月津貼由銀行在每月上課期後的下一個月中前自動轉帳給學生。學生必須提供其香港的銀行儲蓄戶口（暫不接受虛擬銀行戶口）予本學院以辦理自動轉帳手續。若選用匯豐銀行以外的銀行，過數日期可能會延遲數天。如申請人未有香港的銀行儲蓄戶口，請於入學前到銀行開戶，並通知學院有關戶口資料。<br />
              ８.8	所有全日制課程為轉業或待業人士而設，若學生在受訓期間全職受僱，將不獲發訓練津貼。惟能證明在受訓期間停薪者除外。<br />
              8.9	若學生於入學前已具備與工種相關的安全訓練資歷（例如﹕密閉空間核准工人安全訓練課程等），學院會根據監管機構發出的指引作相應安排，例如在續證期內安排學生報讀重溫課程。如學生持有有效資歷則毋須重新修讀該課程，當天不用回校上課，亦不作缺席論，同時不會獲發當天津貼。 (建造業安全基礎證書除外，該證書課程為必須修讀內容)<br />
              ９.	就業/重讀安排<br />
              ９.1	如學生能按指定要求完成課程、通過測試及/或考試及出席率達95%會獲畢業證書及就業協助。<br />
              ９.2	本學院提供事業教育及輔導跟進服務，評估及協助畢業生在業內長遠發展。畢業生必須遵守學院之安排，如未能符合要求之畢業生，有機會被終止輔導及就業轉介服務。<br />
              ９.3	本學院會協助畢業生找尋合適聘用條件的工作。惟畢業生能否成功受聘及其所獲得的聘用條件主要視乎巿場需求、學生的學習表現及受聘機構的情況而定。畢業生亦可自行在建造業內尋找工作。<br />
              ９.4	本學院將會協助畢業生辦理建造業工人註冊手續。所有未經註冊的人士不得在建造工地工作。<br />
              ９.5	中工測試不及格的學生，應盡快申請重考，取得及格成績後可向本學院學生就業輔導服務尋求就業協助。<br />
              ９.6	如申請人已完成或曾入讀本學院全日制課程，而有意再重新報讀另一個全日制課程，需受附件B的限制。本學院一般不會考慮受訓時表現欠佳、缺乏入行及工作意願的申請人再次申請其他全日制課程。<br />
              10.	其他<br />
              10.1		訓練期達75天或以上的全日制短期培訓課程將包含1天的「運動訓練項目」。此為畢業要求。<br />
              10.2		本學院學生招募部在製作此須知時，已力求內容正確無誤。惟內容或會因應情況而更改。如有任何差異，將以「學生手冊」及院校校長/監督宣佈作準。<br />
              10.3		香港建造學院可使用其絕對酌情決定權接受或不接受任何報名，亦毋須就此提供任何原因。<br />
              10.4		若有查詢，請依照入學通知書的指示聯絡有關職員或致電課程熱線 2100 9000。<br />
              <br />
              <br />
              <br />
              香港建造學院-學生招募部 編製                      <br />
              2023年4月20日<br />
            </ng-container>
          </div>
          <div
          *ngIf="isTandc2Error && !isTandc2Read"
          class="text-red-600 col-span-10 mt-2"
          >
            {{ 'Please read the Notice to Applicants to the end.' | translate }}
          </div>
          <!-- <div class="mt-2">
            <input type="checkbox" name="tandc2" (change)="onTandc2Change($event)" class="rounded checked:bg-green-600 checked:hover:bg-green-600 checked:active:bg-green-600 checked:focus:bg-green-600 focus:outline-none focus:ring-green-600" /><span class="text-red-500 ml-1">*</span>
            <span class="text-gray-600 ml-2">{{ 'I have read and understand the Notice to Applicants' | translate }}</span>
          </div>
          <div
          *ngIf="!isTandc2 && isSubmitted"
          class="text-red-600 col-span-10 mt-2"
          >
            {{ 'Please agree with the Notice to Applicants.' | translate }}
          </div> -->
          <div class="mt-6 flex flex-row justify-center md:justify-start">
            <button
              class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
              *ngIf="!isLoading"
              (click)="onRegisterStep3Back()"
            >
                {{ 'Back' | translate }}
            </button>
            <div class="flex-grow"></div>
            <button
              class="flex flex-row justify-center w-40 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 items-center"
              *ngIf="!isLoading && !disableUserInfo"
              (click)="onRegisterStep3Confirm()"
            >
                {{ 'Agree and Register' | translate }}
            </button>
            <button
              type="submit"
              disabled
              class="flex flex-row justify-center w-40 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
              *ngIf="isLoading"
            >
            <flowbite-loading size="1.25rem"></flowbite-loading>
            </button>
          </div>
        </div>
        </div>

          <div class="flex items-center justify-between">
            <!-- <div class="flex items-start">
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                {{ 'Already registered?' | translate }}
                <a
                  (click)="onLogin($event)"
                  class="font-medium text-notus-primary-600 hover:underline dark:text-primary-500"
                  >{{ 'Login' | translate }}</a
                >
              </p>
            </div> -->
            <!-- <a
              href="#"
              class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >{{ 'Forgot password?' | translate }}</a
            > -->
          </div>
          </div>
        </div>
      </div>
    </div>
