import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { phoneNumberWrapping } from '../../functions/utils';
import { EmailEnrollResponse, FactorInfoChange, SmsEnrollResponse } from '../../app.actions';

@Component({
    selector: 'ciciam-email-change-popup',
    templateUrl: './email-change-popup.component.html',
    styleUrls: ['./email-change-popup.component.scss']
})
export class EmailChangePopupComponent {
  showModal = false;

  courseId: string = '';
  courseName: string = '';
  coursePrice: number = 0;
  onSuccess?: () => Promise<any> = undefined;
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;
  isLoading: boolean = false;

  emailForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private supportService: SupportService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.appState$.subscribe((state) => {
      this.userId = state.userId;
    });
  }

  requestPrompt(onSuccess?: () => Promise<any>) {
    this.onSuccess = onSuccess;
    this.showModal = true;
    this.emailForm.reset();
  }

  onResult = async ($event: any) => {
    this.toastr.success(this.translate.instant('Email Authentication updated successfully'), this.translate.instant('Success'));
    this.toggleModal();
    this.onSuccess?.();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  useStripe() {
    this.toggleModal();
  }

  onCancel($event: any) {
    $event.preventDefault();
    this.toggleModal();
  }

  async onEmailFormSubmit() {
    this.emailForm.markAllAsTouched();
    if(this.emailForm.valid) {
      this.isLoading = true;
      try {
        let result = await this.supportService.enable2FaEmail(this.userId!, false, this.translate.currentLang, this.emailForm.value.email);
        if(!result || result.success == false) {
          this.emailForm.controls['email'].setErrors({ 'emailused': 'emailused' });
          this.isLoading = false;
        } else {
          this.toastr.success(this.translate.instant('Email address updated successfully'), this.translate.instant('Success'));
          this.store.dispatch(new EmailEnrollResponse(result));
          this.store.dispatch(new FactorInfoChange(this.emailForm.value.email));
          this.isLoading = false;
          this.toggleModal();
          this.onSuccess?.();
        }
      } catch(e) {
        this.toastr.error(this.translate.instant('Failed to update email address'), this.translate.instant('Error'));
        this.isLoading = false;
      }
    }
  }
}
