import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { faCheck, faCircle, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'ciciam-change-password-popup',
    templateUrl: './change-password-popup.component.html',
    styleUrls: ['./change-password-popup.component.scss']
})
export class ChangePasswordPopupComponent {
  showModal = false;

  courseId: string = '';
  courseName: string = '';
  coursePrice: number = 0;
  onSuccess?: () => Promise<any> = undefined;
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;

  isPasswordHasLowercase: boolean = false;
  isPasswordHasUppercase: boolean = false;
  isPasswordHasNumber: boolean = false;
  isPasswordLength8: boolean = false;

  faCheck = faCheck;
  faTimes = faTimes;
  faCircle = faCircle;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  isCurrentPasswordVisible = false;
  isNewPasswordVisible = false;
  isConfirmPasswordVisible = false;

  form: FormGroup = this.fb.group({
    current_password: ['', Validators.required],
    new_password: ['', Validators.required],
    confirm_password: ['', Validators.required],
  });

  isLoading = false;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private supportService: SupportService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.appState$.subscribe((state) => {
      this.userId = state.userId;
    });
  }

  requestPrompt(/*courseId: string, courseName: string, coursePrice: number, onSuccess?: () => Promise<any>*/) {
    // this.courseId = courseId;
    // this.courseName = courseName;
    // this.coursePrice = coursePrice;
    // this.onSuccess = onSuccess;
    this.showModal = true;
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  useStripe() {
    this.toggleModal();
  }

  checkPassword($event: any) {
    let password = this.form.controls['new_password'].value;

    this.isPasswordHasLowercase = /[a-z]/.test(password);
    this.isPasswordHasUppercase = /[A-Z]/.test(password);
    this.isPasswordHasNumber = /[0-9]/.test(password);
    this.isPasswordLength8 = password.length >= 8;
  }

  toggleCurrentPassword() { this.isCurrentPasswordVisible = !this.isCurrentPasswordVisible; }
  toggleNewPassword() { this.isNewPasswordVisible = !this.isNewPasswordVisible; }
  toggleConfirmPassword() { this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible; }

  async onSubmit() {
    if(this.isLoading) return;
    console.log(this.form.value);
    if(this.form.valid) {
      if(this.form.value['new_password'] != this.form.value['confirm_password']) {
        this.toastr.error(this.translateService.instant('New password and repeat password do not match'), this.translateService.instant('Error'));
        return;
      }

      this.isLoading = true;

      let result = await this.supportService.changePassword(this.userId!, this.form.value['current_password'], this.form.value['new_password']);

      this.isLoading = false;

      if(result.success == true) {
        // TODO: is this consider as success?
        this.toastr.success(this.translateService.instant('Password changed successfully'), this.translateService.instant('Success'));
        this.toggleModal();
        this.form.reset();
      } else {
        if(result.errorCode == "E0000014") {
          this.toastr.error(this.translateService.instant('Your password seems to be the same as last 4 password you used, or contains part of your email / phone number, please modify and try again'), this.translateService.instant('Error'));
        } else {
          this.toastr.error(this.translateService.instant('Action failed, please try again ({{errorcode}})', { errorcode: result.errorCode }), this.translateService.instant('Error'));
        }
      }
    }
  }

  onCancel($event: any) {
    $event.preventDefault();
    this.toggleModal();
  }
}
