import { Injectable, NgModule } from "@angular/core";
import { Action, Actions, NgxsModule, State, StateContext, StateToken } from "@ngxs/store";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { Authenticate, ChangeResetPasswordCallback, ClearEnrollResponses, ClearEverything, ClearLoginCredentials, ClearOAuthSigninRequest, EmailEnrollResponse, FactorInfoChange, FactorInfoChangeConfirm, Logout, OauthSigninRequest, SetLanguage, SetLoginApp, SetOAuthSigninRequest, SetOffFlow, SetOidcError, SetUserId, SetVerificationToken, SmsEnrollResponse, StoreLoginCredentials, StoreSessionToken } from "./app.actions";

export const STATE_TOKEN = new StateToken<StateModel>('cicciam');

export interface StateModel {
  language: string,

    username?: string,
    password?: string,
    smsEnrollResponse?: any,
    emailEnrollResponse?: any,

    userId?: string,
    isLoggedIn: boolean,

    sessionToken?: string,
    oauthSigninRequest?: OauthSigninRequest,

    loginApp?: string,

    errorCode?: string,

    verificationToken?: string,

    newFactorInfo?: string,

    resetPasswordCallback?: string
}

export const initialState: StateModel = {
  language: 'zh-Hant',
  isLoggedIn: false
}

@State<StateModel>(
  {
      name: STATE_TOKEN,
      defaults: initialState,
  }
)
@Injectable({ providedIn: 'root' })
export class AppState {

  constructor(private actions$: Actions) { }

  @Action(ClearEverything, { cancelUncompleted: true })
  clearEverything(ctx: StateContext<StateModel>, action: ClearEverything) {
    ctx.setState({
      ...initialState,
      language: ctx.getState().language,
      errorCode: ctx.getState().errorCode
    });
  }

  @Action(SetLanguage, { cancelUncompleted: true })
  setLanguage(ctx: StateContext<StateModel>, action: SetLanguage) {
    ctx.setState({
      ...ctx.getState(),
      language: action.language
    });
  }

  @Action(StoreLoginCredentials, { cancelUncompleted: true })
  storeLoginCredentials(ctx: StateContext<StateModel>, action: StoreLoginCredentials) {
    ctx.setState({
      ...ctx.getState(),
      ...action
    });
  }

  @Action(ClearLoginCredentials, { cancelUncompleted: true })
  clearLoginCredentials(ctx: StateContext<StateModel>, action: ClearLoginCredentials) {
    ctx.setState({
      ...ctx.getState(),
      username: undefined,
      password: undefined
    });
  }

  @Action(SetOidcError, { cancelUncompleted: true })
  setOidcError(ctx: StateContext<StateModel>, action: SetOidcError) {
    ctx.setState({
      ...ctx.getState(),
      errorCode: action.errorCode
    });
  }

  @Action(ClearEnrollResponses, { cancelUncompleted: true })
  clearEnrollResponse(ctx: StateContext<StateModel>, action: ClearEnrollResponses) {
    ctx.setState({
      ...ctx.getState(),
      emailEnrollResponse: undefined,
      smsEnrollResponse: undefined
    });
  }

  @Action(SmsEnrollResponse, { cancelUncompleted: true })
  smsEnrollResponse(ctx: StateContext<StateModel>, action: SmsEnrollResponse) {
    ctx.setState({
      ...ctx.getState(),
      ...action
    });
  }

  @Action(EmailEnrollResponse, { cancelUncompleted: true })
  emailEnrollResponse(ctx: StateContext<StateModel>, action: EmailEnrollResponse) {
    ctx.setState({
      ...ctx.getState(),
      ...action
    });
  }

  @Action(StoreSessionToken, { cancelUncompleted: true })
  storeSessionToken(ctx: StateContext<StateModel>, action: StoreSessionToken) {
    ctx.setState({
      ...ctx.getState(),
      sessionToken: action.sessionToken
    });
  }

  @Action(SetOAuthSigninRequest, { cancelUncompleted: true })
  setOAuthSigninRequest(ctx: StateContext<StateModel>, action: SetOAuthSigninRequest) {
    console.log('XDD');
    ctx.setState({
      ...ctx.getState(),
      oauthSigninRequest: action.oauthSigninRequest
    });
  }

  @Action(ClearOAuthSigninRequest, { cancelUncompleted: true })
  clearOAuthSigninRequest(ctx: StateContext<StateModel>, action: ClearOAuthSigninRequest) {
    ctx.setState({
      ...ctx.getState(),
      oauthSigninRequest: undefined
    });
  }

  @Action(SetUserId, { cancelUncompleted: true })
  setUserId(ctx: StateContext<StateModel>, action: SetUserId) {
    ctx.setState({
      ...ctx.getState(),
      userId: action.userId
    });
  }

  @Action(SetLoginApp, { cancelUncompleted: true })
  setLoginApp(ctx: StateContext<StateModel>, action: SetLoginApp) {
    ctx.setState({
      ...ctx.getState(),
      loginApp: action.loginApp
    });
  }

  @Action(SetOffFlow, { cancelUncompleted: true })
  setOffFlow(ctx: StateContext<StateModel>, action: SetOffFlow) {
    let s = ctx.getState().oauthSigninRequest;
    if(s == undefined) return;
    ctx.setState({
      ...ctx.getState(),
      oauthSigninRequest: {
        ...s,
        isOffFlow: action.offFlow
      }
    });
  }

  @Action(SetVerificationToken, { cancelUncompleted: true })
  setVerificationCallback(ctx: StateContext<StateModel>, action: SetVerificationToken) {
    ctx.setState({
      ...ctx.getState(),
      verificationToken: action.token
    });
  }

  @Action(Authenticate, { cancelUncompleted: true })
  authenticate(ctx: StateContext<StateModel>, action: Authenticate) {
      const state = ctx.getState();
      if (state.userId != action.userId)
          ctx.setState({
            ...ctx.getState(),
            userId: action.userId,
            isLoggedIn: true});
  }

  @Action(Logout, { cancelUncompleted: true })
  logout(ctx: StateContext<StateModel>) {
      ctx.setState({...initialState, language: ctx.getState().language});
  }

  @Action(FactorInfoChange, { cancelUncompleted: true })
  factorInfoChange(ctx: StateContext<StateModel>, action: FactorInfoChange) {
    ctx.setState({
      ...ctx.getState(),
      newFactorInfo: action.newFactorInfo
    });
  }

  @Action(FactorInfoChangeConfirm, { cancelUncompleted: true })
  factorInfoChangeConfirm(ctx: StateContext<StateModel>, action: FactorInfoChangeConfirm) {
    let newFactorInfo = ctx.getState().newFactorInfo;
    if(newFactorInfo != undefined) {
      if(newFactorInfo!.includes('@') && ctx.getState().username?.includes('@cicciam.hk') == false) {
        ctx.setState({
          ...ctx.getState(),
          username: newFactorInfo
        });
      } else if(newFactorInfo!.includes('@') == false && ctx.getState().username?.includes('@cicciam.hk') == true) {
        ctx.setState({
          ...ctx.getState(),
          username: newFactorInfo + '@cicciam.hk'
        });
      }
      ctx.setState({
        ...ctx.getState(),
        newFactorInfo: undefined
      });
    }
  }

  @Action(ChangeResetPasswordCallback, { cancelUncompleted: true })
  changeResetPasswordCallback(ctx: StateContext<StateModel>, action: ChangeResetPasswordCallback) {
    ctx.setState({
      ...ctx.getState(),
      resetPasswordCallback: action.callback
    });
  }
}
