import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { DropdownItem } from '../../ui-tailwind.types';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'tw-dropdown',
  templateUrl: './dropdown.component.html',
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class DropdownComponent {

  @Input() text: string | null = null;
  @Input() items: DropdownItem[][] | undefined = [];
  @Input() isRightAlign: boolean = true;
  @Input() dropdownPosition: 'top' | 'bottom' = 'bottom';

  faAngleDown = faAngleDown;

  isDropdownShow = false;

  toggleDropdown() {
    this.isDropdownShow = !this.isDropdownShow;
  }

  closeDropdown() {
    this.isDropdownShow = false;
  }
}
