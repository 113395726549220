import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens, AuthApiError } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { ChangeResetPasswordCallback, ClearEnrollResponses, ClearEverything, EmailEnrollResponse, SetOAuthSigninRequest, SetUserId, SmsEnrollResponse, StoreLoginCredentials, StoreSessionToken } from '../../app.actions';
import { Authenticate } from 'libs/commons/src/lib/guards/auth-guard.actions';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { Observable, firstValueFrom } from 'rxjs';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Choose2faPopupComponent } from '../../popup/choose-2fa-popup/choose-2fa-popup.component';
import { FormLoginComponent } from '../../widgets/form-login/form-login.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormForgotPasswordComponent } from '../../widgets/form-forgot-password/form-forgot-password.component';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'digitsolu-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {

  user: any;
  credential = new EventEmitter<{ email: string, password: string, usernameTypes: string[] }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;
  showPwModal: boolean = false;

  callback: string = '';

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  @ViewChild('forgotPasswordForm') forgotPasswordForm?: FormForgotPasswordComponent;
  @ViewChild('choose2fa') choose2fa!: Choose2faPopupComponent;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private translateService: TranslateService, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, private toastr: ToastrService, private translate: TranslateService) {
    this.route.queryParams.subscribe(params => {
      let callback = params['callback'];
      this.callback = callback;
      this.store.dispatch(new ChangeResetPasswordCallback(callback));
    });
  }

  async ngOnInit() {
    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
    // this.translateService.setDefaultLang('zh-Hant');
    this.translateService.addLangs(['en', 'zh-Hant']);

    this.appState$.subscribe(async (state) => {
      if(state.language) {
        this.translateService.use(state.language);
      }
    });
  }

  onForgotPassword = async (payload: { email: string, usernameTypes: string[] }) => {
    // this.credential.emit(payload);

    try {
      var user = await this.supportService.lookupUser(payload.email, payload.usernameTypes);

      if(user === false) {
        // user not found
        this.toastr.error(this.translateService.instant('User not found'));
        this.forgotPasswordForm!.isLoading = false;
      } else {
        if(user.length > 0) user = user[0];

        let mfaData = await this.supportService.get2faStatus(user!.id);
        let sms2faData = mfaData.find((item: any) => item != null && (item.status == 'ACTIVE' || item.status == 'PENDING_ACTIVATION') && item.factorType === 'sms');
        let email2faData = mfaData.find((item: any) => item != null && (item.status == 'ACTIVE' || item.status == 'PENDING_ACTIVATION') && item.factorType === 'email' && !item.profile.email.endsWith('@cicciam.hk'));

        this.store.dispatch(new SetUserId(user!.id));
        this.user = user;

        if(sms2faData && email2faData) {
          // both factors available, need popup and ask
          this.choose2fa.requestPrompt(sms2faData, email2faData);
        } else if(sms2faData) {
          await this.proceedWithSms2fa();
        } else if(email2faData) {
          await this.proceedWithEmail2fa();
        } else {

        }
      }
    } catch(e: any) {
      console.log(e);

      // this.loginForm!.isLoading = false;
      // if(e as AuthApiError) {
      //   if(e.errorCauses.length > 0) {
      //     this.toastr.error(e.errorCauses[0].errorSummary);
      //   } else {
      //     this.toastr.error(e.errorSummary);
      //   }
      // }
    }
  }

  onForgotPasswordRedirect = async () => {

  }

  onSigninException = async (e: any) => {
    this.forgotPasswordForm!.isLoading = false;
    if(e as AuthApiError) {
      if(e.errorCauses.length > 0) {
        this.toastr.error(e.errorCauses[0].errorSummary);
      } else {
        this.toastr.error(e.errorSummary);
      }
    }
  }

  async proceedWithSms2fa() {
    let result = await this.supportService.enable2FaSms(this.user.id, true, this.translate.currentLang);
    this.store.dispatch(new ClearEnrollResponses());
    this.store.dispatch(new SmsEnrollResponse(result));
    this.router.navigate(['/forgot-password-validate']);
    // this.oktaAuth.session.setCookieAndRedirect((await firstValueFrom(this.appState$)).sessionToken, window.location.protocol + "//" + window.location.host + '/login-validate'); // Sets a cookie on redirect
  }

  async proceedWithEmail2fa() {
    let result = await this.supportService.enable2FaEmail(this.user.id, true, this.translate.currentLang);
    this.store.dispatch(new ClearEnrollResponses());
    this.store.dispatch(new EmailEnrollResponse(result));
    this.router.navigate(['/forgot-password-validate']);
    // this.oktaAuth.session.setCookieAndRedirect((await firstValueFrom(this.appState$)).sessionToken, window.location.protocol + "//" + window.location.host + '/login-validate'); // Sets a cookie on redirect
  }

  onRegisterClick() {

    // this.choose2fa.requestPrompt(undefined, undefined);
    // this.router.navigate(['register']);
    this.showModal = true;
  }

  onForgotPasswordClick() {
    this.showPwModal = true;
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  togglePwModal() {
    this.showPwModal = !this.showPwModal;
  }

  onBackToLoginClick = () => {
    if(this.callback) {
      this.store.dispatch(new ClearEverything());
      window.location.href = this.callback;
    } else {
      this.router.navigate(['/oauth2/v1/authorize']);
    }
  }

  onCancel2fa = async () => {
    if(this.forgotPasswordForm) this.forgotPasswordForm.isLoading = false;
  }
}
