
<ciciam-frame-front>
  <ciciam-form-login #loginForm
    (credential)="onSignIn($event)"
    (register)="onRegisterClick()"
    (forgotPassword)="onForgotPasswordClick()"
  ></ciciam-form-login>
</ciciam-frame-front>

<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex max-h-screen hide-scroll-bar">
  <div class="relative md:w-[80vw] w-[90vw] min-w-[300px] my-6 mx-auto max-w-3xl max-h-screen">
    <!--content-->
    <div class="border-2 border-notus-primary-500 rounded-2xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none h-[90vh]">
      <!--header-->
      <div class="flex items-start justify-between p-5 rounded-2xl">
        <h3 class="text-2xl block flex-grow text-center md:text-left lg:inline-block text-notus-primary-900">
          {{ 'New User Registration' | translate }}
        </h3>
        <button class="absolute top-0 right-0 p-5 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none" (click)="toggleModal()">
          <span class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
            ×
          </span>
        </button>
      </div>
      <!--body-->
      <div class="relative p-4 flex-auto overflow-x-hidden overflow-y-clip">
        <ciciam-form-register
          (credential)="onRegister($event)"
        ></ciciam-form-register>
      </div>
      <!--footer-->
      <!-- <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          Close
        </button>
        <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          Save Changes
        </button>
      </div> -->
    </div>
    <div>&nbsp;</div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>

<ciciam-choose-2fa-popup #choose2fa
  (smsVerify)="proceedWithSms2fa()"
  (emailVerify)="proceedWithEmail2fa()"
  (cancel)="onCancel2fa()"
></ciciam-choose-2fa-popup>


<div *ngIf="showExpireModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex max-h-screen hide-scroll-bar">
  <div class="relative md:w-[80vw] w-[90vw] min-w-[300px] my-6 mx-auto max-w-3xl max-h-screen">
    <!--content-->
    <div class="border-2 border-notus-primary-500 rounded-2xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 rounded-2xl">
        <h3 class="text-2xl block flex-grow text-center md:text-left lg:inline-block text-notus-primary-900">
          {{ 'Login Expired' | translate }}
        </h3>
      </div>
      <!--body-->
      <div class="relative p-4 flex-auto overflow-y-scroll overflow-x-hidden hide-scroll-bar">
        {{ 'Sorry your login session is expired, please back to the app you came from and restart the login process' | translate }}
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        <button class="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="goBack()">
          {{ 'Back' | translate }}
        </button>
      </div>
    </div>
    <div>&nbsp;</div>
  </div>
</div>
<div *ngIf="showExpireModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>
