<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-6xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--body-->
      <div class="relative p-6 flex-auto">
         <ciam-sms-code-widget (result)="onResult($event)" [isRight]="false"></ciam-sms-code-widget>

         <div class="flex flex-row-reverse">
           <button
             type="submit"
             class="flex flex-row justify-center w-32 text-notus-primary-900 bg-transparent hover:text-notus-primary-900 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
             (click)="onCancel($event)"
           >
             {{ 'Cancel' | translate }}
           </button>
         </div>
        </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>
