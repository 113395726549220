import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { firstValueFrom, Observable } from 'rxjs';
import { ClearEverything, ClearLoginCredentials, ClearOAuthSigninRequest, SmsEnrollResponse } from '../../app.actions';
import { StateModel, STATE_TOKEN } from '../../app.states';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormResetPasswordComponent } from '../../widgets/form-reset-password/form-reset-password.component';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'ciam-forgot-password-submit',
  templateUrl: './forgot-password-submit.component.html',
  styleUrls: ['./forgot-password-submit.component.scss'],
})
export class ForgotPasswordSubmitComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  showModal: boolean = false;

  uid: string = '';
  code: string = '';
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  username?: string = '';
  password?: string = '';

  isSms: boolean = false;
  isSmsSuccess: boolean = false;
  isEmail: boolean = false;
  isEmailSuccess: boolean = false;

  callback?: string;

  @ViewChild('resetpassword') form?: FormResetPasswordComponent;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, private toastr: ToastrService, private translate: TranslateService) {
    console.log('hihi0');
    this.appState$.subscribe((state) => {
      if(state.resetPasswordCallback) {
        this.callback = state.resetPasswordCallback;
      }
      if(state.smsEnrollResponse) {
        this.isSms = true;
      }
      if(state.emailEnrollResponse) {
        this.isEmail = true;
      }

      this.username = state.username;
      this.password = state.password;

      console.log(this.username);
      console.log(this.password);

      console.log('hihi1');
    });
  }

  async ngOnInit() {

    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
  }

  onCodeChanged = ($event: any) => {
    this.code = $event;
  }

  onCodeCompleted = ($event: any) => {

  }

  onResetPassword = async ($event: {password: string}) => {
    let r = await this.supportService.resetPassword((await firstValueFrom(this.appState$)).userId!, $event.password);

    if(r.status == "ACTIVE") {
      // TODO: is this consider as success?
      this.toastr.success(this.translate.instant('Password has been reset successfully and account unlocked'), this.translate.instant('Success'));
      if(this.callback) {
        // delay a few seconds
        this.store.dispatch(new ClearEverything());
        await new Promise(resolve => setTimeout(resolve, 3000));
        window.location.href = this.callback;
      } else {
        this.router.navigate(['/oauth2/v1/authorize']);
      }
    } else {
      this.form?.onReset();
      if(r.errorCode == "E0000001") {
        this.toastr.error(this.translate.instant('Your password seems contains part of your email / phone number, please modify and try again'), this.translate.instant('Error'));
      } else {
        this.toastr.error(this.translate.instant('Action failed, please try again ({{errorcode}})', { errorcode: r.errorCode }), this.translate.instant('Error'));
      }
    }
  }

  onSmsResult = async ($event: any) => {
    this.isSmsSuccess = true;
    await this.onVerifcationComplete();
  }

  onEmailResult = async ($event: any) => {
    this.isEmailSuccess = true;
    await this.onVerifcationComplete();
  }

  onVerifcationComplete = async () => {
    if((!this.isSms || this.isSmsSuccess) && (!this.isEmail || this.isEmailSuccess)) {
      let r = await this.supportService.getForgetPasswordLink((await firstValueFrom(this.appState$)).userId!);

      if(r !== false) {
        window.location.href = r as string;
      }


    }
  }

}
