// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

export const environment = {
  production: false,
  portalDomain: "https://onepass-uat.cic.hk/",
  apiDomain: "https://onepass-uat.cic.hk/api/",
  companyGroupId: "oty5sin89bVg7EPpS697",
  oktaDomain: "onepass-sso-uat.cic.hk",
  idpReturnAppId: "0oa6ijux06c1pMfy5697",
  oktaAuthAppId: "0oa6ijux06c1pMfy5697",
  oktaAuthAppIdHighRisk: "0oa9dt8h7z9H5UuyH697",
  oktaAuthAppIdLowRisk: "0oa9dt0eo57MgMeBT697",
  oktaClientId: "0oa45oydkuC2wsRuJ697",
  oktaClientIdHighRisk: "0oa9j4uw26L6ChLgj697",
  oktaClientIdLowRisk: "0oa9j4nhm0ZVgiX8n697",
  plugins: [NgxsLoggerPluginModule.forRoot(), NgxsReduxDevtoolsPluginModule.forRoot()],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
