import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logout } from '@digitsolu/commons';
import { Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'digitsolu-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit, OnDestroy {

  private readonly subscriptions = new Subscription();

  constructor(private readonly store: Store, private readonly router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public oidcSecurityService: OidcSecurityService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        let redirectUrl = params['redirect'];
        this.oidcSecurityService.getIdToken().subscribe((idToken) => {
          console.log(idToken);
          this.oktaAuth.signOut({
            postLogoutRedirectUri: redirectUrl
          }).then(() => {
            this.oidcSecurityService.logoffAndRevokeTokens(undefined, {
              redirectUrl: redirectUrl
            }).subscribe(() => {
              let token = this.store.selectSnapshot(state => state.app != undefined ? state.app.sessionToken : undefined);
              this.store.dispatch(new Logout()).subscribe(() => {
                if(token != undefined || idToken != undefined) {
                  this.oktaAuth.signOut({
                    idToken: token == undefined ? idToken : token
                  }).then(() => {
                    if(redirectUrl == undefined || redirectUrl == '') {
                      this.router.navigate(['/']);
                    } else {
                      window.location.href = redirectUrl;
                    }
                  });
                } else {
                  if(redirectUrl == undefined || redirectUrl == '') {
                    this.router.navigate(['/']);
                  } else {
                    window.location.href = redirectUrl;
                  }
                }
              });
            })
          })
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
