import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';
import { Authenticate, EmailEnrollResponse, SetLanguage, SmsEnrollResponse, StoreLoginCredentials } from '../../app.actions';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { DropdownItem } from '@digitsolu/ui-tailwind';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'digitsolu-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile


  public readonly items: DropdownItem[][] = [
    [
      {
        name: '繁體中文',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('zh-Hant');
          this.store.dispatch(new SetLanguage('zh-Hant'));
        },
      },
    ],
    [
      {
        name: '简体中文',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('zh-Hans');
          this.store.dispatch(new SetLanguage('zh-Hans'));
        },
      },
    ],
    [
      {
        name: 'English',
        onClick: ($event) => {
          $event.preventDefault();
          this.translate.use('en');
          this.store.dispatch(new SetLanguage('en'));
        }
      },
    ]
  ];

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, public translate: TranslateService) {
    this.route.queryParams.subscribe(params => {
      this.state = params['state'];
      this.nonce = params['nonce'];
      this.code_challenge = params['code_challenge'];
      this.code_challenge_method = params['code_challenge_method'];
      this.client_id = params['client_id'];
      this.redirect_uri = params['redirect_uri'];
      this.response_type = params['response_type'];
      this.display = params['display'];
      this.scope = params['scope'];
    });
  }

  async ngOnInit() {

    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
  }

  // onRegister = async (payload: { email: string, password: string, first_name: string, last_name: string, mobile: string }) => {
  //   // this.credential.emit(payload);

  //   this.http.post('https://' + environment.oktaDomain + '/api/v1/users?activate=false', {
  //     "profile": {
  //       "firstName": payload.first_name,
  //       "lastName": payload.last_name,
  //       "email": payload.email,
  //       "login": payload.email,
  //       "mobilePhone": payload.mobile
  //     },
  //     "credentials": {
  //       "password" : { "value": payload.password }
  //     }
  //   }, {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     }
  //   }).subscribe((data: any) => {
  //     console.log(data);

  //     this.http.post('https://' + environment.oktaDomain + '/api/v1/users/' + data.id + '/factors', {
  //       "factorType": "sms",
  //       "provider": "OKTA",
  //       "profile": {
  //         "phoneNumber": payload.mobile
  //       }
  //     }, {
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       }
  //     }).subscribe((data) => {
  //       console.log(data);

  //     });
  //   });
  // }

  onRegister = async (payload: any) => {
    if(payload.isSuccess) {
      console.log(payload.data);

      this.store.dispatch(new Authenticate(payload.data.value));
      this.store.dispatch(new StoreLoginCredentials(payload.email, payload.password));

      if(payload.email && !payload.email.endsWith('@cicciam.hk')) {
        try {
          let result = await this.supportService.enable2FaEmail(payload.data.value, true, this.translate.currentLang);
          console.log(result);
          // TODO: check if success in return
          this.store.dispatch(new EmailEnrollResponse(result));
        } catch(e) {
          this.store.dispatch(new EmailEnrollResponse(e));
        }
      } else {
        this.store.dispatch(new EmailEnrollResponse(null));
      }

      if(payload.mobilePhone) {

        if(payload.mobilePhone.length == 8 && !payload.mobilePhone.startsWith('+')) {
          payload.mobilePhone = '+852' + payload.mobilePhone;
        }
        try {
          let result = await this.supportService.enable2FaSms(payload.data.value, true, this.translate.currentLang, payload.mobilePhone);
          console.log(result);
          // TODO: check if success in return
          this.store.dispatch(new SmsEnrollResponse(result));
        } catch(e) {
          this.store.dispatch(new SmsEnrollResponse(e));
        }
      } else {
        this.store.dispatch(new SmsEnrollResponse(null));
      }

      this.router.navigate(['register-validate']);
    }
  }

  onLogin() {
    this.router.navigate(['login']);
  }
}
