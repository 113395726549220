export interface OauthSigninRequest {
  state: string,
  nonce: string,
  code_challenge: string,
  code_challenge_method: string,
  client_id: string,
  redirect_uri: string,
  response_type: string,
  display: string,
  scope: string,

  initiatedAt: Date,
  isOffFlow: boolean,
}

export class ClearEverything {
    static readonly type = '[App] ClearEverything';

    constructor() { }
}

export class SetLanguage {
    static readonly type = '[App] SetLanguage';

    constructor(public language: string) { }
}

export class StoreLoginCredentials {
    static readonly type = '[App] StoreLoginCredentials';

    constructor(public username: string, public password: string) { }
}

export class ClearLoginCredentials {
    static readonly type = '[App] ClearLoginCredentials';

    constructor() { }
}

export class ClearEnrollResponses {
    static readonly type = '[App] ClearEnrollResponses';

    constructor() { }
}

export class SmsEnrollResponse {
    static readonly type = '[App] SmsEnrollResponse';

    constructor(public smsEnrollResponse: any) { }
}

export class EmailEnrollResponse {
    static readonly type = '[App] EmailEnrollResponse';

    constructor(public emailEnrollResponse: any) { }
}

export class StoreSessionToken {
    static readonly type = '[App] StoreSessionToken';

    constructor(public sessionToken: string) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class Authenticate {
  static readonly type = '[Auth] Authenticate';

  constructor(public readonly userId: string) { }
}

export class RetainSession {
    static readonly type = '[Auth] RetainSession';
}

export class SetOAuthSigninRequest {
    static readonly type = '[App] SetOAuthSigninRequest';

    constructor(public oauthSigninRequest: OauthSigninRequest) { }
}

export class ClearOAuthSigninRequest {
    static readonly type = '[App] ClearOAuthSigninRequest';

    constructor() { }
}

export class SetOidcError {
    static readonly type = '[App] SetOidcError';

    constructor(public errorCode?: string) { }
}

export class SetUserId {
    static readonly type = '[App] SetUserId';

    constructor(public userId: string) { }
}

export class SetIsCompany {
    static readonly type = '[App] SetIsCompany';

    constructor(public isCompany: boolean) { }
}

export class SetLoginApp {
  static readonly type = '[App] SetLoginApp';

  constructor(public loginApp?: string) { }
}

export class SetOffFlow {
  static readonly type = '[App] SetOffFlow';

  constructor(public offFlow: boolean) { }
}

export class SetVerificationToken {
  static readonly type = '[App] SetVerificationToken';

  constructor(public token?: string) { }
}

export class FactorInfoChange {
  static readonly type = '[App] FactorInfoChange';

  constructor(public newFactorInfo: string) { }
}

export class FactorInfoChangeConfirm {
  static readonly type = '[App] FactorInfoChangeConfirm';

  constructor() { }
}

export class ChangeResetPasswordCallback {
  static readonly type = '[App] ChangeResetPasswordCallback';

  constructor(public callback: string) { }
}
