import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { AgGridModule } from '@ag-grid-community/angular';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AuthState } from './guards/auth-guard.state';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { AgGridComponent } from './widgets/grid/ag-grid/ag-grid.component';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridFaIconComponent } from './widgets/grid/ag-grid-fa-icon/ag-grid-fa-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridFaIconsComponent } from './widgets/grid/ag-grid-fa-icons/ag-grid-fa-icons.component';
import { AgGridCheckboxComponent } from './widgets/grid/ag-grid-checkbox/ag-grid-checkbox.component';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    NgxsModule.forFeature([AuthState]),
    AgGridModule,
    FontAwesomeModule,
  ],
  declarations: [ClickOutsideDirective, AgGridComponent, AgGridFaIconComponent, AgGridFaIconsComponent, AgGridCheckboxComponent],
  exports: [ClickOutsideDirective, AgGridComponent, AgGridFaIconComponent, AgGridFaIconsComponent, AgGridCheckboxComponent],
})
export class CommonsModule { }
