<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-6xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 rounded-t">
        <h3 class="text-xl font-semibold text-green-800 flex-grow text-center">
          {{ "Verify it's you with a security method" | translate }}
        </h3>
      </div>
      <!--body-->
      <div class="relative flex-auto mx-6">
        <hr class="mb-4" />
        <h4 class="block text-center text-base text-green-800 mb-4">{{ "Select from the following options" | translate }}</h4>
        <div class="flex flex-row items-center">
          <div class="m-2 p-6 px-12 rounded-xl flex flex-col flex-grow text-center justify-center items-center cursor-pointer shadow-md"
          (click)="onSelectEmail($event)">
          <div><img src="assets/images/mail.svg" class="w-6" /></div>
            <div class="flex-grow text-green-800 text-xs mt-2">{{ "Email" | translate }}</div>
          </div>
          <div class="m-2 p-6 px-12 rounded-xl flex flex-col flex-grow text-center justify-center items-center cursor-pointer shadow-md"
          (click)="onSelectSms($event)">
            <div><img src="assets/images/phone.svg" class="w-6" /></div>
            <div class="flex-grow text-green-800 text-xs mt-2">{{ "SMS" | translate }}</div>
          </div>
        </div>

        <hr class="mt-4 mb-2" />

        <div class="flex flex-row-reverse mb-4">
          <button
            type="submit"
            class="flex flex-row justify-center text-gray-800 bg-transparent hover:text-green-800 focus:outline-none focus:ring-notus-primary-300 rounded-3xl text-xs py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            (click)="onCancel($event)"
          >
            {{ 'Cancel' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>

<ciciam-sms-verify-popup #smsPopup></ciciam-sms-verify-popup>

