import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormLoginComponent } from './pages/form-login/form-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormRegisterComponent } from './pages/form-register/form-register.component';
import { FormLoginPhoneComponent } from './pages/form-login-phone/form-login-phone.component';
import { FormRegisterPhoneComponent } from './pages/form-register-phone/form-register-phone.component';
import { ModalDefaultComponent } from './modals/modal-default/modal-default.component';
import { ComponentLoadingComponent } from './components/loading/loading.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot({
      defaultLanguage: 'zh-Hant',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(
            http,
            `assets/i18n/flowbite/`,
            '.json'
          );
        },
        deps: [HttpClient]
    }
    }),],
  declarations: [
    FormLoginComponent, FormLoginPhoneComponent, FormRegisterComponent, FormRegisterPhoneComponent, ModalDefaultComponent, ComponentLoadingComponent],
  exports: [
    FormLoginComponent, FormLoginPhoneComponent, FormRegisterComponent, FormRegisterPhoneComponent, ModalDefaultComponent, ComponentLoadingComponent],
})
export class FlowbiteModule { }
