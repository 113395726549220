import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { firstValueFrom, Observable } from 'rxjs';
import { ClearLoginCredentials, ClearOAuthSigninRequest, SmsEnrollResponse } from '../../app.actions';
import { StateModel, STATE_TOKEN } from '../../app.states';
import * as uuid from 'uuid';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'ciam-register-validate',
  templateUrl: './register-validate.component.html',
  styleUrls: ['./register-validate.component.scss'],
})
export class RegisterValidateComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;

  uid: string = '';
  code: string = '';
  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  isSms: boolean = false;
  isSmsSuccess: boolean = false;
  isEmail: boolean = false;
  isEmailSuccess: boolean = false;

  verificationToken?: string;
  loginApp?: string;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService) {
    // console.log('hihi0');
    this.appState$.subscribe((state) => {
      if(state.smsEnrollResponse) {
        this.isSms = true;
      }
      if(state.emailEnrollResponse) {
        this.isEmail = true;
      }
      if(state.verificationToken) {
        this.verificationToken = state.verificationToken;
      }

      console.log('hihi1');
      if(state.oauthSigninRequest) {
        console.log('hihi2');
        this.state = state.oauthSigninRequest.state;
        this.nonce = state.oauthSigninRequest.nonce;
        this.code_challenge = state.oauthSigninRequest.code_challenge;
        this.code_challenge_method = state.oauthSigninRequest.code_challenge_method;
        this.client_id = state.oauthSigninRequest.client_id;
        this.redirect_uri = state.oauthSigninRequest.redirect_uri;
        this.response_type = state.oauthSigninRequest.response_type;
        this.display = state.oauthSigninRequest.display;
        this.scope = state.oauthSigninRequest.scope;
        console.log(this.state);
      }
    });

    this.store.subscribe((state) => {
      this.loginApp = state.cicciam.loginApp;
    });
  }

  async ngOnInit() {

    // const user = await this.oktaAuth.getUser();
    // this.user = JSON.stringify(user, null, 4);
  }

  onCodeChanged = ($event: any) => {
    this.code = $event;
  }

  onCodeCompleted = ($event: any) => {

  }

  onSmsResult = async ($event: any) => {
    this.isSmsSuccess = true;
    await this.onVerifcationComplete();
  }

  onEmailResult = async ($event: any) => {
    this.isEmailSuccess = true;
    await this.onVerifcationComplete();
  }

  onVerifcationComplete = async () => {
    if((!this.isSms || this.isSmsSuccess) && (!this.isEmail || this.isEmailSuccess)) {
      console.log((await firstValueFrom(this.appState$)));

      if(this.verificationToken) {
        window.open(this.supportService.getVerifyCallbackUrl(this.verificationToken), "_self");
        return;
      }

      let deviceToken = localStorage.getItem('deviceToken');
      if(deviceToken == null) { deviceToken = uuid.v4().substring(0, 32); localStorage.setItem('deviceToken', deviceToken); }

      let trans: AuthnTransaction = await this.oktaAuth.signIn({
        username: (await firstValueFrom(this.appState$)).username,
        password: (await firstValueFrom(this.appState$)).password,
        context: {
          deviceToken: deviceToken
        }
      });

      if(trans.status == 'SUCCESS') {
        if(this.loginApp) {
          await this.supportService.assignUserGroupByApp(trans.user!['id'].toString(), this.loginApp);
        }

        this.store.dispatch(new ClearLoginCredentials());

          switch(this.loginApp) {
            case 'opasdev':
              location.href = 'https://cic-dev.outsystemsenterprise.com/onlineProgramApplication/RegistrationSuccess?uid=' + trans.user!['id'].toString();
              break;
            case 'opasuat':
              location.href = 'https://cic-tst.outsystemsenterprise.com/onlineProgramApplication/RegistrationSuccess?uid=' + trans.user!['id'].toString();
              break;
            case 'opas':
              location.href = 'https://apps.cic.hk/hkicopas/RegistrationSuccess?uid=' + trans.user!['id'].toString();
              break;
            case 'superapp':
              if(this.state && this.redirect_uri) {
                this.store.dispatch(new ClearOAuthSigninRequest());
                let result = await this.supportService.createTokenRecord(trans.sessionToken ?? '', this.state, this.redirect_uri);
                console.log(result);

                window.location.href = 'https://' + environment.oktaDomain + '/oauth2/v1/authorize?response_type=token&scope=openid%20profile%20offline_access%20email&nonce=TEST&client_id=' + environment.idpReturnAppId + '&redirect_uri=https:%2F%2F' + window.location.hostname + '%2Flogin%2Fcallback&sessionToken=' + trans.sessionToken + '&state=' + result.state;
              } else {
                this.oktaAuth.session.setCookieAndRedirect(trans.sessionToken, window.location.protocol + "//" + window.location.host + '/home');
              }
              break;
            default:
              this.oktaAuth.session.setCookieAndRedirect(trans.sessionToken, window.location.protocol + "//" + window.location.host + '/home');
          }// Sets a cookie on redirect
        }
    }
  }

}
