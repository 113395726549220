<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-[80%] my-6 mx-auto max-w-2xl">

      <div
        [formGroup]="emailForm"
        *ngIf="method == 'email'"
      >
      <!--content-->
      <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <!--header-->
        <div class="p-5 border-b border-solid border-slate-200 rounded-t mx-4">
          <h3 class="text-2xl font-semibold text-notus-primary-900 text-center">
            {{ 'Change Two-factor Authentication' | translate }}
          </h3>
        </div>
        <!--body-->
        <div class="relative p-6 flex-auto">
        <div class="relative">
          <h4 class="text-lg font-semibold">{{ 'Email' | translate }}</h4>
          <ng-switchery class="absolute top-0 right-0" formControlName="emailEnable" color="switchery-success" (click)="onEmailEnableToggle($event)"></ng-switchery>
        </div>
        <div>{{ 'Receive verification code via email.' | translate }}</div>
          <div class="space-4 md:space-6 flex-wrap flex flex-col lg:flex-row gap-8 mb-2 mt-2">
            <div class="w-full">
              <span class="p-float-label relative">
                <input
                  pInputText
                  name="email"
                  id="email"
                  class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  formControlName="email"
                />
                <label
                for="email"
                class=""
                >{{ 'Email' | translate }}</label
              >
              </span>
              <div *ngIf="emailForm.get('email')?.errors?.['required']" class="alert alert-danger text-red-600">
                {{ 'Please enter your email address' | translate }}
              </div>
              <div *ngIf="emailForm.get('email')?.errors?.['pattern']" class="alert alert-danger text-red-600">
                {{ 'Email address format is incorrect.' | translate }}
              </div>
              <div *ngIf="emailForm.get('email')?.errors?.['emailused']" class="alert alert-danger text-red-600">
                {{ 'Email address had been used.' | translate }}
              </div>
            </div>
          </div>

          <hr class="my-4" />

          <div class="flex flex-row-reverse">
            <button
              type="submit"
              class="flex flex-row justify-center w-32 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              *ngIf="!isLoading && isFormModified"
              (click)="onEmailFormSubmit()"
              [disabled]="!isFormModified"
            >
              {{ (isVerifcationNeeded ? 'Verify' : 'Change') | translate }}
            </button>
            <button
              type="submit"
              disabled
              class="flex flex-row justify-center w-32 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
              *ngIf="isLoading || !isFormModified"
            >
              <ng-container *ngIf="isLoading">
                <flowbite-loading size="1.25rem"></flowbite-loading>
              </ng-container>
              <ng-container *ngIf="!isFormModified">
                {{ (isVerifcationNeeded ? 'Verify' : 'Change') | translate }}
              </ng-container>
            </button>
            <button
              type="submit"
              class="flex flex-row justify-center w-32 text-notus-primary-900 bg-transparent hover:text-notus-primary-900 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              (click)="onCancel($event)"
            >
              {{ 'Close' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

        <div
          action="#"
          [formGroup]="phoneForm"
          *ngIf="method == 'sms'"
        >
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="p-5 border-b border-solid border-slate-200 rounded-t mx-4">
            <h3 class="text-2xl font-semibold text-notus-primary-900 text-center">
              {{ 'Change Two-factor Authentication' | translate }}
            </h3>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">

        <div class="relative">
          <h4 class="text-lg font-semibold mt-4">{{ 'SMS' | translate }}</h4>
          <ng-switchery class="absolute top-0 right-0" formControlName="phoneNumberEnable" color="switchery-success" (click)="onSmsEnableToggle($event)"></ng-switchery>
        </div>
        <div>{{ 'Receive verification code via SMS.' | translate }}</div>

          <div class="space-4 md:space-6 flex-wrap flex flex-col lg:flex-row gap-8 mb-2 mt-2">
            <div class="w-full">
              <span class="p-float-label relative">
              <input
                pInputText
                name="phoneNumber"
                id="phoneNumber"
                class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                formControlName="phoneNumber"
              />
              <label
                for="phoneNumber"
                class=""
                >{{ 'Phone Number' | translate }}</label
              >
            </span>
              <div *ngIf="phoneForm.get('phoneNumber')?.errors?.['required']" class="alert alert-danger text-red-600">
                {{ 'Please enter your phone number' | translate }}
              </div>
              <div *ngIf="phoneForm.get('phoneNumber')?.errors?.['pattern']" class="alert alert-danger text-red-600">
                {{ 'Please enter a valid phone number.' | translate }}
              </div>
              <div *ngIf="phoneForm.get('phoneNumber')?.errors?.['phoneused']" class="alert alert-danger text-red-600">
                {{ 'Phone number had been used.' | translate }}
              </div>
            </div>
          </div>

          <hr class="my-4" />

          <div class="flex flex-row-reverse">
            <button
              type="submit"
              class="flex flex-row justify-center w-32 text-white bg-notus-primary-600 hover:bg-notus-primary-700 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              *ngIf="!isLoading && isFormModified"
              (click)="onPhoneFormSubmit()"
            >
              {{ (isVerifcationNeeded ? 'Verify' : 'Change') | translate }}
            </button>
            <button
              type="submit"
              disabled
              class="flex flex-row justify-center w-32 text-white bg-gray-300 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-white"
              *ngIf="isLoading || !isFormModified"
            >
              <ng-container *ngIf="isLoading">
                <flowbite-loading size="1.25rem"></flowbite-loading>
              </ng-container>
              <ng-container *ngIf="!isFormModified">
                {{ (isVerifcationNeeded ? 'Verify' : 'Change') | translate }}
              </ng-container>
            </button>
            <button
              type="submit"
              class="flex flex-row justify-center w-32 text-notus-primary-900 bg-transparent hover:text-notus-primary-900 focus:outline-none focus:ring-notus-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              (click)="onCancel($event)"
            >
              {{ 'Close' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<div *ngIf="showModal" class="opacity-50 fixed inset-0 z-40 bg-white"></div>

<ciciam-sms-verify-popup #smsPopup></ciciam-sms-verify-popup>
<ciciam-email-verify-popup #emailPopup></ciciam-email-verify-popup>

