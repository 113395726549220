import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthnTransaction, Tokens, AuthApiError } from '@okta/okta-auth-js';
import OktaSignIn, { WidgetOktaAuthInterface } from '@okta/okta-signin-widget';
import { ClearEnrollResponses, ClearEverything, EmailEnrollResponse, SetLanguage, SetLoginApp, SetOAuthSigninRequest, SmsEnrollResponse, StoreLoginCredentials, StoreSessionToken } from '../../app.actions';
import { Authenticate } from 'libs/commons/src/lib/guards/auth-guard.actions';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { Observable, firstValueFrom } from 'rxjs';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { Choose2faPopupComponent } from '../../popup/choose-2fa-popup/choose-2fa-popup.component';
import { FormLoginComponent } from '../../widgets/form-login/form-login.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as uuid from 'uuid';
import { environment } from 'apps/app-cic-ciam/src/environments/environment';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'cicciam-login',
  templateUrl: './portallogin.component.html',
  styleUrls: ['./portallogin.component.scss'],
})
export class PortalLoginComponent {

  user: string = '';
  credential = new EventEmitter<{ email: string, password: string, usernameTypes: string[] }>();

  state: string = ''; //=WVNGL3grSERUdFJTVFNibFBsWG91cUZzMkxaUEgxMDBoczJncGlzWmdCUDI5Wkk2Nmx0bmM3end4YVJremtsUA&
  nonce: string = ''; //=tphLFY7GlJssurM4nh4s30rzhAMfciCk&
  code_challenge: string = ''; //=SDr1LUqoRgPsTxe336MVhzrRjhAGcWjnaj4W1bSw5Ew&
  code_challenge_method: string = ''; //=S256&
  client_id: string = ''; //=0oa4cd4j2gVdXd0Ac697&
  redirect_uri: string = ''; //=https%3A%2F%2Fonepass-sso-uat.cic.hk%2Foauth2%2Fv1%2Fauthorize%2Fcallback&
  response_type: string = ''; //=code&
  display: string = ''; //=page&
  scope: string = ''; //=email+openid+profile

  showModal: boolean = false;

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;

  @ViewChild('loginForm') loginForm?: FormLoginComponent;
  @ViewChild('choose2fa') choose2fa!: Choose2faPopupComponent;

  constructor(private router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private translateService: TranslateService, private route: ActivatedRoute, private http: HttpClient, private store: Store, private supportService: SupportService, private toastr: ToastrService, public oidcSecurityService: OidcSecurityService) {
    route.queryParams.subscribe(async params => {
      if(params['lang']) {
        switch(params['lang']) {
          case 'zh-Hant':
          case 'zh-Hans':
          case 'en':
            this.store.dispatch(new SetLanguage(params['lang']));
            break;
        }
      }
      if(params['display']) {
        switch(params['display']) {
          case 'zh-Hant':
          case 'zh-Hans':
          case 'en':
            this.store.dispatch(new SetLanguage(params['display']));
            break;
        }
      }
    });
  }

  async ngOnInit() {
    this.oidcSecurityService.authorize(undefined, {
      redirectUrl: environment.portalDomain + 'portallogin/callback',
      customParams: {
        // display: 'zh-HK'
      }
    });
  }
}
