import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridCellDropdownComponent } from '@digitsolu/ui-tailwind';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, toArray } from 'rxjs';
import { STATE_TOKEN, StateModel } from '../../app.states';
import { SupportService } from 'apps/app-cic-ciam/src/services/support.service';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { SetUserId } from '../../app.actions';

@Component({
  selector: 'ciciam-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {

  loading: boolean | undefined;
  data: any[] = [];

  faUserCircle = faUserCircle;

  @Select(STATE_TOKEN) appState$!: Observable<StateModel>;
  userId?: string;

  isLoading: boolean = false;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private readonly fb: FormBuilder,
  private store: Store, private supportService: SupportService, private config: PrimeNGConfig, private translateService: TranslateService) {

  }

  async ngOnInit() {
    this.isLoading = true;
    const session = await this.oktaAuth.session.get();
    if(session.status !== 'ACTIVE') {
      await this.oktaAuth.signOut();
      return;
    }

    const user: any = await session.user!();

    this.store.dispatch(new SetUserId(user.id));
    this.userId = user.id;
    this.getHistoryList();

    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  async getHistoryList() {
    if(this.userId) {
      this.isLoading = true;
      this.data = (await this.supportService.getHistoryByUser(this.userId)).map((item: any) => {
        let appName = "";
        if(item.target != null) {
          for(let target of item.target) {
            if(target.alternateId !== 'unknown') {
              appName = target.alternateId;
              break;
            }
          }
        } else {
          // appName = this.translateService.instant("OnePass Portal");
        }
        let location: string[] = [];
        // if(item._Client?.geographicalContext?.state) { location.push(item._Client.geographicalContext.state); }
        if(item._Client?.geographicalContext?.country) { location.push(item._Client.geographicalContext.country); }
        return {
          application: appName,
          locaiton: location.join(', '),
          device: item._Client?.device,
          loginTime: item.published,
        };
      });
      this.isLoading = false;
    }
  }

  dateDisplay(date: Date) {
    return new Date(date).toLocaleString();
  }
}
