import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faCheck, faCircle, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ciciam-form-reset-password',
  templateUrl: './form-reset-password.component.html',
})
export class FormResetPasswordComponent implements OnDestroy {

  @Input() websiteName = 'Flowbite';
  @Input() set message(stream: Observable<string | null>) {
    this.subscriptions.add(stream.subscribe(x => this.messageSubject.next(x)));
  }

  @Output() credential = new EventEmitter<{ password: string }>();
  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() forgotPassword: EventEmitter<any> = new EventEmitter();

  credentialForm = this.fb.nonNullable.group(
    {
      password: ['', Validators.required],
      repeatPassword: ['', Validators.required]
    }
  );

  isLoading: boolean = false;

  isPasswordHasLowercase: boolean = false;
  isPasswordHasUppercase: boolean = false;
  isPasswordHasNumber: boolean = false;
  isPasswordLength8: boolean = false;
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;

  faCheck = faCheck;
  faTimes = faTimes;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircle = faCircle;


  private readonly messageSubject = new BehaviorSubject<string | null>(null);
  public readonly message$ = this.messageSubject.asObservable();

  private readonly subscriptions = new Subscription();

  constructor(private readonly fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onResetPassword() {
    this.messageSubject.next(null);

    this.credentialForm.setErrors(null);

    this.credentialForm.controls['password'].markAsTouched();
    this.credentialForm.controls['repeatPassword'].markAsTouched();

    if(!(this.isPasswordHasLowercase && this.isPasswordHasUppercase && this.isPasswordHasNumber && this.isPasswordLength8)) {
      this.credentialForm.controls['password'].setErrors({ 'requirement': 'regerror.password' });
    }

    let formValue = this.credentialForm.value;

    let password = formValue.password;
    let repeatPassword = formValue.repeatPassword;

    // TODO: check password repeat password the same
    if(password !== repeatPassword) {
      // TODO: show error message
      this.credentialForm.controls['repeatPassword'].setErrors({ 'match': 'error' });
      return;
    }

    if(!this.credentialForm.valid) {
      return;
    }

    this.isLoading = true;
    this.credential.emit({ password: '', ...this.credentialForm.value });
    // this.credentialForm.reset();
  }

  onRegister($event: any) {
    $event.preventDefault();
    this.register.emit();
  }

  onForgotPassword($event: any) {
    $event.preventDefault();
    this.forgotPassword.emit();
  }

  checkPassword($event: any) {
    let password = this.credentialForm.controls['password'].value;

    this.isPasswordHasLowercase = /[a-z]/.test(password);
    this.isPasswordHasUppercase = /[A-Z]/.test(password);
    this.isPasswordHasNumber = /[0-9]/.test(password);
    this.isPasswordLength8 = password.length >= 8;
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPassword() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  onReset() {
    this.isLoading = false;
  }
}
