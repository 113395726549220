
    <div
      class="w-full md:max-w-md md:mt-0 xl:p-0 md:ml-auto"
    >
      <!-- <div class="md:px-6 text-center" *ngIf="isLoginAppGet">
        <ng-container *ngIf="(!loginApp || loginApp == 'portal'); else loginAppLine">{{ 'Portal welcome' | translate }}</ng-container>
        <ng-template #loginAppLine>{{ 'Portal welcome app' | translate: { app: loginApp | translate } }}</ng-template>
      </div> -->
      <div class="py-6 md:p-6 space-y-4 md:space-y-6">
        <div
          class="space-y-4 md:space-y-6"
          [formGroup]="credentialForm"
          *ngIf="isLoginAppGet"
        >
          <div class="pt-2">
            <span class="p-float-label">
              <input
                pInputText
                type="email"
                name="email"
                id="email"
                class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=""
                required=""
                formControlName="email"
                autocomplete="off"
                tabindex="0"
                (click)="onFieldClick($event)"
              />
              <label
                for="email"
                class=""
                >{{ usernameHint() }}</label
              >
            </span>
            <div
            *ngIf="isSubmitted && credentialForm.controls['email'].invalid && (credentialForm.controls['email'].dirty || credentialForm.controls['email'].touched)"
            class="text-red-600 col-span-10 text-xs mt-2"
            >
              <div *ngIf="credentialForm.controls['email'].errors?.['required']">
                {{ 'is required.' | translate: { fieldname: usernameHint() } }}
              </div>
            </div>
          </div>
          <div class="pt-2">
            <span class="p-float-label relative">
              <input
                pInputText
                [type]="isPasswordVisible ? 'text' : 'password'"
                name="password"
                id="password"
                placeholder=""
                class="border border-gray-300 text-gray-900 text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                formControlName="password"
                autocomplete="off"
                (click)="onFieldClick($event)"
              />
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-2 text-gray-400" *ngIf="!isPasswordVisible" [icon]="faEyeSlash" (click)="togglePassword()"></fa-icon>
              <fa-icon class="absolute p-2.5 leading-5 top-0 right-2 text-gray-400" *ngIf="isPasswordVisible" [icon]="faEye" (click)="togglePassword()"></fa-icon>

              <label
              for="password"
              class=""
              >{{ 'Password' | translate }}</label
            >
            </span>
            <div
            *ngIf="isSubmitted && credentialForm.controls['password'].invalid && (credentialForm.controls['password'].dirty || credentialForm.controls['password'].touched)"
            class="text-red-600 col-span-10 text-xs mt-2"
            >
              <div *ngIf="credentialForm.controls['password'].errors?.['required']">
                {{ 'Password is required.' | translate }}
              </div>
            </div>
          </div>
          <div class="text-right mt-2">
            <div class="">
              <p class="text-sm font-light text-gray-500 dark:text-gray-400 text-right">
                <a
                  (click)="onForgotPassword($event)"
                  class="font-medium text-notus-primary-700 hover:underline dark:text-primary-500 cursor-pointer"
                  >{{ 'Forgot Password' | translate }} {{ "Or" | translate }} {{ "Account being locked?" | translate }}</a
                >
              </p>
            </div>
            <!-- <a
              href="#"
              class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >{{ 'Forgot password?' | translate }}</a
            > -->
          </div>
          <button
            type="submit"
            class="flex flex-row justify-center w-60 m-auto text-white focus:outline-none focus:ring-none font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            [class]="isLoading ? 'bg-white ring-notus-primary-700 ring-2 cursor-default' : 'bg-notus-primary-700 hover:bg-notus-primary-800'"
            (click)="onSignInWithEmail()"
          >
            <ng-container *ngIf="!isLoading">
              <img src="/assets/images/login-icon.png" class="mr-2 h-5" />{{ 'Sign in' | translate }}
            </ng-container>
            <ng-container *ngIf="isLoading">
              <flowbite-loading size="1.25rem"></flowbite-loading>
            </ng-container>
          </button>
          <div *ngIf="isAllowRegister" class="text-center">
            <div class="">
              <p class="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                <a
                  (click)="onRegister($event)"
                  class="font-medium text-notus-primary-700 hover:underline dark:text-primary-600 cursor-pointer"
                  >{{ 'Sign up' | translate }}</a
                >
              </p>
            </div>
            <!-- <a
              href="#"
              class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >{{ 'Forgot password?' | translate }}</a
            > -->
          </div>
        </div>
        <div *ngIf="!isLoginAppGet" class="text-center">
          <flowbite-loading size="1.25rem"></flowbite-loading>
      </div>
    </div>
